/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { DateTimeConstants, DateTimeHelpers } from '@dai/common'
import { FormPicOSPriority } from './picos.types'

const EMPTY_PICOS: FormPicOSPriority = {
  id: '',
  name: '',
  startDate: DateTimeHelpers.getDateWeekDelta(0).toISOString(),
  endDate: DateTimeHelpers.getDateWeekDelta(1).toISOString(),
  isAllStores: undefined,
  priorityNum: undefined,
  mandateType: undefined,
  imageUrl: undefined,
  description: '',
  displayTypes: [],
  displayLocations: [],
  displayElements: undefined,
  swirePosElementRequired: false,
  otherCategoriesAllowed: false,
  otherBrandsAllowed: false,
  otherPackagingAllowed: false,
  otherProductsAllowed: false,
  otherDisplayTypesAllowed: false,
  otherDisplayLocationsAllowed: false,
  categories: [],
  brands: [],
  packaging: [],
  products: [],
  customerBanner: undefined,
  storeGroups: [],
  pricing: undefined,
  cost: undefined,
}

export const PicosConstants = {
  EMPTY_PICOS,
}
