"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_PICOS_PRIORITY = void 0;
var client_1 = require("@apollo/client");
exports.CREATE_PICOS_PRIORITY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation CreatePicosPriority($input: CreatePicOSPriorityInput!){\n\t\tcreatePicosPriority(input: $input){\n\t\t\tpicosPriority{\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tpriority\n\t\t\t\tdateCreated\n\t\t\t\tstartDate\n\t\t\t\tendDate\n\t\t\t\tdescription\n\t\t\t\tmandateType\n\t\t\t\tstoreGroup{\n\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tcreator{\n\t\t\t\tfirstName\n\t\t\t\tlastName\n\t\t\t\tusername\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t} \n"], ["\n\tmutation CreatePicosPriority($input: CreatePicOSPriorityInput!){\n\t\tcreatePicosPriority(input: $input){\n\t\t\tpicosPriority{\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tpriority\n\t\t\t\tdateCreated\n\t\t\t\tstartDate\n\t\t\t\tendDate\n\t\t\t\tdescription\n\t\t\t\tmandateType\n\t\t\t\tstoreGroup{\n\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tcreator{\n\t\t\t\tfirstName\n\t\t\t\tlastName\n\t\t\t\tusername\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t} \n"])));
var templateObject_1;
