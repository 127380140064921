/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  FlexBox,
  SearchableListWithSkeleton,
  Sidebar,
  useDebouncedItemQuery,
  CaptionStack,
  CircularProgressThresholds,
  UserTile,
} from '@dai/web-components'
import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import {
  GET_GEOGRAPHIC_HIERARCHY_LEAD_CANDIDATES,
  GetGeographicHierarchyLeadCandidatesQuery,
  GetGeographicHierarchyLeadCandidatesQueryVariables,
} from '@dai/graphql'
import TerritoryHierarchy from 'stores/containers/hierarchy-management/TerritoryHierarchy'
import { FSM } from './types'

const GeographicHierarchyContainer: React.FC = () => {
  const [selectedUser, setSelectedUser] = React.useState<FSM>()

  const FSMLazyQuery = useDebouncedItemQuery<
    GetGeographicHierarchyLeadCandidatesQuery,
    GetGeographicHierarchyLeadCandidatesQueryVariables
  >({
    queryStr: GET_GEOGRAPHIC_HIERARCHY_LEAD_CANDIDATES,
    options: { fetchPolicy: 'network-only' },
  })

  const candidates =
    FSMLazyQuery.lazyQuery.meta.data?.geographicHierarchyLeadCandidates
      ?.results || []

  const handleQuery = () => {
    FSMLazyQuery.lazyQuery
      .query({
        variables: {
          input: {
            searchTerm: FSMLazyQuery.debouncedQuery,
          },
        },
      })
      .then(res => {
        if (res.data?.geographicHierarchyLeadCandidates.results.length) {
          setSelectedUser(res.data.geographicHierarchyLeadCandidates.results[0])
        }
      })
  }

  useEffect(handleQuery, [FSMLazyQuery.debouncedQuery])

  return (
    <>
      <FlexBox.Row flex={1}>
        <Sidebar side={'left'}>
          <SearchableListWithSkeleton<FSM>
            placeholder={'Search FSM Users'}
            onSearchChange={term => FSMLazyQuery.setItemQuery(term.trim())}
            items={candidates}
            loading={FSMLazyQuery.lazyQuery.meta.loading}
            renderItem={({ item, index, loading }) => (
              <Box mx={2} mb={1} flex={1} mt={index === 0 ? 1 : 0}>
                <UserTile
                  isLoading={loading}
                  user={item}
                  selected={item && selectedUser?.uuid === item.uuid}
                  onPress={() => setSelectedUser(item!)}
                  RightComponent={
                    item &&
                    !loading && (
                      <CaptionStack caption="Approved Routes">
                        <CircularProgressThresholds
                          size={60}
                          showPercentageLabel
                          value={
                            (item.numDraftsApproved / item.numDraftsTotal) * 100
                          }
                          thresholds={{ warning: 50, success: 80 }}
                        />
                      </CaptionStack>
                    )
                  }
                />
              </Box>
            )}
          />
        </Sidebar>
        <FlexBox.Col flex={1} mb={14}>
          <TerritoryHierarchy
            selectedUser={selectedUser}
            onRefetch={handleQuery}
          />
        </FlexBox.Col>
      </FlexBox.Row>
    </>
  )
}

export default GeographicHierarchyContainer
