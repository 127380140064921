/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import { DisplayAuditProductsModal } from 'store-inspection/components/modal/DisplayAuditProducts.modal'
import StoreInspectionHelpers from 'store-inspection/helpers/store-inspection.helpers'
import {
  AvatarWithText,
  DAITable,
  FILL_GRID_ALL_SCREENS,
  FillContainerLoading,
  FullScreenLayout,
  gridAllScreens,
  PageTitle,
  GridSections,
  TableContainerClass,
} from '@dai/web-components'
import { useAuditSummaryLogic } from 'store-inspection/containers/AuditSummary.logic'
import { Card, Divider, Grid } from '@mui/material'
import { round } from 'lodash'
import { Stack } from '@mui/system'

const AuditSummaryContainer: React.FC = () => {
  const {
    state: {
      showDisplayProducts,
      setShowDisplayProducts,
      displayAuditProducts,
      displayAuditTitle,
    },
    data: {
      priceColumns,
      answerColumns,
      displayColumns,
      displayRows,
      priceRows,
      answerRows,
      audit,
      loading,
    },
    handle: { handleOpenDisplayProducts },
  } = useAuditSummaryLogic()

  const dataIsLoading =
    loading || !audit || !answerRows || !priceRows || !displayRows

  if (dataIsLoading) {
    return <FillContainerLoading />
  }

  return (
    <>
      <FullScreenLayout
        header={<PageTitle title="Audit Summary" />}
        body={
          <Grid container spacing={2}>
            <Grid item {...gridAllScreens(4)} md={5} sm={12} xs={12}>
              <Card
                sx={theme => ({ padding: theme.spacing(2), height: '100%' })}
              >
                <GridSections
                  items={{
                    Auditor: {
                      gridProps: FILL_GRID_ALL_SCREENS,
                      body: (
                        <AvatarWithText
                          primaryText={`${audit.employee?.firstName} ${audit.employee?.lastName}`}
                          secondaryText={`Date Completed: ${StoreInspectionHelpers.formatDate(
                            new Date(audit.dateCompleted),
                          )}`}
                        >
                          {audit.employee?.firstName[0]}
                          {audit.employee?.lastName[0]}
                        </AvatarWithText>
                      ),
                    },
                    Scores: {
                      gridProps: FILL_GRID_ALL_SCREENS,
                      body: (
                        <Stack spacing={2} direction={'column'}>
                          <Grid item {...gridAllScreens(12)}>
                            <AvatarWithText
                              avatarVariant="rounded"
                              primaryText="% Total Sovi"
                              AvatarSx={{ width: 1000 }}
                            >
                              {isNaN(StoreInspectionHelpers.soviPercent(audit))
                                ? '-'
                                : `${round(
                                    StoreInspectionHelpers.soviPercent(audit) *
                                      100,
                                    0,
                                  )}`}
                            </AvatarWithText>
                          </Grid>
                          <Divider
                            sx={theme => ({
                              width: '100%',
                              color: theme.palette.grey['200'],
                            })}
                          />
                          <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={{ xs: 0, sm: 0 }}
                          >
                            {audit.score?.soviScores.map(score => {
                              const percent =
                                score.customerCasesCount /
                                (score.competitorCasesCount +
                                  score.customerCasesCount)
                              console.log({
                                cust: score.customerCasesCount,
                                comp: score.competitorCasesCount,
                                cat: score.category,
                              })
                              return (
                                <Grid
                                  item
                                  key={score.category}
                                  {...gridAllScreens(6)}
                                >
                                  <AvatarWithText
                                    avatarVariant="rounded"
                                    primaryText={`% ${score.category}`}
                                  >
                                    {isNaN(percent)
                                      ? '-'
                                      : round(percent * 100)}
                                  </AvatarWithText>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Stack>
                      ),
                    },
                  }}
                />
              </Card>
            </Grid>
            <Grid item {...gridAllScreens(8)} md={7} sm={12} xs={12}>
              <Card>
                <DAITable
                  className={TableContainerClass.MODAL}
                  onRowSelect={handleOpenDisplayProducts}
                  tableToolBarProps={{
                    tableTitle: 'Displays',
                    titleSx: { fontWeight: 'bold' },
                  }}
                  rows={displayRows}
                  columns={displayColumns}
                  rowCount={displayRows?.length}
                  loading={loading}
                  disableSelectAll
                  rowsPerPageOptions={[]}
                  showCheckbox={false}
                  singleSelect
                  pageSize={10}
                />
              </Card>
            </Grid>
            <Grid item {...gridAllScreens(6)} sm={12} xs={12}>
              <Card>
                <DAITable
                  className={TableContainerClass.MODAL}
                  rows={answerRows}
                  tableToolBarProps={{
                    tableTitle: 'Answers',
                    titleSx: { fontWeight: 'bold' },
                  }}
                  columns={answerColumns}
                  rowCount={answerRows?.length}
                  loading={loading}
                  disableSelectAll
                  rowsPerPageOptions={[]}
                  showCheckbox={false}
                  singleSelect
                />
              </Card>
            </Grid>
            <Grid item {...gridAllScreens(6)} sm={12} xs={12}>
              <Card>
                <DAITable
                  className={TableContainerClass.MODAL}
                  rows={priceRows}
                  tableToolBarProps={{
                    tableTitle: 'Questions',
                    titleSx: { fontWeight: 'bold' },
                  }}
                  columns={priceColumns}
                  rowCount={priceRows?.length}
                  loading={loading}
                  disableSelectAll
                  rowsPerPageOptions={[]}
                  showCheckbox={false}
                  singleSelect
                />
              </Card>
            </Grid>
          </Grid>
        }
      />
      <DisplayAuditProductsModal
        isOpen={showDisplayProducts}
        displayTitle={displayAuditTitle}
        products={displayAuditProducts}
        handleClose={() => setShowDisplayProducts(false)}
      />
    </>
  )
}

export default AuditSummaryContainer
