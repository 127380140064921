/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import { ReportBuilder } from 'reporting/containers/Report.builder'
import { UserProductivityConstants } from 'users/user-productivity.contants'
import {
  Button,
  Chip,
  Stack,
  Tab,
  Tabs,
  TextField,
  useTheme,
} from '@mui/material'
import ReportContext from 'context/ReportContext'
import { useUserProductivityLogic } from 'users/containers/UserProductivity.logic'
import {
  FillContainerLoading,
  FilterHelpers,
  FilterLabel,
  FlexBox,
  StringHelpers,
} from '@dai/web-components'
import {
  UserProductivityReportInput,
  UserProductivityTabType,
} from 'users/users.types'
import { DAIGroupEnum } from '@dai/graphql'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { useWindowDimensions } from '@dai/common'
import { UserProductivityReportName } from 'reporting/admin-reporting.types'

const UserProductivityContainer: React.FC = () => {
  const {
    state: {
      formState,
      setFormState,
      stateKey,
      selectedReportTab,
      setSelectedReportTab,
      selectedReportGroup,
      setSelectedReportGroup,
      FilterProps,
      selectedSummaryTimeFrame,
      setSelectedSummaryTimeFrame,
      reportSearchTerm,
      setReportSearchTerm,
      tableToDownload,
      setTableToDownload,
    },
    data: {
      reports,
      summaries,
      reportTitle,
      moreInfo,
      managerUuid,
      userUuids,
      allUsers,
      allTeams,
    },
  } = useUserProductivityLogic()

  const { width } = useWindowDimensions()
  const theme = useTheme()
  const isMobile = width <= theme.breakpoints.values.sm

  if (!formState || !formState[stateKey].REPORT_TYPE)
    return <FillContainerLoading />

  const reportParams: UserProductivityReportInput = {
    userType: formState[stateKey].USER_TYPE.value as DAIGroupEnum,
    reportLevel: formState.autocomplete.REPORT_LEVEL.value as string,
    reportType: formState[stateKey].REPORT_TYPE.value as string,
    startDate: FilterProps.filter.filterBy?.dateOptions?.startDate || '',
    endDate: FilterProps.filter.filterBy?.dateOptions?.endDate || '',
    summaryTimeFrame: selectedSummaryTimeFrame,
    searchTerm: reportSearchTerm,
    managerUuid,
    userUuids,
    allUsers,
    allTeams,
    selectedReportTab,
  }

  const filterTabs = (tab: UserProductivityTabType) => {
    const tabCondition =
      reportParams.userType !== DAIGroupEnum.AUDITORS
        ? tab === 'leaderboard'
        : tab === 'leaderboard' || tab === 'overview'
    return tabCondition ? allUsers || allTeams || managerUuid : true
  }

  const handleCsvDownload = () => {
    const headers = tableToDownload.columns.map(col => col.headerName).join(',')
    const rows = tableToDownload.rows.map(row =>
      Object.keys(row)
        .filter(key => key !== 'id')
        .map(key => {
          const val: string = row[key] as string
          return val.includes(',') ? `"${val}"` : val
        })
        .join(','),
    )
    const searchTermForTitle = StringHelpers.isNullOrEmpty(reportSearchTerm)
      ? ''
      : `__search_term:"${reportSearchTerm}"`
    const file = new File(
      [`${headers}\n${rows.join('\n')}`],
      `${reportParams.userType.toLowerCase()}_${reportParams.reportType.toLowerCase()}_${reportParams.reportLevel.toLowerCase()}_overview${searchTermForTitle}.csv`,
      {
        type: 'text/csv',
      },
    )
    const link = document.createElement('a')
    const url = URL.createObjectURL(file)
    link.href = url
    link.download = file.name
    link.click()
    window.URL.revokeObjectURL(url)
  }

  return (
    <ReportContext.Provider
      value={{
        selectedSummaryGroup: formState[stateKey].REPORT_TYPE.value as string,
        selectedReportGroup,
        setSelectedReportGroup,
        setDownloadableTable: setTableToDownload,
        reportParams,
      }}
    >
      <ReportBuilder<UserProductivityReportName>
        title={reportTitle}
        moreInfo={moreInfo?.display}
        FilterAndSearch={
          <>
            {selectedReportTab === 'overview' ? (
              <FlexBox.RowBetween>
                <TextField
                  label="Search"
                  variant="outlined"
                  onChange={event => setReportSearchTerm(event.target.value)}
                  sx={{ width: isMobile ? 250 : 300 }}
                  size="small"
                />
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!tableToDownload.rows.length}
                  endIcon={<CloudDownloadOutlinedIcon color="secondary" />}
                  onClick={handleCsvDownload}
                  sx={{ height: '100%' }}
                >
                  {isMobile ? 'CSV' : 'Export as CSV'}
                </Button>
              </FlexBox.RowBetween>
            ) : (
              <Stack
                sx={theme => theme.view.row.left}
                spacing={2}
                direction={'row'}
              >
                {FilterHelpers.getDateFilterLabels(FilterProps).map(label => (
                  <div key={label.title}>
                    <FilterLabel {...label} />
                  </div>
                ))}
              </Stack>
            )}
          </>
        }
        summaryMoreInfo={
          <Stack direction="row" spacing={1}>
            {['All-Time', '30 Day Summary'].map(timeFrame => (
              <Chip
                variant={
                  timeFrame === selectedSummaryTimeFrame
                    ? 'bold-light-hoverable'
                    : 'bold-dark-hoverable'
                }
                label={timeFrame}
                onClick={() =>
                  setSelectedSummaryTimeFrame(
                    timeFrame as UserProductivityReportInput['summaryTimeFrame'],
                  )
                }
              />
            ))}
          </Stack>
        }
        reports={reports}
        summaries={summaries}
        HeaderRightComponent={
          <Tabs
            value={UserProductivityConstants.REPORT_TABS.filter(
              filterTabs,
            ).indexOf(selectedReportTab)}
          >
            {UserProductivityConstants.REPORT_TABS.filter(filterTabs).map(
              tab => (
                <Tab
                  key={tab}
                  label={tab}
                  onClick={() => setSelectedReportTab(tab)}
                />
              ),
            )}
          </Tabs>
        }
        inputForm={{
          formState,
          setFormState,
          orderOfForms: ['autocomplete', 'select', 'radio'],
        }}
        FilterProps={FilterProps}
      />
    </ReportContext.Provider>
  )
}

export default UserProductivityContainer
