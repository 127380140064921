/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import {
  FlexBox,
  ActionBar,
  FillContainerLoading,
  NotificationAlert,
  StoresHelpers,
  UserHelpers,
  Square,
} from '@dai/web-components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CardMedia,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  SpeedDialIcon,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import {
  Add,
  ChangeCircle,
  Check,
  Close,
  ExpandMore,
  Person2,
  Save,
} from '@mui/icons-material'
import { Edit, ShoppingCart, Trash } from 'react-feather'
import { UserFragment } from '@dai/graphql'
import UsersModalFilterByRole from './UsersModalFilterByRole'
import AddStoresToRouteModal from './AddStoresToRouteModal'
import {
  TerritoryHierarchyProps,
  useTerritoryHierarchyLogic,
} from './TerritoryHierachy.logic'

const TerritoryHierarchy: React.FC<TerritoryHierarchyProps> = props => {
  const {
    state: {
      routes,
      selectedRoute,
      setSelectedRoute,
      savingMutation,
      modalContext,
      setModalContext,
      districtManager,
      setDistrictManager,
      territoryName,
      setTerritoryName,
      usersToAssignToTerritory,
      territoryManager,
      setTerritoryManager,
    },
    handle: {
      handleAddRoute,
      handleAddStoresToRoute,
      handleEditRouteManager,
      handleDeleteRoute,
      handleRemoveStoreFromRoute,
      handleSaveTerritory,
      handleUpdateRouteName,
      handleAssignUsersToTerritory,
    },
    data: { loading, districtManagerOptions },
    DistrictManagersLQ,
  } = useTerritoryHierarchyLogic(props)
  const theme = useTheme()

  if (loading) {
    return (
      <Box sx={{ flex: 1 }}>
        <NotificationAlert />
        <FillContainerLoading />
      </Box>
    )
  } else if (!props.selectedUser) {
    return (
      <Box sx={{ flex: 1 }}>
        <NotificationAlert />
      </Box>
    )
  }

  return (
    <>
      <NotificationAlert />
      <Backdrop
        sx={theme => ({
          color: theme.palette.primary.light,
          zIndex: theme.zIndex.drawer + 1,
        })}
        open={savingMutation}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FlexBox.RowBetween px={2} pt={2}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant="h4">
            {territoryManager &&
              UserHelpers.formatName({ user: territoryManager })}
          </Typography>

          <Button
            color="secondary"
            startIcon={<Edit />}
            onClick={() => {
              setModalContext('edit-territory-manager')
            }}
          >
            Edit Territory Manager
          </Button>
        </Stack>

        {props.selectedUser!.territoryId && (
          <Chip
            icon={<Check color="success" />}
            label="Territory Created"
            variant="outlined"
            color="success"
          />
        )}
      </FlexBox.RowBetween>
      <Stack spacing={2} p={2}>
        <Stack direction={'row'} spacing={4} alignItems={'center'}>
          <TextField
            value={territoryName || ''}
            onChange={event => {
              setTerritoryName(event.target.value)
            }}
            placeholder="Give a name for this territory..."
            label="Territory Name"
            variant="outlined"
            fullWidth
          />
          <Tooltip title="Click to assign more users to this territory">
            <Button
              fullWidth
              sx={{ height: '100%' }}
              variant="outlined"
              color="secondary"
              onClick={() => setModalContext('assign-users-to-territory')}
            >
              {usersToAssignToTerritory.length
                ? `${usersToAssignToTerritory.length} users assigned to territory`
                : 'Assign users to territory'}
            </Button>
          </Tooltip>
          {districtManager ? (
            <TextField
              value={UserHelpers.formatName({ user: districtManager })}
              label="District Manager"
              disabled
              fullWidth
              InputProps={{
                startAdornment: <Person2 sx={{ mr: 2 }} />,
                endAdornment: (
                  <Tooltip title="Change District Manager">
                    <IconButton onClick={() => setDistrictManager(undefined)}>
                      <ChangeCircle color="inherit" />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          ) : (
            <Autocomplete
              fullWidth
              autoHighlight
              openOnFocus
              getOptionLabel={user => UserHelpers.formatName({ user })}
              options={districtManagerOptions}
              loading={DistrictManagersLQ.lazyQuery.meta.loading}
              placeholder="Search Users..."
              onChange={(e, manager) => {
                if (manager) {
                  setDistrictManager(manager as UserFragment)
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="District Manager"
                  onChange={e =>
                    DistrictManagersLQ.setItemQuery(e.target.value)
                  }
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <Person2 sx={{ mr: 2 }} />,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
          <Stack direction="row" spacing={4}>
            <Stack direction="row" spacing={2}>
              <Square
                size={35}
                color={theme.palette.secondary.main}
                variant="fill"
              >
                {routes.length}
              </Square>
              <Typography variant={'h5'}>Routes</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Square
                size={35}
                color={theme.palette.secondary.main}
                variant="fill"
              >
                {routes.reduce((sum, route) => sum + route.stores.length, 0)}
              </Square>
              <Typography variant={'h5'}>Stores</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Box>
          <Button
            color="secondary"
            startIcon={<Add />}
            onClick={() => setModalContext('add-route')}
          >
            Add Route
          </Button>
        </Box>
        <Stack spacing={2}>
          {routes.map((route, routeIndex) => (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FlexBox.RowBetween flex={1} pr={2}>
                  <Stack spacing={1} flex={1}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                      <Typography variant="h6">
                        {UserHelpers.formatName({ user: route.rar })}
                      </Typography>
                      <Button
                        color="secondary"
                        startIcon={<Edit />}
                        onClick={() => {
                          setModalContext('edit-route-manager')
                          setSelectedRoute(route)
                        }}
                      >
                        Edit Route Manager
                      </Button>
                    </Stack>

                    <Stack
                      direction={'row'}
                      spacing={2}
                      alignContent={'center'}
                    >
                      <Square
                        size={35}
                        color={theme.palette.secondary.main}
                        variant="fill"
                      >
                        {route.stores.length}
                      </Square>
                      <Typography
                        sx={theme => theme.view.col.center}
                        variant={'h5'}
                      >
                        Stores
                      </Typography>
                    </Stack>
                  </Stack>
                  {route.approvedBy && (
                    <Chip
                      icon={<Check color="success" />}
                      label={`Approved By: ${UserHelpers.formatName({
                        user: route.approvedBy,
                      })}`}
                      variant="outlined"
                      color="success"
                    />
                  )}
                </FlexBox.RowBetween>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  value={route.routeName || ''}
                  onChange={event => {
                    handleUpdateRouteName(event.target.value, routeIndex)
                  }}
                  placeholder="Give a name for this route..."
                  label="Route Name"
                  variant="outlined"
                  fullWidth
                />
                <List>
                  {route.stores.map(store => (
                    <ListItem divider>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent={'space-between'}
                        flex={1}
                      >
                        <Stack direction="row" spacing={2}>
                          <CardMedia
                            image={store.image?.url}
                            sx={theme => ({
                              height: theme.sizes.img.sm,
                              width: theme.sizes.img.sm,
                              borderRadius: 2,
                            })}
                          />
                          <Stack spacing={0.5} justifyContent={'center'}>
                            <Typography variant="subtitle1" fontWeight={'bold'}>
                              {store.name}
                            </Typography>
                            <Box>
                              {store.address && (
                                <Typography>
                                  {StoresHelpers.formatAddress(store.address, {
                                    newLineABeforeCity: true,
                                  })}
                                </Typography>
                              )}
                              <Typography variant="caption">
                                Internal Customer ID: {store.internalStoreId}
                              </Typography>
                            </Box>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Tooltip title="Delete Store From Route">
                        <IconButton
                          onClick={() =>
                            handleRemoveStoreFromRoute(routeIndex, store.id)
                          }
                          color="primary"
                        >
                          <Trash />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                  ))}
                </List>
                <FlexBox.RowBetween>
                  <Button
                    startIcon={<Trash />}
                    onClick={() => handleDeleteRoute(routeIndex)}
                  >
                    Delete Route
                  </Button>
                  <Button
                    color="secondary"
                    startIcon={<Add />}
                    onClick={() => {
                      setModalContext('add-store')
                      setSelectedRoute(route)
                    }}
                  >
                    Add Store To Route
                  </Button>
                </FlexBox.RowBetween>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          bottom: 24,
          right: 24,
        }}
      >
        <ActionBar
          speedDialIcon={
            <SpeedDialIcon
              icon={<ShoppingCart color="secondary" />}
              openIcon={<Close color="secondary" />}
            />
          }
          bulkActions={[
            {
              condition: true,
              onClick: handleSaveTerritory,
              icon: <Save color="secondary" />,
              label: 'Save',
              disabled:
                routes.some(route => !route.stores.length) ||
                savingMutation ||
                !districtManager,
            },
          ]}
        />
      </Box>
      <UsersModalFilterByRole
        title="Add Route"
        subtitle="Assign a sales user to this route"
        onSave={users => handleAddRoute(users[0])}
        isOpen={modalContext === 'add-route'}
        handleClose={() => setModalContext(undefined)}
        usersToOmit={routes.map(route => route.rar.id)}
        initialRoles={['Sales']}
      />
      <UsersModalFilterByRole
        title="Edit Route Manager"
        subtitle="Assign a sales user to this route"
        onSave={users => {
          if (selectedRoute) {
            const selectedIndex = routes.indexOf(selectedRoute)
            handleEditRouteManager(selectedRoute, selectedIndex, users[0])
          }
        }}
        isOpen={modalContext === 'edit-route-manager'}
        handleClose={() => setModalContext(undefined)}
        usersToOmit={routes.map(route => route.rar.id)}
        initialRoles={['Sales']}
      />
      <UsersModalFilterByRole
        title="Edit Territory Manager"
        subtitle="Assign an FSM to this territory"
        onSave={users => {
          setTerritoryManager(users[0])
        }}
        isOpen={modalContext === 'edit-territory-manager'}
        handleClose={() => setModalContext(undefined)}
        usersToOmit={territoryManager && [territoryManager.id]}
        initialRoles={['Sales Managers']}
      />
      <UsersModalFilterByRole
        title="Assign users to territory"
        subtitle="Assign non-managers to this territory"
        onSave={handleAssignUsersToTerritory}
        isOpen={modalContext === 'assign-users-to-territory'}
        handleClose={() => setModalContext(undefined)}
        usersToOmit={[
          ...routes.map(route => route.rar.id),
          territoryManager?.id || '',
        ]}
        multiselect
        initialRoles={[
          'Sales',
          'Sales Managers',
          'Merchandiser Managers',
          'Sales Admins',
          'Merchandisers',
        ]}
        initialSelected={usersToAssignToTerritory.map(u => u.id)}
      />
      <AddStoresToRouteModal
        onSave={(selectedRoute, stores) => {
          const selectedIndex = routes.indexOf(selectedRoute)!
          handleAddStoresToRoute(selectedRoute, selectedIndex, stores)
        }}
        isOpen={modalContext === 'add-store'}
        handleClose={() => {
          setModalContext(undefined)
          setSelectedRoute(undefined)
        }}
        selectedRoute={selectedRoute}
        storeIdsToIgnore={routes.flatMap(route =>
          route.stores.map(store => store.id),
        )}
      />
    </>
  )
}

export default TerritoryHierarchy
