/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import { SelectableOption } from '@dai/web-components'
import { FormPicOSPriority } from 'picos/picos.types'
import { PicosConstants } from 'picos/picos.constants'
import { StoreCategory } from '@dai/graphql'

export interface PicOSState {
  selectedBanner: SelectableOption | undefined
  setAndSaveSelectedBanner: (banner: SelectableOption) => void
  priorityToEdit: FormPicOSPriority
  setPriorityToEdit: (priority: FormPicOSPriority) => void
  storeTypesInCustomer: StoreCategory[]
  setStoreTypesInCustomer: (storeTypes: StoreCategory[]) => void
  setIsNewPriority: (isNew: boolean) => void
  isNewPriority: boolean
}

const PicOSContext = React.createContext<PicOSState>({
  selectedBanner: undefined,
  setAndSaveSelectedBanner: _ => null,
  priorityToEdit: PicosConstants.EMPTY_PICOS,
  setPriorityToEdit: _ => null,
  storeTypesInCustomer: [],
  setStoreTypesInCustomer: _ => null,
  isNewPriority: true,
  setIsNewPriority: _ => null,
})

export default PicOSContext
