/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { DAIGroupEnum } from '@dai/graphql'
import { ReportDisplayRules } from 'reporting/admin-reporting.types'

export type LocalStorageCompanyUser = {
  name: string
  email: string
  roles: DAIGroupEnum[]
  subUsers: string[]
}

export type LocalStorage = {
  companyUsers?: Record<string, LocalStorageCompanyUser>
  banner?: {}
  reportRules?: ReportDisplayRules<string>
}

export const useLocalStorage = () => {
  const setItem = <T extends keyof LocalStorage>(
    key: T,
    value: LocalStorage[T],
  ) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  const getItem = <T extends keyof LocalStorage>(
    key: T,
  ): LocalStorage[T] | undefined => {
    const item = localStorage.getItem(key)
    if (item) {
      return JSON.parse(item)
    }
    return undefined
  }

  return {
    Storage: {
      getItem,
      setItem,
    },
  }
}
