/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useGetCompanyUsers, useGetGroups } from '@dai/common'
import {
  GET_COMPANY_USERS,
  GetCompanyUsersQuery,
  GetCompanyUsersQueryVariables,
  UserFragment,
} from '@dai/graphql'
import {
  DAITable,
  DAITableColumns,
  Filter,
  FilterHelpers,
  MuiFormModal,
  MuiFormModalProps,
  MuiTheme,
  NotificationContext,
  SearchBar,
  StringHelpers,
  TableContainerClass,
  useCommonFilter,
  useDebouncedItemQuery,
} from '@dai/web-components'
import { useDebounce } from 'use-debounce'
import { SimpleUserRow } from 'users/users.types'
import { Save } from '@mui/icons-material'
import { useContext, useEffect, useRef, useState } from 'react'
import { Button } from '@mui/material'

type AddRouteModalProps = {
  initialRoles?: string[]
  usersToOmit?: string[]
  multiselect?: boolean
  initialSelected?: string[]
  onSave: (users: UserFragment[]) => void
} & Omit<MuiFormModalProps, 'actionButtonsProps'>

const columns: DAITableColumns<SimpleUserRow>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: MuiTheme.sizes.table.column.width.xl,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: MuiTheme.sizes.table.column.width.xl,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: MuiTheme.sizes.table.column.width.lg,
  },
]

const PAGE_SIZE = MuiTheme.sizes.table.page.sm

const UsersModalFilterByRole: React.FC<AddRouteModalProps> = props => {
  const {
    onSave,
    handleClose,
    usersToOmit = [],
    initialRoles = [],
    multiselect = false,
    initialSelected = [],
  } = props
  const [userSearch, setUserSearch] = useState('')
  const [debouncedUserSearchQuery] = useDebounce(userSearch, 500)
  const UserQuery = useDebouncedItemQuery<
    GetCompanyUsersQuery,
    GetCompanyUsersQueryVariables
  >({
    queryStr: GET_COMPANY_USERS,
    options: { fetchPolicy: 'cache-first' },
  })
  const { setError } = useContext(NotificationContext)
  const { groups } = useGetGroups()

  const FilterProps = useCommonFilter({
    filterBy: {
      selected: [],
      options: [
        {
          label: 'Role',
          selected: [] as string[],
          subOptions:
            groups?.map(group => ({
              value: group.id,
              display: group.name,
            })) || [],
        },
      ],
    },
  })
  const { users, count, loading, fetchNextPage } = useGetCompanyUsers({
    limit: PAGE_SIZE,
    searchTerm: debouncedUserSearchQuery,
    userRoles: FilterHelpers.getSubOptions(FilterProps.filter.filterBy!, 'Role')
      ?.selected,
  })
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>(
    initialSelected,
  )
  const tableRef = useRef<DAITable<SimpleUserRow>>(null)

  useEffect(() => {
    tableRef.current?.returnToFirstPage()
  }, [debouncedUserSearchQuery])

  useEffect(() => {
    if (groups?.length && initialRoles?.length) {
      const roles = groups?.filter(g => initialRoles.includes(g.name))
      FilterProps.setFilter({
        filterBy: {
          selected: [],
          options: [
            {
              label: 'Role',
              selected: roles.map(role => role.id),
              subOptions:
                groups.map(group => ({
                  value: group.id,
                  display: group.name,
                })) || [],
            },
          ],
        },
      })
    }
  }, [groups?.length, initialRoles?.length])

  const handlePageChange = async (page: number, cursorPosition: number) => {
    if (cursorPosition >= users.length) {
      await fetchNextPage()
    }
  }

  const userRows = users
    .filter(user => !usersToOmit.includes(user.id))
    .map(user => ({
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      role: user.mainRole
        ? StringHelpers.camelCaseToTitleCase(user.mainRole)
        : '',
    }))

  return (
    <MuiFormModal
      {...props}
      actionButtonsProps={[
        {
          children: 'Save',
          startIcon: <Save color="secondary" />,
          onClick: async () => {
            const users = await UserQuery.lazyQuery
              .query({
                variables: {
                  input: {
                    userIds: selectedUserIds,
                  },
                },
              })
              .then(users => {
                if (users.error) {
                  setError(
                    `An error occurred while trying to save users: ${users.error}`,
                  )
                } else {
                  onSave(users.data?.companyUsers.results || [])
                }
              })
              .catch(error => {
                setError(
                  `An error occurred while trying to save users: ${error}`,
                )
              })
              .finally(handleClose)
          },
        },
      ]}
    >
      <DAITable
        ref={tableRef}
        rows={userRows}
        columns={columns}
        className={TableContainerClass.MODAL}
        tableToolBarProps={{
          tableTitle: 'Users',
          right: {
            others: [
              multiselect ? (
                <Button
                  onClick={() => {
                    setSelectedUserIds([])
                    tableRef.current?._setSelected([])
                  }}
                >
                  Clear Users
                </Button>
              ) : null,
              <SearchBar
                useResponsive
                onChange={value => setUserSearch(value)}
              />,
              <Filter filterTitle="Filter Users" {...FilterProps} />,
            ],
          },
        }}
        pageSize={PAGE_SIZE}
        rowCount={count}
        onPageChange={handlePageChange}
        loading={loading}
        initialSelected={initialSelected}
        disableSelectAll
        singleSelect={!multiselect}
        showCheckbox
        onSelectionChange={setSelectedUserIds}
      />
    </MuiFormModal>
  )
}

export default UsersModalFilterByRole
