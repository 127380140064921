/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { LocalStorage, LocalStorageCompanyUser } from 'hooks/use-local-storage'
import { StringHelpers } from '@dai/web-components'
import { DAIGroupEnum } from '@dai/graphql'
import { UserProductivityReportingConstants } from 'users/constants/reporting/user-productivity-reporting.constants'

const filterUsersBySelectedUserType = (
  user: LocalStorageCompanyUser,
  userEntries: [string, LocalStorageCompanyUser][],
  selectedUserType: DAIGroupEnum,
) => {
  const hasRole =
    user.roles.length && user.roles.includes(selectedUserType as DAIGroupEnum)
  if (!hasRole) {
    return userEntries
      .filter(([id, _]) => user.subUsers.includes(id))
      .some(
        ([_, subUser]) =>
          subUser.roles.length &&
          subUser.roles.includes(selectedUserType as DAIGroupEnum),
      )
  } else {
    return true
  }
}

const getReportLevelOptions = (
  companyUsers: LocalStorage['companyUsers'],
  selectedUserType?: DAIGroupEnum,
) => {
  const filterFn = selectedUserType
    ? filterUsersBySelectedUserType
    : (..._: any[]) => true
  const userEntries = Object.entries(companyUsers!).filter(
    ([_, user]) => user.roles.length,
  )
  const levels = userEntries
    .filter(([_, user]) => filterFn(user, userEntries, selectedUserType!))
    .reduce<{ display: string; group: string; value: string }[]>(
      (acc, [id, user]) => [
        ...acc,
        {
          display: user.name,
          value: id,
          group: formatUserGroup(user),
        },
      ],
      [],
    )
    .sort((a, b) => {
      const aIsManager = a.group.toLowerCase().includes('manager')
      const bIsManager = b.group.toLowerCase().includes('manager')
      if (aIsManager && !bIsManager) {
        return -1
      }
      if (!aIsManager && bIsManager) {
        return 1
      }
      return aIsManager <= bIsManager ? -1 : 1
    })
  return [
    {
      display: UserProductivityReportingConstants.ALL_USERS,
      value: UserProductivityReportingConstants.ALL_USERS,
      group: '',
    },
    {
      display: UserProductivityReportingConstants.ALL_TEAMS,
      value: UserProductivityReportingConstants.ALL_TEAMS,
      group: '',
    },
    ...levels,
  ]
}

const formatUserGroup = (user: LocalStorageCompanyUser) => {
  if (user.subUsers.length) {
    const managerRole = user.roles.find(r =>
      r.toLowerCase().includes('manager'),
    )
    return StringHelpers.stringValue(managerRole || `${user.roles[0]} Managers`)
  }
  return StringHelpers.stringValue(user.roles[0])
}

const setLeaderboardTitle = (
  stateFn: (
    args: typeof UserProductivityReportingConstants.EMPTY_LEADERBOARD_TITLE,
  ) => void,
  range: number,
  allTeams: boolean = false,
) => {
  if (range) {
    stateFn({
      type: allTeams ? 'Teams' : 'Users',
      range: String(
        Math.min(
          UserProductivityReportingConstants.LEADERBOARD_DEFAULT_LIMIT,
          range,
        ),
      ),
    })
  }
}

const formatPct = (value: number | null) =>
  value ? `${(value * 100).toFixed(2)}%` : 'N/A'

export const UserProductivityReportHelpers = {
  getReportLevelOptions,
  setLeaderboardTitle,
  formatPct,
}
