/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Mitch Wilson <mitchell.wilson@deliciousai.com>
 */

import { useEffect, useState } from 'react'
import {
  StringHelpers,
  useCommonFilter,
  useMuiForm,
  SimpleOptionSelect,
} from '@dai/web-components'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  AddImageMutation,
  AddImageMutationVariables,
  ADD_IMAGE,
  Training_training,
  UpdateTrainingMutation,
  UPDATE_TRAINING,
  UpdateTrainingMutationVariables,
  GET_ALL_TRAININGS,
  CreateTrainingMutation,
  CreateTrainingMutationVariables,
  CREATE_TRAINING,
  DELETE_TRAINING,
  DeleteTrainingMutation,
  DeleteTrainingMutationVariables,
  VideoMetaData,
} from '@dai/graphql'
import { DZFile, uniqueId } from '@dai/common'
import { useNotificationState } from '@dai/web-components'
import { isArray } from 'lodash'
import { uploadImageOrVideoToFirebaseBucket } from '../../../helpers/firebase.helpers'
import { useEditTrainingForm } from './hooks/use-edit-training.form'

type TrainingFilterType = {
  filterBy: SimpleOptionSelect<string[]>
  sortBy: SimpleOptionSelect<string>
}

export type Training = {
  title: string
  description: string
  id: string
  timeRequired: number
  groups: string[] | null
  deviceType: string
  trainingImageId: string
  keyTakeaways?: string[] | null
  videoUrl?: string | null
}

export const useTrainingsLogic = () => {
  const [
    showConfirmSaveTraining,
    setShowConfirmSaveTraining,
  ] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const notificationState = useNotificationState()
  const [trainingEditing, setTrainingEditing] = useState<Training | undefined>({
    id: '',
    title: '',
    description: '',
    timeRequired: 0,
    deviceType: '',
    groups: [],
    keyTakeaways: [],
    trainingImageId: '',
  })
  const [originalTraining, setOriginalTraining] = useState<
    Training_training | undefined
  >()
  const [selectedTrainingId, setSelectedTrainingId] = useState<
    string | undefined
  >()

  const [allTrainings, setAllTrainings] = useState<Training_training[]>([])

  const [filteredTrainings, setFilteredTrainings] = useState<
    Training_training[]
  >(allTrainings)
  const [videoFile, setVideoFile] = useState<DZFile>()
  const [searchTerm, setSearchTerm] = useState<string>()
  const [addImage] = useMutation<AddImageMutation, AddImageMutationVariables>(
    ADD_IMAGE,
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [addingImage, setAddingImage] = useState(false)
  const [imageUrlToDisplay, setImageUrlToDisplay] = useState<string>('')

  const [getAllTrainings, data] = useLazyQuery(GET_ALL_TRAININGS, {
    fetchPolicy: 'cache-first',
  })

  const handleUpdateSearchValue = (searchValue: string) => {
    if (!StringHelpers.isNullOrEmpty(searchValue)) {
      setSearchTerm(searchValue)
    }
  }

  useEffect(() => {
    getAllTrainings()
  }, [])

  useEffect(() => {
    setAllTrainings(data?.data?.trainings || [])
    if (!allTrainings || allTrainings.length === 0) {
      setSelectedTrainingId('')
    }
    setLoading(false)
  }, [data])

  useEffect(() => {
    setSelectedTrainingId(allTrainings[0]?.id || '')
  }, [allTrainings])

  useEffect(() => {
    if (!searchTerm) {
      setFilteredTrainings(allTrainings)
    } else {
      setFilteredTrainings(
        allTrainings.filter(tr => {
          return (
            tr.title.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            tr.description.toLowerCase().includes(searchTerm.toLowerCase())
          )
        }),
      )
    }
  }, [searchTerm, allTrainings])

  useEffect(() => {
    const tr = allTrainings.find(tr => tr.id === selectedTrainingId)
    setTrainingEditing({
      title: tr?.title || '',
      description: tr?.description || '',
      id: tr?.id || '',
      timeRequired: tr?.timeRequired || 0,
      // @ts-ignore
      groups: tr?.groups?.map(g => g.id) || [],
      keyTakeaways: tr?.keyTakeaways || [],
      deviceType: tr?.deviceType || '',
      trainingImageId: tr?.trainingImage.id || '',
      videoUrl: tr?.videoUrl,
      ...tr,
    })
    setOriginalTraining(tr)
    setImageUrlToDisplay(tr?.trainingImage.url || '')
  }, [selectedTrainingId])

  const defaultFilter: TrainingFilterType = {
    filterBy: {
      selected: [],
      options: [
        {
          label: 'Groups',
          selected: [],
          subOptions: ['Auditors', 'Merchandisers'].map(opt => ({
            value: opt,
            display: opt,
          })),
        },
      ],
    },
    sortBy: {
      selected: '',
      options: [],
    },
  }

  const FilterProps = useCommonFilter(defaultFilter)

  const [updateTraining] = useMutation<
    UpdateTrainingMutation,
    UpdateTrainingMutationVariables
  >(UPDATE_TRAINING)

  const [createTraining] = useMutation<
    CreateTrainingMutation,
    CreateTrainingMutationVariables
  >(CREATE_TRAINING)

  const refresh = () => {
    window.location.reload()
  }

  const getDeviceTypeFromFormState = () => {
    if (
      formState.select.DEVICE_TYPE.value &&
      isArray(formState.select.DEVICE_TYPE.value)
    ) {
      return formState.select.DEVICE_TYPE.value[0]
    } else if (formState.select.DEVICE_TYPE.value) {
      return formState.select.DEVICE_TYPE.value
    } else {
      return 'Both'
    }
  }

  const getKeyTakeawaysFromFormState = () => {
    const keyTakeawayList: string[] = []
    if (formState.text.KEY_TAKEAWAY_1.value?.toString())
      keyTakeawayList.push(formState.text.KEY_TAKEAWAY_1.value?.toString())
    if (formState.text.KEY_TAKEAWAY_2.value?.toString())
      keyTakeawayList.push(formState.text.KEY_TAKEAWAY_2.value?.toString())
    if (formState.text.KEY_TAKEAWAY_3.value?.toString())
      keyTakeawayList.push(formState.text.KEY_TAKEAWAY_3.value?.toString())
    if (formState.text.KEY_TAKEAWAY_4.value?.toString())
      keyTakeawayList.push(formState.text.KEY_TAKEAWAY_4.value?.toString())
    return keyTakeawayList
  }

  const getVideoMetaDataForMutationInput = async () => {
    if (videoFile) {
      const { fileUuid, bucketPath } = await uploadImageOrVideoToFirebaseBucket(
        videoFile,
        'videos',
        uniqueId(),
        'training-videos',
      )
      const videoMetaData: VideoMetaData = {
        id: fileUuid,
        bucketFilePath: bucketPath,
      }
      return videoMetaData
    }
  }

  const handleSubmitNewTraining = async () => {
    if (trainingEditing) {
      const video = await getVideoMetaDataForMutationInput()
      createTraining({
        variables: {
          input: {
            title: formState.text.TITLE.value?.toString() || '',
            description: formState.text.DESCRIPTION.value?.toString() || '',
            timeRequired: formState.text.TIME_REQUIRED.value
              ? +formState.text.TIME_REQUIRED.value
              : 0,
            deviceType: getDeviceTypeFromFormState(),
            // @ts-ignore
            groups: formState.select.GROUPS.value || [],
            keyTakeaways: getKeyTakeawaysFromFormState(),
            trainingImage: trainingEditing.trainingImageId,
            video,
          },
        },
      })
        .then(refresh)
        .catch(e => {
          console.error(e)
        })
    }
  }

  const handleUpdateTraining = async () => {
    if (trainingEditing) {
      const newVideo = await getVideoMetaDataForMutationInput()
      updateTraining({
        variables: {
          input: {
            id: trainingEditing.id,
            title: formState.text.TITLE.value?.toString() || '',
            description: formState.text.DESCRIPTION.value?.toString() || '',
            timeRequired: formState.text.TIME_REQUIRED.value
              ? +formState.text.TIME_REQUIRED.value
              : 0,
            deviceType: getDeviceTypeFromFormState(),
            // @ts-ignore
            groups: formState.select.GROUPS.value || [],
            keyTakeaways: getKeyTakeawaysFromFormState(),
            trainingImage: trainingEditing.trainingImageId,
            newVideo,
          },
        },
      })
        .then(refresh)
        .catch(() => {
          console.error('Error updating the training')
        })
    }
  }

  const resetTraining = () => {
    if (originalTraining) {
      setTrainingEditing({
        trainingImageId: originalTraining.trainingImage.id,
        // @ts-ignore
        groups: originalTraining.groups?.map(g => g.id) || [],
        ...originalTraining,
      })
      setImageUrlToDisplay(originalTraining.trainingImage.url)
    }
  }

  const { formState, setFormState } = useEditTrainingForm({
    trainingEditing,
    originalTraining,
  })

  const {
    data: { canSubmit },
    handle: { handleOnChange },
  } = useMuiForm({
    formState,
    setFormState,
  })

  const handleUploadImage = async (imageFile: DZFile) => {
    setAddingImage(true)
    const imageId = uniqueId()

    const { bucketPath } = await uploadImageOrVideoToFirebaseBucket(
      imageFile,
      'images',
      imageId,
      'training',
    )

    addImage({
      variables: {
        input: {
          id: imageId,
          bucketFilePath: bucketPath,
          isTrainingImage: true,
        },
      },
    })
      .then(res => {
        if (res?.data?.addImage?.image) {
          const image = res.data.addImage.image
          setTrainingEditing(
            t =>
              t && {
                ...t,
                trainingImageId: image.id,
              },
          )
          setImageUrlToDisplay(image.url)
          setAddingImage(false)
          notificationState.setSuccess('Successfully uploaded 1 image.')
        }
      })
      .catch(err => {
        notificationState.setError(err)
        setAddingImage(false)
      })
  }

  const [deleteTraining] = useMutation<
    DeleteTrainingMutation,
    DeleteTrainingMutationVariables
  >(DELETE_TRAINING)

  const handleDeleteTraining = () => {
    if (trainingEditing && trainingEditing.id) {
      deleteTraining({
        variables: {
          input: trainingEditing.id,
        },
      })
        .then(() => {
          refresh()
        })
        .catch(err => {
          notificationState.setError(err)
        })
    }
  }

  const [hasNewTitle, setHasNewTitle] = useState<boolean>(false)

  useEffect(() => {
    setHasNewTitle(
      !!originalTraining &&
        !!formState.text.TITLE.value &&
        originalTraining.title !== formState.text.TITLE.value?.toString(),
    )
  }, [originalTraining, formState.text.TITLE.value])

  return {
    state: {
      allTrainings,
      trainingEditing,
      setTrainingEditing,
      selectedTrainingId,
      setSelectedTrainingId,
      modalOpen,
      setModalOpen,
      formState,
      showConfirmSaveTraining,
      setShowConfirmSaveTraining,
      imageUrlToDisplay,
      FilterProps,
      filteredTrainings,
      videoFile,
      setVideoFile,
    },
    data: {
      addingImage,
      canSubmit: canSubmit && hasNewTitle,
      canUpdateTraining: canSubmit && !!trainingEditing,
      loading,
    },
    handle: {
      clearForm: resetTraining,
      handleOnChange,
      handleUpdateTraining,
      handleUploadImage,
      handleSubmitNewTraining,
      handleDeleteTraining,
      handleUpdateSearchValue,
    },
  }
}
