"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_PICOS_PRIORITY = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.UPDATE_PICOS_PRIORITY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation UpdatePicOSPriority($input: UpdatePicOSPriorityInput!) {\n    updatePicosPriority(input: $input) {\n      picosPriority {\n        id\n        name\n        priority\n        dateCreated\n        startDate\n        endDate\n        description\n        mandateType\n        displayElements\n        displayTypes {\n          name\n        }\n        displayLocation {\n          name\n        }\n        swirePosElementRequired\n        otherCategoriesAllowed\n        otherBrandsAllowed\n        otherPackagingAllowed\n        otherProductsAllowed\n        storeGroup {\n          name\n        }\n        creator {\n          ...User\n        }\n      }\n    }\n  }\n  ", "\n"], ["\n  mutation UpdatePicOSPriority($input: UpdatePicOSPriorityInput!) {\n    updatePicosPriority(input: $input) {\n      picosPriority {\n        id\n        name\n        priority\n        dateCreated\n        startDate\n        endDate\n        description\n        mandateType\n        displayElements\n        displayTypes {\n          name\n        }\n        displayLocation {\n          name\n        }\n        swirePosElementRequired\n        otherCategoriesAllowed\n        otherBrandsAllowed\n        otherPackagingAllowed\n        otherProductsAllowed\n        storeGroup {\n          name\n        }\n        creator {\n          ...User\n        }\n      }\n    }\n  }\n  ", "\n"])), fragments_1.USER_FRAGMENT);
var templateObject_1;
