"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SABRE_PACKAGING_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
exports.SABRE_PACKAGING_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Packaging on Packaging {\n    id\n    size\n    unit\n    quantity\n    container\n  }\n"], ["\n  fragment Packaging on Packaging {\n    id\n    size\n    unit\n    quantity\n    container\n  }\n"])));
var templateObject_1;
