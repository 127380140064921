/**
 * Copyright © 2023 Delicious AI, LLC
 */

import { useEffect, useState } from 'react'
import {
  DAIFormState,
  FILL_GRID_ALL_SCREENS,
  gridAllScreens,
  StringHelpers,
  useDebouncedItemQuery,
} from '@dai/web-components'
import {
  SabreCategoriesQuery,
  SabreCategoriesQueryVariables,
  SABRE_GET_CATEGORIES,
  SabreCategoryFragment,
} from '@dai/graphql'
import { SelectableOption } from '@dai/web-components'

export const useCreateEditCategoriesForm = ({
  category,
  handleNameChange,
  handleParentChange,
  searchResults,
  setSearchResults,
}: {
  category: SabreCategoryFragment | undefined
  handleNameChange: (name: string) => void
  handleParentChange: (parentId: string) => void
  searchResults?: SelectableOption[]
  setSearchResults: React.Dispatch<React.SetStateAction<SelectableOption[]>>
}) => {
  const NAME_LENGTH_LIMIT = 255

  const CategoriesLazyQuery = useDebouncedItemQuery<
    SabreCategoriesQuery,
    SabreCategoriesQueryVariables
  >({
    queryStr: SABRE_GET_CATEGORIES,
    options: { context: { endPoint: 'sabre' } },
  })

  useEffect(() => {
    const results =
      (CategoriesLazyQuery.lazyQuery.meta.data?.categories.results.map(
        (c: { name: any; id: any }) => ({
          value: c.name,
          id: c.id,
          display: c.name,
        }),
      ) as SelectableOption[]) || ([] as SelectableOption[])
    setSearchResults(results)
  }, [CategoriesLazyQuery.lazyQuery.meta.data])

  useEffect(() => {
    if (!StringHelpers.isNullOrEmpty(CategoriesLazyQuery.debouncedQuery)) {
      CategoriesLazyQuery.lazyQuery.query({
        variables: {
          input: {
            categoryName: CategoriesLazyQuery.debouncedQuery,
          },
        },
      })
    }
  }, [CategoriesLazyQuery.debouncedQuery, CategoriesLazyQuery.lazyQuery.query])

  useEffect(() => {
    if (
      StringHelpers.isNullOrEmpty(CategoriesLazyQuery.debouncedQuery) &&
      category?.parent?.name
    ) {
      CategoriesLazyQuery.lazyQuery.query({
        variables: {
          input: {
            categoryName: category.parent.name,
          },
        },
      })
    }
  }, [category])

  const EDIT_CATEGORY_FORM = (input: {
    categoryName?: string
    parentName?: string
    searchResults: SelectableOption[]
  }) =>
    ({
      select: {},
      autocomplete: {},
      text: {
        NAME: {
          input: {
            placeholder: 'i.e Beverage',
            required: true,
            type: 'text',
            variant: 'standard',
            label: 'Name',
          },
          validationFn: (value: string | any[]) =>
            value.length > NAME_LENGTH_LIMIT
              ? {
                  help: `Name is limited to ${NAME_LENGTH_LIMIT} characters, it currently has ${value.length}`,
                  error: true,
                }
              : { error: false },
          gridProps: FILL_GRID_ALL_SCREENS,
          onChange: (obj: string) => {
            handleNameChange(obj)
          },
          value: input.categoryName || '',
        },
      },
      radio: {},
      searchSelect: {
        CATEGORIES: {
          label: 'Parent Category',
          gridProps: { ...gridAllScreens(6), xs: 12 },
          variant: 'filled',
          value: input.parentName,
          options: input.searchResults,
          multiple: false,
          setSearchText: CategoriesLazyQuery.setItemQuery,
          loading: CategoriesLazyQuery.lazyQuery.meta.loading,
          onChange: (obj: any) => {
            handleParentChange(obj[0].id)
          },
        },
      },
      checkbox: {},
    } as DAIFormState)

  const [formState, setFormState] = useState<DAIFormState>(
    EDIT_CATEGORY_FORM({
      categoryName: category?.name,
      parentName: category?.parent?.name,
      searchResults: searchResults || [],
    }),
  )

  useEffect(() => {
    setFormState(
      EDIT_CATEGORY_FORM({
        categoryName: category?.name,
        parentName: category?.parent?.name,
        searchResults: searchResults || [],
      }),
    )
  }, [searchResults, category])

  return {
    formState,
    setFormState,
  }
}
