/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { Alert, Box, ButtonGroup, Divider, Grid, Snackbar } from '@mui/material'
import {
  FillContainerLoading,
  FlexBox,
  gridAllScreens,
  MuiFormController,
  NotificationAlert,
  TransferListContainer,
} from '@dai/web-components'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Add, Check, Close } from '@mui/icons-material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import * as React from 'react'
import { useContext } from 'react'
import PicOSContext from 'context/PicOSContext'
import { usePicosStoreGroupForm } from 'picos/hooks/form/use-picos-store-group.form'
import { StoreCSVUploadModal } from './modal/StoreCSVUpload.modal'

type StoreGroupFormProps = {
  show: boolean
  form: ReturnType<typeof usePicosStoreGroupForm>
}

const StoreGroupForm: React.FC<StoreGroupFormProps> = props => {
  const { show, form } = props
  const storeGroup = form.state
  const { selectedBanner } = useContext(PicOSContext)
  const [showUploadCSVModal, setShowUploadCSVModal] = React.useState<boolean>(
    false,
  )

  if (!show) {
    return null
  }

  return (
    <>
      <NotificationAlert />
      <FlexBox.Col width="100%">
        <Divider
          sx={theme => ({
            color: theme.palette.grey['50'],
            my: 2,
          })}
        />
        <FlexBox.Col>
          <FlexBox.RowBetween pb={2}>
            <Typography variant="h5">
              {storeGroup.showTransferList
                ? `New Store Group`
                : `Selected Stores for ${selectedBanner?.display}`}
            </Typography>
            {storeGroup.showTransferList ? (
              <ButtonGroup>
                <Button
                  color="error"
                  startIcon={<Close color="error" />}
                  onClick={() => storeGroup.setShowTransferList(false)}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  startIcon={<Check color="success" />}
                  onClick={form.handle.handleCreateStoreGroup}
                  disabled={
                    !storeGroup.chosenStores.length ||
                    !(storeGroup.formState?.text.NAME.value as string).length
                  }
                >
                  Confirm
                </Button>
              </ButtonGroup>
            ) : (
              <Button
                color="secondary"
                startIcon={<Add />}
                onClick={() => {
                  storeGroup.setShowTransferList(true)
                  form.handle.handleResetSelectedStoreGroup()
                }}
              >
                Create Store Group
              </Button>
            )}
          </FlexBox.RowBetween>
          <Grid container flex={1} spacing={2}>
            {form.data.createStoreGroupLoading ? (
              <Box p={4} flex={1}>
                <FillContainerLoading progressSize={50} />
              </Box>
            ) : !storeGroup.showTransferList &&
              storeGroup.formState?.searchSelect ? (
              <Grid item flex={1} {...gridAllScreens(6)} xs={12}>
                <MuiFormController.searchSelect
                  formState={storeGroup.formState.searchSelect}
                />
              </Grid>
            ) : (
              <>
                <Grid item flex={1} {...gridAllScreens(12)}>
                  <FlexBox.RowBetween pb={2}>
                    <MuiFormController.text
                      formState={storeGroup.formState.text}
                    />
                    <ButtonGroup
                      sx={{
                        margin: 2,
                      }}
                    >
                      <Button
                        color="secondary"
                        startIcon={<FileUploadIcon />}
                        onClick={() => setShowUploadCSVModal(true)}
                      >
                        Upload Group
                      </Button>
                    </ButtonGroup>
                  </FlexBox.RowBetween>
                </Grid>
                <Grid item flex={1} {...gridAllScreens(12)}>
                  <TransferListContainer
                    choices={storeGroup.choiceStores}
                    setChoices={storeGroup.setChoiceStores}
                    chosen={storeGroup.chosenStores}
                    setChosen={storeGroup.setChosenStores}
                    choicesLabel="Available Stores"
                    chosenLabel="Selected Stores"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FlexBox.Col>
      </FlexBox.Col>
      <StoreCSVUploadModal
        onSubmit={() => {
          form.handle.handleCreateStoreGroup()
          storeGroup.setShowTransferList(false)
          setShowUploadCSVModal(false)
        }}
        isOpen={showUploadCSVModal}
        handleClose={() => setShowUploadCSVModal(false)}
        handleFormChange={
          storeGroup.formState.text.NAME.onChange
            ? newStoreGroupName =>
                storeGroup.formState.text.NAME.onChange?.(newStoreGroupName)
            : newStoreGroupName => {
                throw new Error('No function to set group name implemented')
              }
        }
        setInternalIdList={storeGroup.setInternalIds}
      />
    </>
  )
}

export default StoreGroupForm
