/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import PicOSLayout from 'picos/components/PicOSLayout'
import { Filter, FlexBox, SearchBar } from '@dai/web-components'
import { IconButton, Tooltip } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useEditPicOSPrioritiesLogic } from 'picos/container/edit/EditPicOSPriorities.logic'
import PicOSPriorities from 'picos/components/PicOSPriorities'

const EditPicOSPrioritiesContainer: React.FC = () => {
  const {
    state: { FilterProps, bannerSelected },
    handle: { handleSelectCreatePriority },
    data: { priorities, prioritiesLoading },
    PriorityLazyQuery,
  } = useEditPicOSPrioritiesLogic()

  return (
    <PicOSLayout>
      <FlexBox.Col>
        <FlexBox.Row mb={bannerSelected ? 2 : 7}>
          {bannerSelected && (
            <>
              <SearchBar onChange={PriorityLazyQuery.setItemQuery} />
              <Filter filterTitle="Filter PicOS Priorities" {...FilterProps} />
              <Tooltip title="Add new PicOS Priority">
                <IconButton onClick={handleSelectCreatePriority}>
                  <Add />
                </IconButton>
              </Tooltip>
            </>
          )}
        </FlexBox.Row>
        <PicOSPriorities priorities={priorities} loading={prioritiesLoading} />
      </FlexBox.Col>
    </PicOSLayout>
  )
}

export default EditPicOSPrioritiesContainer
