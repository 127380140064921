/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useEffect, useMemo, useRef, useState } from 'react'
import {
  AdminCompanyStoresQuery,
  GET_ADMIN_COMPANY_STORES,
  GET_PLANOGRAM_FEEDBACK_IMAGES_ISSUES,
  GetPlanogramFeedbackImagesIssuesQuery,
  GetPlanogramFeedbackImagesIssuesQueryVariables,
  UPDATE_FLAGGED_REASON,
  AdminCompanyStoresQueryVariables,
} from '@dai/graphql'
import { useMutation } from '@apollo/client'
import { useDebounce } from 'use-debounce'
import {
  MuiTheme,
  useCommonFilter,
  BaseFilterAttributes,
  FilterHelpers,
  useDebouncedItemQuery,
  DAITable,
  DAITableColumns,
  StringHelpers,
} from '@dai/web-components'
import { PlanogramRow } from '../planogram.types'

const PAGE_SIZE = MuiTheme.sizes.table.page.md

export const usePlanogramFeedbackLogic = () => {
  const PlanogramLazyQuery = useDebouncedItemQuery<
    GetPlanogramFeedbackImagesIssuesQuery,
    GetPlanogramFeedbackImagesIssuesQueryVariables
  >({
    queryStr: GET_PLANOGRAM_FEEDBACK_IMAGES_ISSUES,
    options: { notifyOnNetworkStatusChange: true },
  })
  const StoresLazyQuery = useDebouncedItemQuery<
    AdminCompanyStoresQuery,
    AdminCompanyStoresQueryVariables
  >({
    queryStr: GET_ADMIN_COMPANY_STORES,
    options: { notifyOnNetworkStatusChange: true },
  })
  const [
    showViewPlanogramFeedbackImage,
    setShowViewPlanogramFeedbackImage,
  ] = useState<boolean>(false)
  const [planogramFeedbackSearch, setPlanogramFeedbackSearch] = useState('')
  const [debouncedPlanogramFeedbackSearchQuery] = useDebounce(
    planogramFeedbackSearch,
    500,
  )
  const defaultFilter: BaseFilterAttributes = {
    filterBy: {
      selected: [],
      options: [
        'Show Resolved Feedback',
        {
          label: 'Category',
          subOptions: [
            'Physical constraints',
            'Missing images',
            'Extra space',
          ].map(opt => ({
            value: opt,
            display: opt,
          })),
          selected: [],
        },
        {
          label: 'Store',
          subOptions: [],
          selected: [],
          showSearch: true,
        },
      ],
    },
  }
  const FilterProps = useCommonFilter(defaultFilter)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [updateFlaggedReason] = useMutation(UPDATE_FLAGGED_REASON)

  const planogramSegments =
    PlanogramLazyQuery.lazyQuery.meta.data?.planogramFeedbackImagesIssues
      .results || []
  const count = planogramSegments.length
  const tableRef = useRef<DAITable<PlanogramRow>>(null)
  const storeSearchTerm = FilterHelpers.getSubOptions(
    FilterProps.filter.filterBy!, // TODO: fix this type issue
    'Store',
  )?.searchTerm

  useEffect(() => {
    PlanogramLazyQuery.lazyQuery.query({
      variables: {
        input: {
          searchTerm: debouncedPlanogramFeedbackSearchQuery,
          limit: PAGE_SIZE,
          stores: FilterHelpers.getSubOptions(
            FilterProps.filter.filterBy!, // TODO: fix this type issue
            'Store',
          )?.selected,
          categories: FilterHelpers.getSubOptions(
            FilterProps.filter.filterBy!, // TODO: fix this type issue
            'Category',
          )?.selected,
        },
      },
    })
  }, [
    debouncedPlanogramFeedbackSearchQuery,
    FilterProps.numberOfItemsSelected,
    PAGE_SIZE,
  ])

  useEffect(() => {
    if (!StringHelpers.isNullOrEmpty(storeSearchTerm)) {
      StoresLazyQuery.lazyQuery.query({
        variables: {
          input: {
            searchTerm: storeSearchTerm,
          },
        },
      })
    }
  }, [storeSearchTerm])

  useEffect(() => {
    const res = StoresLazyQuery.lazyQuery.meta.data?.adminCompanyStores.results
    if (res) {
      const subOptions = res.map(st => ({
        display: st.name,
        value: st.id,
      }))
      FilterProps.handleSetSubOptions(subOptions, 'Store')
    }
  }, [StoresLazyQuery.lazyQuery.meta.data])

  const planogramRows: PlanogramRow[] = useMemo(
    () =>
      planogramSegments.map(segment => ({
        id: segment.id,
        storeName:
          segment?.displaySegment?.planogram?.display.store.store.name || '',
        displayDescription:
          segment?.displaySegment?.planogram?.display.displayType || '',
        created: segment.created,
        issueTypes: StringHelpers.truncateText(
          segment?.flaggedReason?.issueTypes?.join(', ') || '',
          40,
        ),
        flaggedReason: StringHelpers.truncateText(
          segment.flaggedReason.reason,
          60,
        ),
        isResolved: segment.flaggedReason.resolved,
      })),
    [planogramSegments],
  )

  const handleMarkResolved = () => {
    selectedIds.forEach(id => {
      const flaggedReasonId = planogramSegments.filter(
        (segment: any) => segment.id === id,
      )[0]?.flaggedReason?.id
      updateFlaggedReason({
        variables: { input: { id: flaggedReasonId, resolved: true } },
      })
    })
    tableRef.current?._setSelected([])
    PlanogramLazyQuery.lazyQuery.meta.refetch()
  }

  const handleMarkUnresolved = () => {
    selectedIds.forEach(id => {
      const flaggedReasonId = planogramSegments.filter(
        (segment: any) => segment.id === id,
      )[0]?.flaggedReason?.id
      updateFlaggedReason({
        variables: { input: { id: flaggedReasonId, resolved: false } },
      })
    })
    tableRef.current?._setSelected([])
    PlanogramLazyQuery.lazyQuery.meta.refetch()
  }

  const allSelectedProductsResolved = useMemo(() => {
    const selectedSegments = planogramSegments.filter((segment: any) =>
      selectedIds.includes(segment.id),
    )
    return (
      selectedSegments.filter((segment: any) => segment.flaggedReason.resolved)
        .length === selectedIds.length
    )
  }, [planogramSegments, selectedIds])

  const columns: DAITableColumns<PlanogramRow>[] = useMemo(
    () => [
      {
        field: 'storeName',
        headerName: 'Store',
        width: MuiTheme.sizes.table.column.width.xl,
      },
      {
        field: 'displayDescription',
        headerName: 'Display',
        width: MuiTheme.sizes.table.column.width.xl,
      },
      {
        field: 'created',
        headerName: 'Created',
        width: MuiTheme.sizes.table.column.width.md,
      },
      {
        field: 'issueTypes',
        headerName: 'Issue Type',
        width: MuiTheme.sizes.table.column.width.md,
      },
      {
        field: 'flaggedReason',
        headerName: 'Reason',
        width: MuiTheme.sizes.table.column.width.xl * 2,
      },
      {
        field: 'isResolved',
        headerName: 'Resolved',
        width: MuiTheme.sizes.table.column.width.sm,
      },
    ],
    [MuiTheme],
  )

  return {
    state: {
      showViewPlanogramFeedbackImage,
      setShowViewPlanogramFeedbackImage,
      planogramFeedbackSearch,
      setPlanogramFeedbackSearch,
      debouncedPlanogramFeedbackSearchQuery,
      selectedIds,
      setSelectedIds,
      FilterProps,
    },
    ref: {
      tableRef,
    },
    data: {
      planogramRows,
      allSelectedProductsResolved,
      columns,
      PAGE_SIZE,
      count,
      loading: PlanogramLazyQuery.lazyQuery.meta.loading,
    },
    handle: {
      handleMarkResolved,
      handleMarkUnresolved,
    },
  }
}
