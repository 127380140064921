/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import {
  GetPicOSStoreComplianceQuery,
  GetPicOSStoreComplianceStatsQuery,
  GetPicOSStoreComplianceStatsQueryVariables,
  GetPicOSStoreComplianceQueryVariables,
  GET_PICOS_STORE_COMPLIANCE,
  GET_PICOS_STORE_COMPLIANCE_STATS,
} from '@dai/graphql'
import {
  useDebouncedItemQuery,
  usePagination,
  PicOSReportContext,
} from '@dai/web-components'
import { PicOSReportHelpers } from 'picos/helpers/picos-reports.helpers'
import { useContext, useEffect } from 'react'

interface StoreReportProps {
  customerId: string
  divisionId: string
  salesCenterId: string
  storeId: string
}

const useStoreComplianceReport = (props: StoreReportProps) => {
  const { customerId, divisionId, salesCenterId, storeId } = props
  const { selectedDateRange, selectedStore } = useContext(PicOSReportContext)
  const {
    offset,
    limit,
    handleOffset: { goToPage },
  } = usePagination({ pageSize: 20 })

  const OverviewQuery = useDebouncedItemQuery<
    GetPicOSStoreComplianceQuery,
    GetPicOSStoreComplianceQueryVariables
  >({
    queryStr: GET_PICOS_STORE_COMPLIANCE,
    options: {
      variables: {
        input: {
          customerId,
          divisionId,
          salesCenterId,
          storeId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          offset,
          limit,
        },
      },
    },
    queryOnMount: true,
  })

  const StatsQuery = useDebouncedItemQuery<
    GetPicOSStoreComplianceStatsQuery,
    GetPicOSStoreComplianceStatsQueryVariables
  >({
    queryStr: GET_PICOS_STORE_COMPLIANCE_STATS,
    options: {
      variables: {
        input: {
          customerId,
          divisionId,
          salesCenterId,
          storeId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
        },
      },
    },
    queryOnMount: true,
  })

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    OverviewQuery.setItemQuery(searchTerm)
    StatsQuery.setItemQuery(searchTerm)
  }

  useEffect(() => {
    const variables = {
      variables: {
        input: {
          customerId,
          divisionId,
          salesCenterId,
          storeId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          storeName: OverviewQuery.debouncedQuery || undefined,
          offset,
        },
      },
    }
    OverviewQuery.lazyQuery.query(variables)
    StatsQuery.lazyQuery.query(variables)
  }, [OverviewQuery.debouncedQuery, selectedDateRange, offset])

  const overviewQuery = OverviewQuery.lazyQuery.meta
  const statsQuery = StatsQuery.lazyQuery.meta

  const statItems = PicOSReportHelpers.getStatItems(
    ['compliance', 'priorities', 'cost'],
    statsQuery.data?.picosStoreStats,
  )

  return {
    data: {
      title: selectedStore?.name || 'Store',
      complianceResults: overviewQuery.data?.picosStoreCompliance.results || [],
      statItems,
    },
    state: {
      limit,
      loadingStats: !!statsQuery.loading,
      loadingCompliance: !!overviewQuery.loading,
    },
    handlers: {
      handleSearch,
      goToPage,
    },
  }
}

export default useStoreComplianceReport
