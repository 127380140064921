/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import React, { useEffect, useState } from 'react'
import {
  AvatarWithText,
  FILL_GRID_ALL_SCREENS,
  FlexBox,
  GridSections,
  ImageCarousel,
  MUIDatePicker,
  PageTitle,
  StringHelpers,
  ProductTileOnlyUpc,
  FullScreenLayout,
} from '@dai/web-components'
import {
  Box,
  Card,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Pagination,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import {
  Storefront,
  LocationOn,
  CalendarMonth,
  EventBusy,
  Shuffle,
  Badge,
} from '@mui/icons-material'
import { ErrorIconAndDescription } from 'components/ErrorIconAndDescription'
import { ImageType } from '@dai/graphql'
import { useRestockTroubleshootingLogic } from './RestockTroubleshooting.logic'
import DisplayInfo from '../components/DisplayInfo'
import TrackInventoryEvent from '../components/TrackInventoryEvent'
import QuantityFacings from '../components/QuantityFacings'

const RestockTroubleshootingContainer: React.FC = () => {
  const { displayId } = useParams<{ displayId?: string }>()
  const navigate = useHistory()
  const [typedDisplayId, setTypedDisplayId] = useState(displayId)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [startDateCalendar, setStartDateCalendar] = useState(null)
  const [endDateCalendar, setEndDateCalendar] = useState(null)
  const [showRange, setShowRange] = useState(false)
  useEffect(() => {
    if (startDateCalendar && endDateCalendar) {
      setStartDate(startDateCalendar)
      setEndDate(endDateCalendar)
    }
  }, [startDateCalendar, endDateCalendar])

  const {
    display,
    history,
    loadingHistory,
    getRandomObservedDisplay,
    goToPage,
    numberPages,
    page,
    allDistributedProductUpcs,
  } = useRestockTroubleshootingLogic({ displayId, startDate, endDate })

  const handleNavigateToPathWithId = (id: string) => {
    navigate.push(`/display-restock-troubleshooting/${id}`)
  }
  const handleRandomPress = async () => {
    const displayId = await getRandomObservedDisplay()
    if (displayId) {
      setTypedDisplayId(displayId)
      handleNavigateToPathWithId(displayId)
    }
  }

  const handleToggleDateRange = () => {
    if (showRange) {
      setEndDate(undefined)
      setStartDate(undefined)
      setStartDateCalendar(null)
      setEndDateCalendar(null)
    }
    setShowRange(!showRange)
  }
  useEffect(() => {
    if (typedDisplayId && typedDisplayId.length === 36) {
      handleNavigateToPathWithId(typedDisplayId)
    }
  }, [typedDisplayId])

  return (
    <FullScreenLayout
      bodyStyle={{
        padding: 0,
        height: `calc(100vh - 64px)`,
        overflow: 'hidden',
      }}
      header={<PageTitle title="Restock Troubleshooting" />}
      body={
        <FlexBox.Row sx={{ height: '100%' }}>
          <FlexBox.Col
            sx={{
              padding: 4,
              flex: 1,
              overflow: 'auto',
              height: '100%',
              backgroundColor: theme => theme.palette.grey[100],
            }}
          >
            {loadingHistory ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : history ? (
              <Stack spacing={1}>
                {history.results.map(restockData => (
                  <Card
                    key={restockData.transactions[0].dateCompleted}
                    variant="outlined"
                  >
                    <TrackInventoryEvent
                      customerDistributedUpcs={allDistributedProductUpcs}
                      restockData={restockData}
                      maxSlots={
                        display?.idealInventory?.upcs.flatMap(segment =>
                          segment.flatMap(shelf => shelf),
                        ).length || 0
                      }
                    />
                  </Card>
                ))}
              </Stack>
            ) : (
              <ErrorIconAndDescription subtitle="No results found. Try a different date range or display." />
            )}
          </FlexBox.Col>
          <FlexBox.Col
            sx={{
              overflow: 'auto',
              height: '100%',
              padding: 2,
              width: 435,
              backgroundColor: theme => theme.palette.background.default,
            }}
          >
            <Box flexShrink={1}>
              <TextField
                id="standard-basic"
                label="Display Id"
                variant="standard"
                value={typedDisplayId}
                fullWidth
                focused={!StringHelpers.isNullOrEmpty(typedDisplayId)}
                onChange={e => setTypedDisplayId(e.target.value)}
                sx={{ flexGrow: 1, marginRight: 2 }}
                InputProps={{
                  endAdornment: (
                    <Stack direction="row">
                      <IconButton onClick={handleRandomPress}>
                        <Tooltip title="Get a random observed display">
                          <Shuffle />
                        </Tooltip>
                      </IconButton>
                      <IconButton onClick={handleToggleDateRange}>
                        <Tooltip
                          title={
                            showRange
                              ? 'Remove date range'
                              : 'Select a date range'
                          }
                        >
                          {showRange ? <EventBusy /> : <CalendarMonth />}
                        </Tooltip>
                      </IconButton>
                    </Stack>
                  ),
                }}
              />
              <Box>
                <ListSubheader disableGutters sx={{ pb: 1 }}>
                  <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Collapse in={showRange} sx={{ mt: 1 }}>
                      <Stack direction="row" spacing={2}>
                        <MUIDatePicker
                          variant="standard"
                          label="Start Date"
                          date={startDateCalendar}
                          onDateChange={setStartDateCalendar}
                        />
                        <MUIDatePicker
                          variant="standard"
                          label="End Date"
                          date={endDateCalendar}
                          onDateChange={setEndDateCalendar}
                        />
                      </Stack>
                    </Collapse>
                    {numberPages && (
                      <Pagination
                        count={numberPages}
                        page={page}
                        showFirstButton
                        showLastButton
                        onChange={(
                          event: React.ChangeEvent<unknown>,
                          value: number,
                        ) => {
                          goToPage(value)
                        }}
                        size="small"
                      />
                    )}
                  </Stack>
                </ListSubheader>
              </Box>
              <Box sx={{ display: 'flex', flex: 1 }}>
                {display && (
                  <GridSections
                    items={{
                      'Store Details': {
                        gridProps: FILL_GRID_ALL_SCREENS,
                        body: (
                          <Stack spacing={0.5}>
                            <AvatarWithText
                              primaryText={display.store.name}
                              secondaryText={`${display?.store?.address?.city}, ${display?.store?.address?.state}`}
                            >
                              <Storefront color="secondary" />
                            </AvatarWithText>
                            <AvatarWithText
                              primaryText={
                                display?.store?.address?.address1 || ''
                              }
                              secondaryText={
                                display?.store?.address?.postalCode || ''
                              }
                            >
                              <LocationOn color="secondary" />
                            </AvatarWithText>
                            <AvatarWithText
                              secondaryText={display.store.id}
                              primaryText="storeId"
                            >
                              <Badge color="secondary" />
                            </AvatarWithText>
                          </Stack>
                        ),
                      },
                      'Display Details': {
                        gridProps: FILL_GRID_ALL_SCREENS,
                        body: <DisplayInfo display={display} />,
                      },
                      'Display Image': {
                        gridProps: FILL_GRID_ALL_SCREENS,
                        body: (
                          <Box pt={1} flexGrow={1}>
                            <ImageCarousel
                              title=""
                              images={display.images.map(img => ({
                                uri: img.url,
                                id: img.id,
                                imageType: ImageType.DISPLAY,
                              }))}
                              buttons={[]}
                            />
                          </Box>
                        ),
                      },
                      'Latest Recommendation': {
                        gridProps: FILL_GRID_ALL_SCREENS,
                        body:
                          display.latestRecommendations &&
                          display.latestRecommendations[0] ? (
                            <List>
                              {display.latestRecommendations[0].productRecommendations.map(
                                p => (
                                  <ListItem disableGutters>
                                    <ProductTileOnlyUpc
                                      upc={p.upc}
                                      Extra={
                                        <QuantityFacings facings={p.count} />
                                      }
                                    />
                                  </ListItem>
                                ),
                              )}
                            </List>
                          ) : (
                            <Typography>
                              No recommendation at this time
                            </Typography>
                          ),
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
          </FlexBox.Col>
        </FlexBox.Row>
      }
    />
  )
}

export default RestockTroubleshootingContainer
