/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext } from 'react'
import {
  FullScreenLayout,
  NavigationTabs,
  PageTitle,
  NotificationAlert,
  SelectableOption,
  SearchableSelectInput,
  MuiTheme,
} from '@dai/web-components'
import ProtectedRoute from 'routes/ProtectedRoute'
import { Box } from '@mui/material'
import { Switch, useHistory, useLocation } from 'react-router-dom'
import { usePicOSLogic } from 'picos/container/PicOS.logic'
import PicOSContext from 'context/PicOSContext'
import CreatePicOSPriorityContainer from 'picos/container/create/CreatePicOSPriority.container'
import EditPicOSPrioritiesContainer from 'picos/container/edit/EditPicOSPriorities.container'
import RankPicOSPrioritiesContainer from 'picos/container/rank/RankPicOSPriorities.container'
import PicOSReportsContainer from 'picos/container/reports/PicOSReports.container'
import { useWindowDimensions } from '@dai/common'
import { PicosConstants } from 'picos/picos.constants'
import UserContext from 'context/UserContext'

const PicOSContainer: React.FC = () => {
  const {
    state: {
      CustomerBannerLazyQuery,
      selectedBanner,
      setAndSaveSelectedBanner,
      priorityToEdit,
      setPriorityToEdit,
      storeTypesInCustomer,
      setStoreTypesInCustomer,
      setIsNewPriority,
      isNewPriority,
    },
    data: { banners },
  } = usePicOSLogic()
  const { user } = useContext(UserContext)

  const { width } = useWindowDimensions()
  const isDesktop = width >= MuiTheme.breakpoints.values.lg
  const inputWidth = MuiTheme.sizes.forms[isDesktop ? 'lg' : 'md']
  const history = useHistory()
  const { pathname } = useLocation()

  return (
    <>
      <NotificationAlert />
      <FullScreenLayout
        header={
          <PageTitle
            disableTypography
            title={
              pathname.includes('picos/reports') ? null : (
                <Box sx={{ maxWidth: inputWidth, flex: 1 }}>
                  <SearchableSelectInput
                    variant={selectedBanner ? 'filled' : 'outlined'}
                    label="Customer Banner"
                    size="small"
                    minWidth={inputWidth}
                    options={banners}
                    loading={CustomerBannerLazyQuery.lazyQuery.meta.loading}
                    setSearchText={CustomerBannerLazyQuery.setItemQuery}
                    selectedOption={selectedBanner || []}
                    setSelectedOption={banner => {
                      history.push('/picos/edit')
                      setPriorityToEdit(PicosConstants.EMPTY_PICOS)
                      setAndSaveSelectedBanner(banner as SelectableOption)
                    }}
                  />
                </Box>
              )
            }
            RightComponent={
              <NavigationTabs<'edit' | 'create' | 'rank' | 'reports'>
                routeBase="picos"
                tabs={[
                  {
                    label: 'Edit Priorities',
                    ext: 'edit',
                  },
                  {
                    label: 'Rank Priorities',
                    ext: 'rank',
                  },
                  {
                    label: 'Create Priority',
                    ext: 'create',
                    onClick: () => {
                      setIsNewPriority(true)
                      setPriorityToEdit(PicosConstants.EMPTY_PICOS)
                    },
                  },
                  ...(user?.isSuperuser
                    ? [
                        {
                          label: 'Reports',
                          ext: 'reports' as 'reports',
                        },
                      ]
                    : []),
                ]}
              />
            }
          />
        }
        body={
          <PicOSContext.Provider
            value={{
              isNewPriority,
              setIsNewPriority,
              selectedBanner,
              setAndSaveSelectedBanner,
              priorityToEdit,
              setPriorityToEdit,
              storeTypesInCustomer: storeTypesInCustomer || [],
              setStoreTypesInCustomer,
            }}
          >
            <Switch>
              <ProtectedRoute path="/picos/edit/:id">
                <CreatePicOSPriorityContainer />
              </ProtectedRoute>
              <ProtectedRoute path="/picos/edit">
                <EditPicOSPrioritiesContainer />
              </ProtectedRoute>
              <ProtectedRoute path="/picos/rank">
                <RankPicOSPrioritiesContainer />
              </ProtectedRoute>
              <ProtectedRoute path="/picos/create/:id">
                <CreatePicOSPriorityContainer />
              </ProtectedRoute>
              <ProtectedRoute path="/picos/create">
                <CreatePicOSPriorityContainer />
              </ProtectedRoute>
              <ProtectedRoute path="/picos/reports">
                <PicOSReportsContainer />
              </ProtectedRoute>
            </Switch>
          </PicOSContext.Provider>
        }
      />
    </>
  )
}

export default PicOSContainer
