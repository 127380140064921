/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  DisplayElements,
  MandateTypes,
  PicOSPriorities_picosPriorities_results,
} from '@dai/graphql'
import { FormPicOSPriority, PicosForm, PicOSPriority } from 'picos/picos.types'
import { PicosPriorityForm } from 'picos/hooks/form/use-picos-priority.form'
import { PicosGroupForm } from 'picos/hooks/form/use-picos-store-group.form'
import { PicosDisplayForm } from 'picos/hooks/form/use-picos-display-info.form'
import { PicosProductsForm } from 'picos/hooks/form/use-picos-products.form'
import { SelectableOption } from '@dai/web-components'
import { uniqueId } from '@dai/common'

const transformPriority = (
  pr: PicOSPriorities_picosPriorities_results,
): PicOSPriority => ({
  id: pr.id,
  name: pr.name,
  startDate: pr.startDate,
  endDate: pr.endDate,
  priorityNum: pr.priority,
  mandateType: (pr.mandateType as unknown) as MandateTypes,
  isAllStores: !pr.storeGroup.length,
  imageUrl: pr.image?.url || '',
  description: pr.description || '',
  displayTypes: pr.displayTypes.map(pri => ({
    value: pri.id,
    display: pri.name,
  })),
  displayLocations: pr.displayLocation.map(pri => ({
    value: pri.id,
    display: pri.name,
  })),
  displayElements: (pr.displayElements as unknown) as DisplayElements,
  swirePosElementRequired: pr.swirePosElementRequired,
  otherDisplayTypesAllowed: pr.otherDisplayTypesAllowed,
  otherDisplayLocationsAllowed: pr.otherDisplayLocationsAllowed,
  otherCategoriesAllowed: pr.otherCategoriesAllowed,
  otherBrandsAllowed: pr.otherBrandsAllowed,
  otherPackagingAllowed: pr.otherPackagingAllowed,
  otherProductsAllowed: pr.otherProductsAllowed,
  categories: pr.categories.map(pri => ({
    value: pri.id,
    display: pri.category,
  })),
  brands: pr.brands.map(pri => ({
    value: pri.id,
    display: pri.name,
  })),
  packaging: pr.packaging.map(pri => ({
    value: pri.id,
    display: pri.packagingString,
  })),
  products:
    pr.products?.map(pri => ({
      value: pri.id,
      display: pri.name,
    })) || [],
  customerBanner: {
    value: pr.storeChain.id,
    display: pr.storeChain.name,
  },
  storeGroups: pr.storeGroup.map(pri => ({
    value: pri.id,
    display: pri.name,
  })),
  pricing: pr.pricingInfo || '',
  cost: pr.cost,
})

export type CachedPicosPriority = {
  id: string
  picosPriority: FormPicOSPriority
  lastModified: Date
}

// first key is bannerName, second is a unique id
type PicosPriorityDraftCache = {
  [key in string]: {
    [key in string]: CachedPicosPriority
  }
}
const PICOS_PRIORITY_DRAFT_CACHE_KEY = 'PICOS_PRIORITY_DRAFT_CACHE'
const savePicosLocalStorage = async (
  picosPriority: FormPicOSPriority,
  customerBanner: string,
  id?: string,
) => {
  const uuid = id || uniqueId()
  const cachedPicos: CachedPicosPriority = {
    id: uuid,
    picosPriority,
    lastModified: new Date(),
  }
  const currentCache = await localStorage.getItem(
    PICOS_PRIORITY_DRAFT_CACHE_KEY,
  )
  if (currentCache) {
    const cachedDraft: PicosPriorityDraftCache = JSON.parse(currentCache)
    const draftPicos: PicosPriorityDraftCache = {
      ...cachedDraft,
      [customerBanner]: {
        ...(cachedDraft[customerBanner] ? cachedDraft[customerBanner] : {}),
        [uuid]: cachedPicos,
      },
    }
    return localStorage.setItem(
      PICOS_PRIORITY_DRAFT_CACHE_KEY,
      JSON.stringify(draftPicos),
    )
  } else {
    const draftPicos: PicosPriorityDraftCache = {
      [customerBanner]: { [uuid]: cachedPicos },
    }
    return localStorage.setItem(
      PICOS_PRIORITY_DRAFT_CACHE_KEY,
      JSON.stringify(draftPicos),
    )
  }
}

const getPicosLocalStorage = async (
  id: string,
): Promise<FormPicOSPriority | undefined> => {
  const currentCache = await localStorage.getItem(
    PICOS_PRIORITY_DRAFT_CACHE_KEY,
  )
  if (currentCache) {
    const cachedDraft: PicosPriorityDraftCache = JSON.parse(currentCache)
    const picos = Object.values(cachedDraft).find(customer => {
      if (customer[id]) {
        return true
      }
    })
    return picos && picos[id] ? picos[id].picosPriority : undefined
  }
}

const getAllPicosPrioritiesFromLocalStorage = async (
  customerBanner: string,
): Promise<CachedPicosPriority[] | undefined> => {
  const currentCache = await localStorage.getItem(
    PICOS_PRIORITY_DRAFT_CACHE_KEY,
  )
  if (currentCache) {
    const cachedDraft: PicosPriorityDraftCache = JSON.parse(currentCache)
    if (cachedDraft[customerBanner]) {
      return Object.values(cachedDraft[customerBanner])
    }
  }
}

const removePicosPriorityFromLocalStorage = async (
  customerBanner: string,
  id: string,
): Promise<void> => {
  const currentCache = await localStorage.getItem(
    PICOS_PRIORITY_DRAFT_CACHE_KEY,
  )
  if (currentCache) {
    const cachedDraft: PicosPriorityDraftCache = JSON.parse(currentCache)
    delete cachedDraft[customerBanner][id]
    return localStorage.setItem(
      PICOS_PRIORITY_DRAFT_CACHE_KEY,
      JSON.stringify(cachedDraft),
    )
  }
}

const getPriorityFromFormStates = (
  priorityForm: PicosPriorityForm,
  storeGroupForm: PicosGroupForm,
  displayInfoForm: PicosForm<PicosDisplayForm>,
  productForm: PicosForm<PicosProductsForm>,
  id: string,
  customerBanner: SelectableOption,
): FormPicOSPriority => {
  return {
    id,
    name: priorityForm.text.NAME.value as string,
    priorityNum: priorityForm.text.PRIORITY.value as number,
    startDate: priorityForm.text.START.value as string,
    endDate: priorityForm.text.END.value as string,
    mandateType:
      priorityForm.radio.HQ.value === 'true'
        ? MandateTypes.HQ
        : MandateTypes.LOCAL,
    description: priorityForm.text.DESCRIPTION.value as string,
    cost: priorityForm.text.COST.value as number,
    storeGroups: storeGroupForm.searchSelect.GROUPS.options.filter(item =>
      (storeGroupForm.searchSelect.GROUPS.value as string[]).includes(
        item.value,
      ),
    ),
    displayElements:
      displayInfoForm.formState.radio.SUSTAIN.value === 'true'
        ? DisplayElements.SUSTAIN
        : DisplayElements.AD,
    displayTypes: displayInfoForm.formState.searchSelect.TYPES.options.filter(
      item =>
        (displayInfoForm.formState.searchSelect.TYPES
          .value as string[]).includes(item.value),
    ),
    displayLocations: displayInfoForm.formState.searchSelect.LOCATIONS.options.filter(
      item =>
        (displayInfoForm.formState.searchSelect.LOCATIONS
          .value as string[]).includes(item.value),
    ),
    otherDisplayTypesAllowed: displayInfoForm.othersAllowed.types,
    otherDisplayLocationsAllowed: displayInfoForm.othersAllowed.locations,
    otherCategoriesAllowed: productForm.othersAllowed.categories,
    otherBrandsAllowed: productForm.othersAllowed.brands,
    otherPackagingAllowed: productForm.othersAllowed.packaging,
    otherProductsAllowed: productForm.othersAllowed.products,
    categories: productForm.formState.searchSelect.CATEGORY.options.filter(
      item =>
        (productForm.formState.searchSelect.CATEGORY
          .value as string[]).includes(item.value),
    ),
    brands: productForm.formState.searchSelect.BRAND.options.filter(item =>
      (productForm.formState.searchSelect.BRAND.value as string[]).includes(
        item.value,
      ),
    ),
    packaging: productForm.formState.searchSelect.PACKAGING.options.filter(
      item =>
        (productForm.formState.searchSelect.PACKAGING
          .value as string[]).includes(item.value),
    ),
    products: productForm.formState.searchSelect.PRODUCT.options.filter(item =>
      (productForm.formState.searchSelect.PRODUCT.value as string[]).includes(
        item.value,
      ),
    ),
    pricing: productForm.formState.text.PRICE.value as string,
    swirePosElementRequired: (displayInfoForm.formState.checkbox.POS.value
      ? (displayInfoForm.formState.checkbox.POS.value as string[])
      : []
    ).includes('required'),
    customerBanner,
    isAllStores:
      priorityForm.radio.STORES.value?.includes('All Stores') || false,
    imageUrl: '',
  }
}

export const PicOSHelpers = {
  transformPriority,
  savePicosLocalStorage,
  getPicosLocalStorage,
  getAllPicosPrioritiesFromLocalStorage,
  removePicosPriorityFromLocalStorage,
  getPriorityFromFormStates,
}
