/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useEffect } from 'react'
import PicOSContext from 'context/PicOSContext'
import {
  StringHelpers,
  useCommonFilter,
  useDebouncedItemQuery,
} from '@dai/web-components'
import { DateTimeHelpers } from '@dai/common'
import {
  GET_PICOS_PRIORITY,
  PicOSPrioritiesQuery,
  PicOSPrioritiesQueryVariables,
} from '@dai/graphql'
import { BaseFilterAttributes } from '@dai/web-components'

export const useSearchPriorities = () => {
  const { selectedBanner } = useContext(PicOSContext)
  const PriorityLazyQuery = useDebouncedItemQuery<
    PicOSPrioritiesQuery,
    PicOSPrioritiesQueryVariables
  >({
    queryStr: GET_PICOS_PRIORITY,
    options: { fetchPolicy: 'network-only' },
  })
  const priorities =
    PriorityLazyQuery.lazyQuery.meta.data?.picosPriorities.results || []
  const currentWeek = DateTimeHelpers.getDateWeekDelta(0).toISOString()

  const defaultFilter: BaseFilterAttributes = {
    filterBy: {
      selected: [],
      options: [],
      dateOptions: {
        startDate: currentWeek,
        endDate: DateTimeHelpers.getDateWeekDelta(1).toISOString(),
      },
    },
  }
  const FilterProps = useCommonFilter(defaultFilter)

  useEffect(() => {
    if (
      FilterProps.filter.filterBy?.dateOptions?.startDate &&
      !StringHelpers.isNullOrEmpty(
        FilterProps.filter.filterBy?.dateOptions?.startDate,
      ) &&
      selectedBanner
    ) {
      PriorityLazyQuery.lazyQuery.query({
        variables: {
          input: {
            startDate: FilterProps.filter.filterBy.dateOptions.startDate,
            endDate: FilterProps.filter.filterBy.dateOptions.endDate,
            storeChainId: selectedBanner.value,
            name: PriorityLazyQuery.debouncedQuery,
          },
        },
      })
    }
  }, [
    FilterProps.filter,
    selectedBanner,
    PriorityLazyQuery.debouncedQuery,
    FilterProps.filter?.filterBy?.dateOptions?.startDate,
    FilterProps.filter?.filterBy?.dateOptions?.endDate,
  ])

  return {
    state: {
      FilterProps: {
        ...FilterProps,
        numberOfItemsSelected: 2,
      },
    },
    data: {
      priorities,
      prioritiesLoading: PriorityLazyQuery.lazyQuery.meta.loading,
      currentWeek,
    },
    PriorityLazyQuery,
  }
}
