/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext } from 'react'
import { Box, Grid } from '@mui/material'
import { gridAllScreens, MuiTheme } from '@dai/web-components'
import { ErrorIconAndDescription } from 'components/ErrorIconAndDescription'
import { PicOSPriorities_picosPriorities_results } from '@dai/graphql'
import { GridContextProvider, GridDropZone, GridItem } from 'react-grid-dnd'
import { useHistory } from 'react-router-dom'
import PicOSContext from 'context/PicOSContext'
import { PicOSHelpers } from 'picos/helpers/picos.helpers'
import PicOSCard from './PicOSCard'

type PicOSPrioritiesProps = {
  priorities: PicOSPriorities_picosPriorities_results[]
  loading: boolean
  skeletonListSize?: number
  gridDefault?: number
  dragProps?: {
    handleOnChangeRank: (
      sourceId: string,
      sourceIndex: number,
      targetIndex: number,
    ) => void
    disableDrag: boolean
  }
}

const PicOSPriorities: React.FC<PicOSPrioritiesProps> = props => {
  const {
    priorities,
    loading,
    gridDefault = 3,
    dragProps,
    skeletonListSize = 8,
  } = props
  const history = useHistory()
  const { setPriorityToEdit, setIsNewPriority, selectedBanner } = useContext(
    PicOSContext,
  )

  const handleEdit = (pr: PicOSPriorities_picosPriorities_results) => {
    setPriorityToEdit(PicOSHelpers.transformPriority(pr))
    setIsNewPriority(false)
    history.push(`/picos/edit/${pr.id}`)
  }

  const skeletonizedItems = (Array.from({
    length: skeletonListSize,
  }) as unknown) as PicOSPriorities_picosPriorities_results[]

  if (!loading && dragProps && priorities.length) {
    return (
      // Drag/Drop grid can't render inside an MUI grid
      <GridContextProvider onChange={dragProps.handleOnChangeRank}>
        <GridDropZone
          disableDrag={dragProps.disableDrag}
          id="drop-zone"
          // HEIGHT IS REQUIRED, OR THIS WILL BREAK!!!!
          style={{
            height: `calc(100vh - ${MuiTheme.mixins.toolbar.minHeight}px - 32px)`,
          }}
          boxesPerRow={gridDefault}
          // We need to better understand how to handle the PicOS card dimensions,
          // of both the container and contents, this shouldn't be hardcoded...
          // BUT, it works for now.
          rowHeight={356}
        >
          {priorities.map((pr, idx) => (
            <GridItem key={pr.name}>
              <Box p={1}>
                <PicOSCard priority={{ ...pr, priority: idx + 1 }} />
              </Box>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
    )
  }

  return (
    <Grid container spacing={2} pb={2}>
      {/* // TODO: Figure out how to use infinite scroll in a grid */}
      {/* <InfiniteScroll */}
      {/*  next={() => null} */}
      {/*  hasMore={false} */}
      {/*  loader={null} */}
      {/*  dataLength={priorities.length} */}
      {/* > */}
      {!priorities.length && !loading ? (
        <Grid item justifyContent="center" alignItems="center" flex={1}>
          {JSON.stringify(selectedBanner) !== '{}' ? (
            <ErrorIconAndDescription subtitle="No PicOS priorities were found matching those query parameters." />
          ) : (
            <ErrorIconAndDescription
              title="Ugh Oh!"
              subtitle="Select a Customer Banner to continue."
            />
          )}
        </Grid>
      ) : (
        <>
          {(loading && !priorities.length ? skeletonizedItems : priorities).map(
            (pr, idx) => (
              <Grid
                item
                {...gridAllScreens(gridDefault)}
                md={4}
                sm={6}
                xs={12}
                key={`priority-${idx}`}
              >
                <PicOSCard
                  priority={pr}
                  handleEdit={() => handleEdit(pr)}
                  loading={loading}
                />
              </Grid>
            ),
          )}
        </>
      )}
      {/* </InfiniteScroll> */}
    </Grid>
  )
}

export default PicOSPriorities
