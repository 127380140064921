/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext, useState, useEffect } from 'react'
import {
  DAIFormState,
  FILL_GRID_ALL_SCREENS,
  gridAllScreens,
  MuiFormTypes,
  StringHelpers,
  useMuiForm,
} from '@dai/web-components'
import dayjs from 'dayjs'
import { InputAdornment } from '@mui/material'
import PicOSContext from 'context/PicOSContext'
// import { PicosConstants } from 'picos/picos.constants'
import { MandateTypes } from '@dai/graphql'
import { round } from 'lodash'

export interface PicosPriorityForm extends DAIFormState<'text' | 'radio'> {
  text: {
    NAME: MuiFormTypes['text']
    DESCRIPTION: MuiFormTypes['text']
    START: MuiFormTypes['text']
    END: MuiFormTypes['text']
    PRIORITY: MuiFormTypes['text']
    COST: MuiFormTypes['text']
  }
  radio: {
    HQ: MuiFormTypes['radio']
    STORES: MuiFormTypes['radio']
  }
}

export const usePicosPriorityForm = () => {
  const { priorityToEdit, setPriorityToEdit } = useContext(PicOSContext)
  const [dateRangeError, setDateRangeError] = useState<string | undefined>()

  const NAME_LENGTH_LIMIT = 255
  const DESCRIPTION_LENGTH_LIMIT = 511

  const picosPriorityForm: PicosPriorityForm = {
    text: {
      NAME: {
        input: {
          placeholder: 'i.e Big Game Deli Offer',
          required: true,
          type: 'text',
          variant: 'standard',
          label: 'PicOS Priority Name',
        },
        validationFn: value =>
          value.length > NAME_LENGTH_LIMIT
            ? {
                help: `Name is limited to ${NAME_LENGTH_LIMIT} characters, it currently has ${value.length}`,
                error: true,
              }
            : { error: false },
        gridProps: FILL_GRID_ALL_SCREENS,
        onChange: value =>
          setPriorityToEdit({ ...priorityToEdit, name: value }),
        value: priorityToEdit.name,
      },
      DESCRIPTION: {
        input: {
          required: true,
          type: 'text',
          variant: 'filled',
          label: 'Description',
          multiline: true,
        },
        onChange: value =>
          setPriorityToEdit({ ...priorityToEdit, description: value }),
        validationFn: value =>
          value.length > DESCRIPTION_LENGTH_LIMIT
            ? {
                help: `Description is limited to ${DESCRIPTION_LENGTH_LIMIT} characters, it currently has ${value.length}`,
                error: true,
              }
            : { error: false },
        gridProps: FILL_GRID_ALL_SCREENS,
        value: priorityToEdit.description,
      },
      START: {
        input: {
          required: true,
          type: 'date',
          variant: 'filled',
          label: 'Start Date',
        },
        gridProps: {
          xl: 6,
          lg: 6,
          md: 6,
          sm: 12,
          xs: 12,
        },
        value: priorityToEdit.startDate,
        onChange: value =>
          setPriorityToEdit({ ...priorityToEdit, startDate: value }),
      },
      END: {
        input: {
          required: true,
          type: 'date',
          variant: 'filled',
          label: 'End Date',
        },
        gridProps: {
          xl: 6,
          lg: 6,
          md: 6,
          sm: 12,
          xs: 12,
        },
        value: priorityToEdit.endDate,
        error: !!dateRangeError,
        help: dateRangeError,
        onChange: value =>
          setPriorityToEdit({ ...priorityToEdit, endDate: value }),
      },
      PRIORITY: {
        validationFn: value => {
          if (!StringHelpers.isNullOrEmpty(value)) {
            const num = Number(value)
            return {
              error: num <= 0,
              help: num <= 0 ? 'A priority number must be >= 1' : undefined,
            }
          }
          return { error: false, help: undefined }
        },
        input: {
          required: true,
          type: 'number',
          variant: 'outlined',
          label: 'PicOS Priority Number',
        },
        gridProps: {
          xl: 6,
          lg: 6,
          md: 6,
          sm: 12,
          xs: 12,
        },
        value: priorityToEdit.priorityNum || '',
        onChange: value =>
          setPriorityToEdit({
            ...priorityToEdit,
            priorityNum: Number.parseInt(value, 10),
          }),
      },
      COST: {
        validationFn: value => {
          if (!StringHelpers.isNullOrEmpty(value)) {
            const num = Number(value)
            return {
              error: num <= 0,
              help: num <= 0 ? 'Cost must be > 0' : undefined,
            }
          }
          return { error: false, help: undefined }
        },
        input: {
          required: false,
          type: 'number',
          variant: 'outlined',
          label: 'Cost',
          placeholder: 'The expected cost of implementing this priority',
          InputProps: {
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          },
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value: priorityToEdit.cost || '',
        onChange: value =>
          setPriorityToEdit({
            ...priorityToEdit,
            cost: round(Number.parseFloat(value), 2),
          }),
      },
    },
    radio: {
      HQ: {
        value: priorityToEdit.mandateType
          ? priorityToEdit.mandateType === MandateTypes.HQ
            ? 'true'
            : 'false'
          : undefined,
        label: 'Priority',
        required: true,
        group: {},
        options: [
          {
            display: 'HQ Mandated',
            value: 'true',
          },
          {
            display: 'Local Sell-in',
            value: 'false',
          },
        ],
        gridProps: FILL_GRID_ALL_SCREENS,
        onChange: value => {
          const mandateType =
            value?.value === 'true'
              ? MandateTypes.HQ
              : value?.value === 'false'
              ? MandateTypes.LOCAL
              : undefined
          if (mandateType) {
            setPriorityToEdit({ ...priorityToEdit, mandateType })
          }
        },
      },
      STORES: {
        value:
          priorityToEdit.isAllStores === true
            ? 'true'
            : priorityToEdit.isAllStores === false
            ? 'false'
            : undefined,
        label: 'Customer Stores',
        required: true,
        group: {},
        options: [
          {
            display: 'All Stores',
            value: 'true',
          },
          {
            display: 'Selected Stores',
            value: 'false',
          },
        ],
        onChange: value => {
          const isAllStores = value?.value === 'true'
          if (isAllStores) {
            setPriorityToEdit({
              ...priorityToEdit,
              isAllStores,
              storeGroups: [],
            })
          } else {
            setPriorityToEdit({ ...priorityToEdit, isAllStores })
          }
        },
        gridProps: FILL_GRID_ALL_SCREENS,
      },
    },
  }

  const { data } = useMuiForm<PicosPriorityForm>({
    formState: picosPriorityForm,
  })

  // TODO: implement this
  useEffect(() => {
    if (priorityToEdit.startDate && priorityToEdit.endDate) {
      const endDate = dayjs(priorityToEdit.endDate)
      const startDate = dayjs(priorityToEdit.startDate)
      const error = endDate.isBefore(startDate) || endDate.isSame(startDate)
      const help = error ? 'End date must be after start date' : undefined
      setDateRangeError(help)
    }
  }, [priorityToEdit.startDate, priorityToEdit.endDate])

  return {
    state: {
      formState: picosPriorityForm,
    },
    data,
  }
}
