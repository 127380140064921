/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useEffect, useState } from 'react'
import { DAIFormState, MuiFormTypes } from '@dai/web-components'
import { useCompanyFormulaGroups } from '@dai/common'
import { useQuery } from '@apollo/client'
import {
  GET_ADMIN_COMPANY_STORE_CATEGORIES,
  GetAdminCompanyStoreCategoriesByIdQuery,
  GetAdminCompanyStoreCategoriesByIdQueryVariables,
} from '@dai/graphql'
import UserContext from 'context/UserContext'

export const useCreateEditStoreFormulasForm = ({
  storeIds,
}: {
  storeIds: string[]
}) => {
  const { user } = useContext(UserContext)

  const {
    coldAvailabilityFormulaGroups,
    categorySoviFormulaGroups,
  } = useCompanyFormulaGroups()

  const { data } = useQuery<
    GetAdminCompanyStoreCategoriesByIdQuery,
    GetAdminCompanyStoreCategoriesByIdQueryVariables
  >(GET_ADMIN_COMPANY_STORE_CATEGORIES, {
    variables: {
      input: { companyStoreIds: storeIds },
    },
    fetchPolicy: 'cache-first',
  })

  const numLG =
    data?.companyStoresByIds.filter(store => store?.category === 'LG').length ||
    0
  const numCR =
    data?.companyStoresByIds.filter(store => store?.category === 'CR').length ||
    0

  const CR: MuiFormTypes['select'] = {
    value: '',
    variant: 'filled',
    label: 'Cold Availability Formula Group',
    help: `(${numCR} Convenience Retail Stores)`,
    input: {
      required: true,
      placeholder: 'CR Formula Group',
    },
    options: coldAvailabilityFormulaGroups
      ? coldAvailabilityFormulaGroups?.map(c => ({
          value: c.id,
          display: `${c.name} - ${c.info || ''}` || '',
        }))
      : [],
    gridProps: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
  }

  const LG: MuiFormTypes['select'] = {
    value: '',
    variant: 'filled',
    label: 'Sovi Formula Group',
    help: `(${numLG} Large Stores)`,
    input: {
      required: true,
      placeholder: 'LG SOVI Formula Group',
    },
    options: categorySoviFormulaGroups
      ? categorySoviFormulaGroups?.map(c => ({
          value: c.id,
          display: `${c.name} - ${c.info || ''}` || '',
        }))
      : [],
    gridProps: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
  }

  const EDIT_STORE_FORMULAS_FORM: DAIFormState = {
    select: {},
    autocomplete: {},
    text: {},
    radio: {},
    searchSelect: {},
    checkbox: {},
  }
  const [formState, setFormState] = useState<DAIFormState>(
    EDIT_STORE_FORMULAS_FORM,
  )

  useEffect(() => {
    if (numCR > 0) {
      EDIT_STORE_FORMULAS_FORM.select.CR = CR
    }
    if (numLG > 0) {
      EDIT_STORE_FORMULAS_FORM.select.LG = LG
    }
    setFormState(EDIT_STORE_FORMULAS_FORM)
  }, [data, coldAvailabilityFormulaGroups, categorySoviFormulaGroups])

  return {
    formState,
    setFormState,
  }
}
