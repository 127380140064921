/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */

import {
  GetLoggedInUserQuery,
  GET_LOGGED_IN_USER,
  DAIGroupEnum,
} from '@dai/graphql'
import React, { useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import {
  Person,
  ShoppingCart,
  Storefront,
  Quiz,
  FactCheck,
  Summarize,
  ViewQuilt,
  Insights,
  BubbleChartOutlined,
  Edit,
  ZoomOutMap,
  HomeRepairService,
  Add,
  TableChart,
  GroupOutlined,
  BookOutlined,
  Category,
} from '@mui/icons-material'
import { UserProvider, UserState } from 'context/UserContext'
import {
  GROUPS_ALLOWED_TO_LOGIN_ADMIN,
  hasPermission,
  PICOS_USERS,
} from '@dai/common'
import {
  FillContainerLoading,
  FlexBox,
  NavigationDrawer,
  useDebouncedItemQuery,
} from '@dai/web-components'
import { DAIAdminRoute } from 'routes/routes.types'
import { Typography, Stack, Button } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { logout } from './api/auth'
import Routes from './routes/Routes'
import LoginContainer from './containers/LoginContainer'
import { auth } from './api/Firebase'

const App: React.FC = () => {
  const match = useRouteMatch({ path: '/', exact: true, strict: true })
  const onLogin = useRouteMatch({ path: '/login', exact: true, strict: true })
  const [userInfo, setUserInfo] = useState<UserState>({
    loading: true,
    user: null,
    error: null,
  })
  const UserLQ = useDebouncedItemQuery<GetLoggedInUserQuery, {}>({
    queryStr: GET_LOGGED_IN_USER,
    options: {
      context: {
        endPoint: 'gnarnia',
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  })
  const loggedInUser = UserLQ.lazyQuery.meta.data?.loggedInUser
  const loginError = UserLQ.lazyQuery.meta.error

  useEffect(() => {
    // Listen for changes to firebase auth user
    const unsubscribe = auth.onIdTokenChanged(async authUser => {
      if (authUser) {
        UserLQ.lazyQuery.query()
      } else {
        setUserInfo({
          user: null,
          loading: false,
          error: null,
        })
      }
    })
    // Callback to unsubscribe on unmount
    return unsubscribe
  }, [UserLQ.lazyQuery.query])

  useEffect(() => {
    const execute = async () => {
      if (loggedInUser) {
        if (hasPermission(loggedInUser, GROUPS_ALLOWED_TO_LOGIN_ADMIN)) {
          setUserInfo({ user: loggedInUser, loading: false, error: null })
        } else {
          setUserInfo({
            user: null,
            loading: false,
            error:
              'You do not have sufficient permission to access the Admin Portal. To resolve this issue, please contact your manager or send an email to support@deliciousai.com',
          })
        }
      } else if (loginError) {
        setUserInfo({
          user: null,
          loading: false,
          error: loginError.message || null,
        })
      }
    }
    execute()
  }, [loggedInUser, loginError])

  if (userInfo.loading) {
    return (
      <FlexBox.ColCenter sx={{ height: '100vh' }}>
        <FillContainerLoading
          Below={
            <Stack sx={theme => ({ marginTop: theme.spacing(2) })} spacing={4}>
              <Typography>Checking User...</Typography>
              <Button onClick={logout} variant="outlined" color="primary">
                Log out
              </Button>
            </Stack>
          }
        />
      </FlexBox.ColCenter>
    )
  }

  const location = useLocation<{ prev: string }>()

  if (!onLogin && !userInfo.user && !userInfo.loading) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { prev: location.pathname },
        }}
      />
    )
  }
  if (match && userInfo.user && !userInfo.loading) {
    if (location.state?.prev) {
      return <Redirect to={location.state?.prev} />
    }
    return <Redirect to="/users" />
  }

  return (
    <UserProvider value={userInfo}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Switch>
          <Route
            path="/login"
            component={LoginContainer}
            state={{ prev: location.pathname }}
          />
          <Route path="/">
            <NavigationDrawer<DAIAdminRoute>
              logout={logout}
              user={userInfo.user}
              navItems={[
                {
                  label: 'Users',
                  route: 'Users',
                  icon: <Person color="secondary" />,
                },
                {
                  label: 'Reporting',
                  route: 'Reporting/Brands',
                  hide:
                    !userInfo.user?.isSuperuser &&
                    !userInfo.user?.roles.includes(DAIGroupEnum.REPORT_VIEWERS),
                  icon: <Insights color="secondary" />,
                  more: [
                    {
                      label: 'Brands',
                      hide: !userInfo.user?.roles.includes(
                        DAIGroupEnum.REPORT_VIEWERS,
                      ),
                      icon: <BubbleChartOutlined color="secondary" />,
                      route: 'Reporting/Brands',
                    },
                    {
                      label: 'Users',
                      hide: !userInfo.user?.isSuperuser,
                      icon: <GroupOutlined color="secondary" />,
                      route: 'Reporting/Users',
                    },
                  ],
                },
                {
                  label: 'Products',
                  route: 'Products',
                  icon: <ShoppingCart color="secondary" />,
                },
                {
                  label: 'Categories',
                  route: 'Categories',
                  icon: <Category color="secondary" />,
                  hide: !userInfo.user?.isSuperuser,
                },
                {
                  label: 'Stores',
                  route: 'Stores/Management',
                  more: [
                    {
                      label: 'Hierarchy',
                      icon: <Storefront color="secondary" />,
                      route: 'Stores/Hierarchy',
                      hide: !userInfo.user?.isSuperuser,
                    },
                  ],
                  icon: <Storefront color="secondary" />,
                },
                {
                  label: 'Trainings',
                  route: 'Trainings',
                  hide: !userInfo.user?.isSuperuser,
                  icon: <BookOutlined color="secondary" />,
                },
                {
                  label: 'Planograms',
                  route: 'Planograms',
                  icon: <ViewQuilt color="secondary" />,
                },
                {
                  label: 'Questions',
                  route: 'Questions',
                  icon: <Quiz color="secondary" />,
                },
                {
                  label: 'Audits',
                  route: 'Audits',
                  icon: <FactCheck color="secondary" />,
                },
                {
                  label: 'PicOS',
                  route: 'PicOS/Edit',
                  icon: <Summarize color="secondary" />,
                  hide: !(
                    userInfo.user?.isSuperuser ||
                    hasPermission(userInfo.user, PICOS_USERS)
                  ),
                  more: [
                    {
                      label: 'Edit Priorities',
                      icon: <Edit color="secondary" />,
                      route: 'PicOS/Edit',
                    },
                    {
                      label: 'Rank Priorities',
                      icon: <ZoomOutMap color="secondary" />,
                      route: 'PicOS/Rank',
                    },
                    {
                      label: 'Create Priority',
                      icon: <Add color="secondary" />,
                      route: 'PicOS/Create',
                    },
                    {
                      label: 'Reports',
                      icon: <TableChart color="secondary" />,
                      route: 'PicOS/Reports',
                    },
                  ],
                },
                {
                  label: 'Restock Trouble',
                  route: 'display-restock-troubleshooting',
                  icon: <HomeRepairService color="secondary" />,
                  hide: !loggedInUser?.isSuperuser,
                },
              ]}
            >
              <Routes />
            </NavigationDrawer>
          </Route>
        </Switch>
      </LocalizationProvider>
    </UserProvider>
  )
}

export default App
