/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  CREATE_OR_UPDATE_ZONE_TERRITORY,
  CreateOrUpdateZoneTerritoryMutation,
  CreateOrUpdateZoneTerritoryMutationVariables,
  GET_COMPANY_USERS,
  GET_GEOGRAPHIC_HIERARCHY_DRAFTS,
  GetCompanyUsersQuery,
  GetCompanyUsersQueryVariables,
  GetGeographicHierarchyDraftsQuery,
  GetGeographicHierarchyDraftsQueryVariables,
  UserFragment,
} from '@dai/graphql'
import { useContext, useEffect, useState } from 'react'
import { useDebouncedItemQuery, NotificationContext } from '@dai/web-components'
import { cloneDeep } from 'lodash'
import { useMutation } from '@apollo/client'
import { useGetGroups } from '@dai/common'
import UserContext from 'context/UserContext'
import { FSM, RARRoute } from './types'

export type TerritoryHierarchyProps = {
  selectedUser: FSM | undefined
  onRefetch: () => void
}

export const useTerritoryHierarchyLogic = (props: TerritoryHierarchyProps) => {
  const { selectedUser, onRefetch } = props
  const [routes, setRoutes] = useState<RARRoute[]>([])
  const [selectedRoute, setSelectedRoute] = useState<RARRoute>()
  const [territoryName, setTerritoryName] = useState<string>()
  const [districtManager, setDistrictManager] = useState<
    Pick<UserFragment, 'firstName' | 'lastName' | 'uuid'>
  >()
  const [territoryManager, setTerritoryManager] = useState<
    Pick<UserFragment, 'firstName' | 'lastName' | 'uuid' | 'id'> | undefined
  >(selectedUser)

  useEffect(() => {
    setTerritoryManager(selectedUser)
  }, [selectedUser])
  const [savingMutation, setSavingMutation] = useState(false)
  const [usersToAssignToTerritory, setUsersToAssignToTerritory] = useState<
    Pick<UserFragment, 'id' | 'uuid'>[]
  >([])
  const [createOrUpdateGeographicTerritory] = useMutation<
    CreateOrUpdateZoneTerritoryMutation,
    CreateOrUpdateZoneTerritoryMutationVariables
  >(CREATE_OR_UPDATE_ZONE_TERRITORY)
  const { handleGetGroupByName } = useGetGroups()
  const { setSuccess, setError } = useContext(NotificationContext)
  const [modalContext, setModalContext] = useState<
    | 'add-store'
    | 'add-route'
    | 'edit-route-manager'
    | 'edit-territory-manager'
    | 'district-manager'
    | 'assign-users-to-territory'
  >()
  const { user } = useContext(UserContext)
  const FSMLazyQuery = useDebouncedItemQuery<
    GetGeographicHierarchyDraftsQuery,
    GetGeographicHierarchyDraftsQueryVariables
  >({
    queryStr: GET_GEOGRAPHIC_HIERARCHY_DRAFTS,
    options: { fetchPolicy: 'network-only' },
  })
  const DistrictManagersLQ = useDebouncedItemQuery<
    GetCompanyUsersQuery,
    GetCompanyUsersQueryVariables
  >({
    queryStr: GET_COMPANY_USERS,
    options: { fetchPolicy: 'cache-first' },
  })
  const draftsRes = FSMLazyQuery.lazyQuery.meta.data?.geographicHierarchyDrafts
  const districtManagerOptions =
    DistrictManagersLQ.lazyQuery.meta.data?.companyUsers.results || []

  const handleRemoveStoreFromRoute = (routeIndex: number, storeId: string) => {
    const routeToEdit = routes[routeIndex]
    if (routeToEdit) {
      const newRoute: RARRoute = {
        ...routeToEdit,
        stores: routeToEdit.stores.filter(s => s.id !== storeId),
      }
      const newRoutes = cloneDeep(routes)
      newRoutes[routeIndex] = newRoute
      setRoutes(newRoutes)
    }
  }

  const handleEditRouteManager = (
    selectedRoute: RARRoute,
    routeIndex: number,
    manager: UserFragment,
  ) => {
    const newRoute: RARRoute = {
      ...selectedRoute,
      rar: manager,
    }
    const newRoutes = cloneDeep(routes)
    newRoutes[routeIndex] = newRoute
    setRoutes(newRoutes)
  }

  const handleAddStoresToRoute = (
    selectedRoute: RARRoute,
    routeIndex: number,
    stores: RARRoute['stores'],
  ) => {
    const newRoute: RARRoute = {
      ...selectedRoute,
      stores: [...selectedRoute.stores, ...stores],
    }
    const newRoutes = cloneDeep(routes)
    newRoutes[routeIndex] = newRoute
    setRoutes(newRoutes)
  }

  const handleAddRoute = (user: UserFragment) => {
    setRoutes([
      {
        rar: user,
        routeId: null,
        stores: [],
        approvedBy: null,
        routeName: '',
      },
      ...routes,
    ])
  }

  const handleDeleteRoute = (routeIndex: number) => {
    setRoutes(routes.filter((_, index) => index !== routeIndex))
  }

  const handleSaveTerritory = () => {
    if (territoryManager && districtManager) {
      setSavingMutation(true)
      createOrUpdateGeographicTerritory({
        variables: {
          input: {
            territoryManagerUuid: territoryManager.uuid,
            territoryName,
            districtManagerUuid: districtManager.uuid,
            routes: routes.map(route => ({
              routeName: route.routeName,
              routeId: route.routeId,
              routeManagerUuid: route.rar.uuid,
              storeIds: route.stores.map(store => store.id),
            })),
            territoryUserUuids: usersToAssignToTerritory.map(u => u.uuid),
          },
        },
      })
        .then(() => {
          setSuccess('Successfully updated territory')
          onRefetch()
          FSMLazyQuery.lazyQuery.query({
            variables: {
              userUuid: territoryManager.uuid,
            },
          })
        })
        .catch(e => {
          setError(`An error occurred:\n${e}`)
        })
        .finally(() => setSavingMutation(false))
    }
  }

  const handleFetchDistrictManagers = (searchTerm: string) => {
    const salesManager = handleGetGroupByName('Sales Managers')
    if (salesManager) {
      DistrictManagersLQ.lazyQuery.query({
        variables: {
          input: {
            userRoles: [salesManager.id],
            searchTerm,
          },
        },
      })
    }
  }

  const handleUpdateRouteName = (routeName: string, routeIndex: number) => {
    const routeToEdit = routes[routeIndex]
    if (routeToEdit) {
      const newRoute: RARRoute = {
        ...routeToEdit,
        routeName,
      }
      const newRoutes = cloneDeep(routes)
      newRoutes[routeIndex] = newRoute
      setRoutes(newRoutes)
    }
  }

  const handleAssignUsersToTerritory = (users: UserFragment[]) =>
    setUsersToAssignToTerritory(users)

  useEffect(() => {
    if (selectedUser) {
      FSMLazyQuery.lazyQuery.query({
        variables: {
          userUuid: selectedUser.uuid,
        },
      })
    }
  }, [selectedUser])

  useEffect(() => {
    if (draftsRes?.drafts) {
      setRoutes(draftsRes.drafts)
    }
    setTerritoryName(draftsRes?.territoryName || undefined)
    setDistrictManager(draftsRes?.districtManager || undefined)
    setUsersToAssignToTerritory(draftsRes?.assignedTerritoryUsers || [])
  }, [draftsRes?.drafts.length])

  useEffect(() => {
    if (DistrictManagersLQ.debouncedQuery?.length) {
      handleFetchDistrictManagers(DistrictManagersLQ.debouncedQuery)
    }
  }, [DistrictManagersLQ.debouncedQuery])

  const loading = FSMLazyQuery.lazyQuery.meta.loading
  const loadingManagerOptions = DistrictManagersLQ.lazyQuery.meta.loading

  return {
    state: {
      routes,
      setRoutes,
      selectedRoute,
      setSelectedRoute,
      savingMutation,
      setSavingMutation,
      modalContext,
      setModalContext,
      districtManager,
      setDistrictManager,
      territoryName,
      setTerritoryName,
      usersToAssignToTerritory,
      setUsersToAssignToTerritory,
      territoryManager,
      setTerritoryManager,
    },
    handle: {
      handleEditRouteManager,
      handleAddRoute,
      handleAddStoresToRoute,
      handleDeleteRoute,
      handleRemoveStoreFromRoute,
      handleSaveTerritory,
      handleFetchDistrictManagers,
      handleUpdateRouteName,
      handleAssignUsersToTerritory,
    },
    data: {
      loading,
      districtManagerOptions,
      loadingManagerOptions,
    },
    DistrictManagersLQ,
  }
}
