/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  Breakpoint,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Skeleton,
  Typography,
} from '@mui/material'
import { PushPin } from '@mui/icons-material'
import React from 'react'
import Box from '@mui/material/Box'
import { CSSObject, Theme } from '@mui/material/styles'
import { FlexBox, StringHelpers } from '@dai/web-components'
import { Training_training } from '@dai/graphql'

export type TrainingTileProps = {
  loading: boolean
  training?: Training_training
  onSelect?: (upc: string) => void
  selected?: boolean
  imageUrl?: string
  Extra?: React.ReactNode
  hideVerified?: boolean
  enableShowLargeImage?: boolean
}

export const TrainingTile: React.FC<TrainingTileProps> = props => {
  const {
    loading: isLoading,
    training,
    onSelect = () => null,
    selected = false,
    imageUrl = '',
  } = props
  const loading = false

  const image = loading ? undefined : imageUrl

  const sidebarWidthMixin = (theme: Theme): CSSObject =>
    Object.keys(theme.sizes.sidebar.width.variant.lg).reduce<CSSObject>(
      (css, bp) => ({
        ...css,
        [theme.breakpoints.only(bp as Breakpoint)]: {
          // @ts-ignore
          maxWidth: theme.sizes.sidebar.width.variant.lg[bp] * 0.5,
        },
      }),
      {},
    )

  return (
    <>
      <Card
        elevation={selected ? 10 : 1}
        sx={theme => ({ width: '100%' })}
        onClick={() => {
          if (!isLoading && training?.id) {
            onSelect(training?.id)
          }
        }}
      >
        {selected && (
          <CardHeader sx={{ p: 1 }} avatar={<PushPin color="primary" />} />
        )}
        <FlexBox.RowCenter>
          <CardMedia
            component="img"
            sx={theme => ({
              padding: 1,
              width: theme.sizes.img.sm - 8,
              height: theme.sizes.img.sm - 8,
            })}
            image={image}
          />
          <CardContent sx={{ flexGrow: 1 }}>
            <FlexBox.Col>
              {loading ? (
                <>
                  <Skeleton
                    sx={theme => ({
                      height: theme.sizes.skeleton.sm,
                      mb: `${theme.sizes.skeleton.xs}px`,
                    })}
                  />
                  <Skeleton
                    sx={theme => ({
                      height: theme.sizes.skeleton.xs,
                      mb: `${theme.sizes.skeleton.xs}px`,
                    })}
                  />
                  <Skeleton
                    sx={theme => ({
                      height: theme.sizes.skeleton.xs,
                      mb: `${theme.sizes.skeleton.xs}px`,
                    })}
                  />
                  <Skeleton
                    sx={theme => ({
                      height: theme.sizes.skeleton.xs,
                    })}
                  />
                </>
              ) : (
                <Box
                  sx={theme => ({
                    ...sidebarWidthMixin(theme),
                    [theme.breakpoints.only('xs')]: {
                      maxWidth: 200,
                    },
                  })}
                >
                  <Typography
                    variant="body2"
                    component="p"
                    fontWeight={'bold'}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                  >
                    {training?.title}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {training?.description}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {training?.timeRequired}{' '}
                    {StringHelpers.plurality(
                      'minute',
                      training?.timeRequired || 0,
                    )}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {training?.deviceType}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {training?.groups?.map(g => g.name).join(', ')}
                  </Typography>
                </Box>
              )}
            </FlexBox.Col>
          </CardContent>
        </FlexBox.RowCenter>
      </Card>
    </>
  )
}
