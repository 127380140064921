/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useEffect, useState } from 'react'
import {
  StringHelpers,
  useCommonFilter,
  FilterHelpers,
  useDebouncedItemQuery,
  useProductSearch,
  useMuiForm,
  NotificationContext,
  useInfiniteScrollAndSearch,
  SimpleOptionSelect,
  SelectableOption,
} from '@dai/web-components'
import { useMutation } from '@apollo/client'
import {
  ProductSortBy,
  SabreGetProductsQuery,
  SabreGetProductsQueryVariables,
  SabreProductFragment,
  SabreUpdateProductMutation,
  SabreUpdateProductMutationVariables,
  SABRE_RESOLVE_DRAFT_PRODUCT,
  SabreResolveDraftProductMutationVariables,
  SabreResolveDraftProductMutation,
  SABRE_UPDATE_PRODUCT,
  SabrePackagingType,
  SABRE_CREATE_IMAGE,
  SabreCreateImageMutation,
  SabreCreateImageMutationVariables,
  SabreProductImageType,
  SabreDeleteImageMutation,
  SabreDeleteImageMutationVariables,
  SABRE_DELETE_IMAGE,
  SABRE_GET_PRODUCT,
  SabreGetProductQuery,
  SabreGetProductQueryVariables,
  ImageType,
} from '@dai/graphql'
import { useEditProductForm } from 'products/hooks/use-edit-product.form'
import { DZFile, uniqueId } from '@dai/common'
import { useNotificationState } from '@dai/web-components'
import { uploadImageOrVideoToFirebaseBucket } from '../../../helpers/firebase.helpers'

type ProductFilterType = {
  filterBy: SimpleOptionSelect<string[]>
  sortBy: SimpleOptionSelect<string>
}

export const useProductsLogic = () => {
  const [selectedProductUpc, setSelectedProductUpc] = useState<string | null>(
    null,
  )
  const [showConfirmSaveProduct, setShowConfirmSetProduct] = useState<boolean>(
    false,
  )
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [productEditing, setProductEditing] = useState<
    | (SabreProductFragment & {
        defaultImage?: { uri: string; id?: string }
        productFlagResolved?: boolean
      })
    | undefined
  >()
  const [originalProduct, setOriginalProduct] = useState<
    | (SabreProductFragment & {
        defaultImage?: { uri: string }
        productFlagResolved?: boolean
      })
    | undefined
  >()

  const refresh = () => {
    window.location.reload()
  }

  const notificationState = useNotificationState()
  const [addingImage, setAddingImage] = useState(false)
  const [deletingImage, setDeletingImage] = useState(false)
  const [addImage] = useMutation<
    SabreCreateImageMutation,
    SabreCreateImageMutationVariables
  >(SABRE_CREATE_IMAGE, { context: { endPoint: 'sabre' } })
  const [deleteImage] = useMutation<
    SabreDeleteImageMutation,
    SabreDeleteImageMutationVariables
  >(SABRE_DELETE_IMAGE, { context: { endPoint: 'sabre' } })
  const {
    ProductLazyQuery,
    ProductCategoriesLazyQuery,
    ProductMarketingImageLazyQuery,
    BrandLazyQuery,
  } = useProductSearch()
  const ProductDataLazyQuery = useDebouncedItemQuery<
    SabreGetProductQuery,
    SabreGetProductQueryVariables
  >({
    queryStr: SABRE_GET_PRODUCT,
    options: { context: { endPoint: 'sabre' }, fetchPolicy: 'network-only' },
  })
  const productsResponse =
    ProductLazyQuery.lazyQuery.meta.data?.products?.results || []
  const handleBrandChange = (brand: SelectableOption[]) => {
    const newBrand =
      brand.length === 1 ? { name: brand[0].display, id: brand[0].value } : null
    setProductEditing(
      p =>
        p &&
        ({
          ...p,
          brand: newBrand,
        } as SabreProductFragment),
    )
  }
  const handleNameChange = (name: string) => {
    setProductEditing(
      p =>
        p && {
          ...p,
          name,
        },
    )
  }
  const handleSizeChange = (size: string) => {
    setProductEditing(
      p =>
        p &&
        ({
          ...p,
          packaging: { ...p.packaging, size: parseFloat(size) },
        } as SabreProductFragment),
    )
  }
  const handleCountChange = (quantity: string) => {
    setProductEditing(
      p =>
        p &&
        ({
          ...p,
          packaging: { ...p.packaging, quantity: parseInt(quantity, 10) },
        } as SabreProductFragment),
    )
  }
  const handleUnitsChange = (units: SelectableOption[]) => {
    const newUnits = units.length === 1 ? units[0].value : undefined
    setProductEditing(
      p =>
        p &&
        ({
          ...p,
          packaging: {
            ...p.packaging,
            unit: newUnits,
          },
        } as SabreProductFragment),
    )
  }
  const handleContainerChange = (container: SelectableOption[]) => {
    const newContainer = container.length === 1 ? container[0].value : undefined
    setProductEditing(
      p =>
        p &&
        ({
          ...p,
          packaging: {
            ...p.packaging,
            container: newContainer,
          },
        } as SabreProductFragment),
    )
  }
  // const handleCategoryChange = (category: SelectableOption[]) => {
  //   const newCategory =
  //     category.length === 1
  //       ? {
  //           id: category[0].value,
  //           category: category[0].display,
  //         }
  //       : null
  // setProductEditing(
  //   p =>
  //     p && {
  //       ...p,
  //       category: newCategory,
  //     },
  // )
  // }
  const handleManufacturerChange = (manufacturer: SelectableOption[]) => {
    const newManufacturer =
      manufacturer.length === 1
        ? { name: manufacturer[0].display, id: manufacturer[0].value }
        : undefined
    setProductEditing(p =>
      newManufacturer
        ? p &&
          ({
            ...p,
            manufacturer: newManufacturer,
          } as SabreProductFragment)
        : p,
    )
  }
  const handleFlavorsChange = (flavors: SelectableOption[]) => {
    const flavorsToUpdate = flavors.map(fl => ({
      name: fl.value,
      displayFlavor: true,
    }))
    setProductEditing(
      p =>
        p &&
        ({
          ...p,
          flavors: flavorsToUpdate,
        } as SabreProductFragment),
    )
  }

  const handleSetDefaultImage = (image?: { id: string; uri: string }) => {
    if (productEditing) {
      setProductEditing({
        ...productEditing,
        defaultImage: image,
      })
    }
  }

  const handleDeleteImage = async (imageId: string) => {
    setDeletingImage(true)
    deleteImage({
      variables: { input: { id: imageId } },
    })
      .then(res => {
        if (res?.data?.deleteImage?.deleted && productEditing) {
          ProductMarketingImageLazyQuery.lazyQuery
            .query({
              variables: { input: { upc: productEditing.upcA } },
            })
            .then(() => {
              setProductEditing({ ...productEditing })
            })
          setSuccess('Successfully deleted image.')
        }
        setDeletingImage(false)
        refresh()
      })
      .catch(err => {
        notificationState.setError(err)
        setDeletingImage(false)
      })
  }

  const handleUploadImage = async (imageFile: DZFile) => {
    setAddingImage(true)
    const imageId = uniqueId()
    const { bucketPath } = await uploadImageOrVideoToFirebaseBucket(
      imageFile,
      'images',
      imageId,
      'productMarketing',
    )
    addImage({
      variables: {
        input: {
          bucketFileName: bucketPath,
          upc: selectedProductUpc || '',
          imageType: SabreProductImageType.MARKETING,
        },
      },
    })
      .then(res => {
        if (res?.data?.createImage?.image && productEditing) {
          const image = res.data.createImage.image
          handleSetDefaultImage({
            uri: image.url || '',
            id: image.id,
          })
          notificationState.setSuccess('Successfully uploaded 1 image.')
          setAddingImage(false)
          refresh()
        }
      })
      .catch(err => {
        notificationState.setError(err)
        setAddingImage(false)
      })
  }

  const { formState } = useEditProductForm({
    product: productEditing,
    handleBrandChange,
    handleSizeChange,
    // handleCategoryChange,
    handleCountChange,
    handleContainerChange,
    handleFlavorsChange,
    handleManufacturerChange,
    handleNameChange,
    handleUnitsChange,
  })
  const {
    data: { canSubmit },
  } = useMuiForm({
    formState,
  })
  const { setSuccess, setError } = useContext(NotificationContext)

  const defaultFilter: ProductFilterType = {
    filterBy: {
      selected: ['Feedback'],
      options: [
        // {
        //   label: 'Category',
        //   selected: [],
        //   subOptions:
        //     ProductCategoriesLazyQuery.lazyQuery.meta.data?.companyProductCategories.map(
        //       (ct: { category: any; id: any }) => ({
        //         display: ct.category,
        //         value: ct.id,
        //       }),
        //     ) || [],
        // },
        {
          label: 'Brand',
          selected: [],
          subOptions: [],
          showSearch: true,
        },
        'Feedback',
        // 'Needs Image',
      ],
    },
    sortBy: {
      selected: StringHelpers.stringValue(ProductSortBy.AUDIT_APP_LOW_TO_HIGH),
      options: Object.keys(ProductSortBy).map(opt =>
        StringHelpers.stringValue(opt),
      ),
    },
  }

  const FilterProps = useCommonFilter(defaultFilter)
  const brandSearch = FilterProps.filter.filterBy
    ? FilterHelpers.getSubOptions(FilterProps.filter.filterBy, 'Brand')
        ?.searchTerm
    : ''

  useEffect(() => {
    FilterProps.setFilter(defaultFilter)
  }, [
    ProductCategoriesLazyQuery.lazyQuery.meta.data?.companyProductCategories
      .length,
  ])

  const [updateProduct] = useMutation<
    SabreUpdateProductMutation,
    SabreUpdateProductMutationVariables
  >(SABRE_UPDATE_PRODUCT, { context: { endPoint: 'sabre' } })

  const [resolveDraftProduct] = useMutation<
    SabreResolveDraftProductMutation,
    SabreResolveDraftProductMutationVariables
  >(SABRE_RESOLVE_DRAFT_PRODUCT, { context: { endPoint: 'sabre' } })

  const {
    itemsCache: products,
    setItemsCache: setProducts,
    query: productsQuery,
  } = useInfiniteScrollAndSearch<
    SabreGetProductsQuery,
    SabreGetProductsQueryVariables,
    SabreProductFragment
  >({
    LazyQuery: ProductLazyQuery,
    QueryVariables: {
      input: {
        searchTerm: StringHelpers.isNullOrEmpty(ProductLazyQuery.debouncedQuery)
          ? null
          : ProductLazyQuery.debouncedQuery,
        brandFilter: FilterHelpers.getSubOptions(
          FilterProps.filter.filterBy!,
          'Brand',
        )?.selected,
        // categoryFilter: FilterHelpers.getSubOptions(
        //   FilterProps.filter.filterBy!,
        //   'Category',
        // )?.selected,
        // sortBy: StringHelpers.enumValue(
        //   FilterProps.filter.sortBy.selected,
        // ,
        offset: ProductLazyQuery.Pagination.offset,
        limit: ProductLazyQuery.Pagination.limit,
        onlyDrafts: FilterProps.filter.filterBy.selected.includes('Feedback'),
        // needsImage: FilterProps.filter.filterBy.selected.includes(
        //   'Needs Image',
        // ),
      },
    },
    queryResponse: productsResponse,
    resetQueryDependencies: [
      FilterProps.numberOfItemsSelected,
      FilterProps.filter.sortBy.selected.length,
    ],
  })

  const handleResolveProduct = () => {
    if (productEditing) {
      setProductEditing({
        ...productEditing,
        productFlagResolved: true,
      })
    }
  }
  const handleSubmit = () => {
    if (productEditing && selectedProductUpc) {
      updateProduct({
        variables: {
          input: {
            upc: selectedProductUpc,
            name: productEditing.name,
            brandId: productEditing.brand?.id,
            // categoryId: productEditing.category?.id,
            packaging: productEditing.packaging
              ? {
                  quantity: productEditing.packaging.quantity || 0,
                  size: productEditing.packaging?.size || 0,
                  unit: productEditing.packaging?.unit,
                  container: productEditing.packaging.container
                    ? (StringHelpers.enumValue(
                        productEditing.packaging.container,
                      ) as SabrePackagingType)
                    : null,
                }
              : null,
            flavors: productEditing.flavors.map(fl => fl.name) || [],
            manufacturer: productEditing.manufacturer?.name,
            // verifiedBy: user!!.uuid,
            primaryMarketingImage: productEditing?.defaultImage?.id
              ? {
                  url: productEditing.defaultImage.uri,
                  id: productEditing.defaultImage.id,
                }
              : undefined,
          },
        },
      })
        .then(() => {
          if (productEditing.productFlagResolved) {
            resolveDraftProduct({
              variables: {
                input: {
                  id: productEditing.drafts[0].id,
                },
              },
            })
          }
        })
        .then(() => {
          setSuccess('Successfully saved product.')
          setShowConfirmSetProduct(false)
          setProductEditing({
            ...productEditing,
            productFlagResolved: productEditing.productFlagResolved
              ? undefined
              : !!productEditing.drafts,
            drafts: productEditing.drafts.filter(d =>
              StringHelpers.isNullOrEmpty(d.dateResolved),
            ),
          })
          setProducts([])
          ProductLazyQuery.Pagination.setOffset(0)
          productsQuery(0)
        })
        .catch(err => setError(err))
    }
  }

  const hasMore = ProductLazyQuery.lazyQuery.meta.data?.products.hasMore

  const marketingImages =
    productEditing?.images
      .filter(i => i.type === SabreProductImageType.MARKETING)
      .map((img: { url: any; id: any }) => ({
        uri: img.url,
        id: img.id,
        imageType: ImageType.MARKETING,
      })) || []
  const productImages =
    productEditing?.images
      .filter(i => i.type === SabreProductImageType.FIELD_GENERATED)
      .map((img: { url: any; id: any }) => ({
        uri: img.url,
        id: img.id,
        imageType: ImageType.MARKETING,
      })) || []

  const planogramImages =
    productEditing?.planogramImages?.normal.map(
      (img: { url: any; id: any }) => ({
        uri: img.url,
        id: img.id,
        imageType: ImageType.PLANOGRAM,
      }),
    ) || []

  useEffect(() => {
    if (products && selectedProductUpc) {
      const pr = products.find(
        (p: { upcA: string }) => p.upcA === selectedProductUpc,
      )
      if (pr) {
        const product = {
          ...pr,
          defaultImage: pr.marketingImageUrl
            ? {
                uri: pr.marketingImageUrl,
              }
            : undefined,
          marketingImageUrl: pr.marketingImageUrl,
          drafts: pr.drafts.filter(d =>
            StringHelpers.isNullOrEmpty(d.dateResolved),
          ),
        }
        setProductEditing(product)
        setOriginalProduct(product)
      }
    }
  }, [selectedProductUpc])

  const resetProduct = () => {
    if (originalProduct) {
      setProductEditing({ ...originalProduct })
    }
  }

  useEffect(() => {
    if (
      products &&
      products[0] &&
      (selectedProductUpc == null ||
        !products
          .map((p: { upcA: any }) => p.upcA)
          .includes(selectedProductUpc))
    ) {
      setSelectedProductUpc(products[0].upcA)
    }
  }, [products, selectedProductUpc])

  useEffect(() => {
    ProductLazyQuery.Pagination.handleOffset.returnToStart()
  }, [])

  useEffect(() => {
    if (productEditing) {
      ProductMarketingImageLazyQuery.lazyQuery.query({
        variables: { input: { upc: productEditing.upcA } },
      })
    }
  }, [productEditing?.id])

  useEffect(() => {
    if (productEditing) {
      ProductDataLazyQuery.lazyQuery.query({
        variables: {
          upc: productEditing.upcA,
        },
      })
    }
  }, [productEditing?.id])

  useEffect(() => {
    ProductCategoriesLazyQuery.lazyQuery.query({
      variables: { companyId: null },
    })
  }, [])

  useEffect(() => {
    if (!StringHelpers.isNullOrEmpty(brandSearch)) {
      BrandLazyQuery.lazyQuery.query({
        variables: { input: { brandName: brandSearch } },
      })
    }
  }, [brandSearch])

  useEffect(() => {
    const res = BrandLazyQuery.lazyQuery.meta.data?.brands.results
    if (res) {
      const subOptions = res.map((br: { name: any; id: any }) => ({
        display: br.name,
        value: br.id,
      }))
      FilterProps.handleSetSubOptions(subOptions, 'Brand')
    }
  }, [BrandLazyQuery.lazyQuery.meta.data])

  return {
    state: {
      productEditing,
      setProductEditing,
      selectedProductUpc,
      setSelectedProductUpc,
      ProductLazyQuery,
      ProductMarketingImageLazyQuery,
      modalOpen,
      setModalOpen,
      FilterProps,
      formState,
      showConfirmSaveProduct,
      setShowConfirmSetProduct,
    },
    data: {
      addingImage,
      deletingImage,
      canSubmit,
      loading: ProductLazyQuery.lazyQuery.meta.loading,
      marketingImagesLoading:
        ProductDataLazyQuery.lazyQuery.meta.loading ||
        addingImage ||
        deletingImage,
      productImagesLoading: ProductDataLazyQuery.lazyQuery.meta.loading,
      planogramImagesLoading: ProductDataLazyQuery.lazyQuery.meta.loading,
      hasMore,
      products,
      marketingImages,
      productImages,
      planogramImages,
    },
    handle: {
      handleResolveProduct,
      handleSubmit,
      clearForm: resetProduct,
      handleSetDefaultImage,
      handleDeleteImage,
      handleUploadImage,
    },
  }
}
