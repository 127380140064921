/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import { DZFile } from '@dai/common'
import { ref, uploadBytes } from 'firebase/storage'
import { imageStorage, videoStorage } from '../api/Firebase'

const { REACT_APP__FB_PROJECT_ID } = process.env

export const uploadImageOrVideoToFirebaseBucket = async (
  imageFile: DZFile,
  fileType: 'videos' | 'images',
  id: string,
  gcsDirectory: 'picos' | 'productMarketing' | 'training' | 'training-videos',
) => {
  const extension = imageFile.path.slice(imageFile.path.lastIndexOf('.') + 1)
  const uploadFilePath = `${gcsDirectory}/${id}.${extension}`
  const storageRef = ref(
    fileType === 'images' ? imageStorage : videoStorage,
    uploadFilePath,
  )
  const uploadTask = await uploadBytes(storageRef, imageFile)
  return {
    bucketPath: `${REACT_APP__FB_PROJECT_ID}-${fileType}/${uploadFilePath}`,
    fileUuid: id,
    uploadTask,
  }
}
