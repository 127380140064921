/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext } from 'react'
import PicOSContext from 'context/PicOSContext'
import { ErrorIconAndDescription } from 'components/ErrorIconAndDescription'

const PicOSLayout: React.FC = props => {
  const { selectedBanner } = useContext(PicOSContext)

  if (!selectedBanner) {
    return (
      <ErrorIconAndDescription subtitle="Select a customer banner in the top left corner to show PicOS Priorities." />
    )
  }

  return <>{props.children}</>
}

export default PicOSLayout
