/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext } from 'react'
import PicOSLayout from 'picos/components/PicOSLayout'
import {
  ActionBar,
  AvatarWithText,
  FlexBox,
  MuiTheme,
  PageTitle,
  Sidebar,
  NotificationAlert,
  NavigationDrawerContext,
} from '@dai/web-components'
import PicOSPriorities from 'picos/components/PicOSPriorities'
import { useRankPicOSPrioritiesLogic } from 'picos/container/rank/RankPicOSPriorities.logic'
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  SpeedDialIcon,
} from '@mui/material'
import {
  CalendarMonth,
  Close,
  MoreVert,
  Save,
  ZoomOutMap,
} from '@mui/icons-material'
import { alpha } from '@mui/material/styles'
import { useWindowDimensions } from '@dai/common'
import { MUIDatePicker } from '@dai/web-components'
import dayjs from 'dayjs'

const RankPicOSPrioritiesContainer: React.FC = () => {
  const {
    data: { prioritiesLoading },
    state: { rankContext, rankedPriorities, dateOptions, updateDateOptions },
    handle: { handleToggleContext, handleOnChangeRank, handleSaveRank },
  } = useRankPicOSPrioritiesLogic()
  const { width } = useWindowDimensions()
  const { isOpen: drawerIsOpen } = useContext(NavigationDrawerContext)

  return (
    <PicOSLayout>
      <NotificationAlert />
      <FlexBox.Row>
        <Sidebar
          side="left"
          variant="sm"
          Header={<PageTitle title="Selected Week" />}
        >
          <List>
            <ListItem>
              <MUIDatePicker
                date={dayjs(
                  dateOptions?.startDate ? dateOptions?.startDate : null,
                )}
                onDateChange={newDate =>
                  updateDateOptions('startDate', newDate)
                }
                label={'Start Date'}
              />
            </ListItem>
            <ListItem>
              <MUIDatePicker
                date={dayjs(dateOptions?.endDate ? dateOptions?.endDate : null)}
                onDateChange={newDate => updateDateOptions('endDate', newDate)}
                label={'End Date'}
              />
            </ListItem>
          </List>
        </Sidebar>
        <FlexBox.Col flex={1} m={-2}>
          {rankContext === 'edit' && (
            <FlexBox.RowRight
              sx={theme => ({
                height: 75,
                bgcolor: alpha(theme.palette.info.light, 0.35),
                p: 2,
              })}
            >
              <Box>
                <AvatarWithText
                  primaryText="Ranking Priorities"
                  secondaryText="Rank the order of the priorities by dragging/dropping the tiles."
                  boldPrimary
                  avatarColor={MuiTheme.palette.grey['200']}
                >
                  <ZoomOutMap />
                </AvatarWithText>
              </Box>
            </FlexBox.RowRight>
          )}
          <Box p={1} flex={1}>
            <PicOSPriorities
              priorities={rankedPriorities}
              loading={prioritiesLoading}
              dragProps={{
                handleOnChangeRank,
                disableDrag: rankContext !== 'edit',
              }}
              gridDefault={
                width <= MuiTheme.breakpoints.values.sm
                  ? 1
                  : width >= MuiTheme.breakpoints.values.lg
                  ? drawerIsOpen
                    ? 3
                    : 4
                  : drawerIsOpen
                  ? 2
                  : 3
              }
            />
          </Box>
        </FlexBox.Col>
      </FlexBox.Row>
      <ActionBar
        speedDialIcon={
          <SpeedDialIcon
            icon={<MoreVert color="secondary" />}
            openIcon={<Close color="secondary" />}
          />
        }
        bulkActions={[
          {
            condition: rankContext === 'view',
            onClick: handleToggleContext,
            icon: <ZoomOutMap color="secondary" />,
            label: 'Change Ranking',
          },
          {
            condition: rankContext === 'edit',
            onClick: handleToggleContext,
            icon: <Close color="secondary" />,
            label: 'Cancel',
          },
          {
            condition: rankContext === 'edit',
            onClick: handleSaveRank,
            icon: <Save color="secondary" />,
            label: 'Save Ranking',
          },
        ]}
      />
    </PicOSLayout>
  )
}

export default RankPicOSPrioritiesContainer
