/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import * as React from 'react'
import { useContext } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import { Box, Button, Grid, IconButton, Stack, Theme } from '@mui/material'
import {
  ButtonWithProgress,
  FileUploader,
  FlexBox,
  FormInputType,
  gridAllScreens,
  ImageCarousel,
  MuiForm,
} from '@dai/web-components'
import StoreGroupForm from 'picos/components/StoreGroupForm'
import { useCreatePicOSPriorityLogic } from 'picos/container/create/CreatePicOSPriority.logic'
import PicOSLayout from 'picos/components/PicOSLayout'
import PicOSContext from 'context/PicOSContext'
import { ImageType } from '@dai/graphql'
import { useHistory, useParams } from 'react-router-dom'
import { uniqueId } from '@dai/common'
import PicOSDraftsAccordion from 'picos/components/PicOSDraftsAccordion'
import { ErrorIconAndDescription } from 'components/ErrorIconAndDescription'
import { ArrowRightAlt, Delete, Done } from '@mui/icons-material'

const CreatePicOSPriorityContainer: React.FC = () => {
  const { id: picosId } = useParams<{ id?: string }>()
  const navigate = useHistory()
  if (!picosId) {
    navigate.push(`/picos/create/${uniqueId()}`)
  }
  const {
    state: {
      activeStep,
      imageFile,
      setImageFile,
      draftPicos,
      incompleteOrLoading,
    },
    data: {
      forms,
      isImageStep,
      totalSteps,
      completedSteps,
      activeStepCompleted,
      orderOfForms,
      steps,
      createPicOSLoading,
      updatePicOSLoading,
      allowedFileFormats,
    },
    forms: { SGF },
    handle: {
      handleStep,
      handleSubmit,
      handleClearForms,
      handleDeletePicosDraft,
      handleEditPicosDraft,
    },
  } = useCreatePicOSPriorityLogic(picosId || '')
  const { priorityToEdit, selectedBanner } = useContext(PicOSContext)
  // @ts-ignore
  const activeForm = forms[activeStep]

  const draftIsSameAsSelected =
    draftPicos.length === 1 && draftPicos[0].id === picosId
  const showPicosDraftsAccordion =
    selectedBanner && !!draftPicos.length && !draftIsSameAsSelected

  if (JSON.stringify(selectedBanner) === '{}') {
    return (
      <Grid item justifyContent="center" alignItems="center" flex={1} pt={7}>
        <ErrorIconAndDescription
          title="Ugh Oh!"
          subtitle="Select a Customer Banner to continue."
        />
      </Grid>
    )
  }

  return (
    <PicOSLayout>
      <Grid container direction="row">
        <Grid item {...gridAllScreens(3)} sm={0} xs={0} />
        <Grid item {...gridAllScreens(6)} sm={12} xs={12}>
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {Object.values(steps).map((step, index) => (
              // @ts-ignore
              <Step key={step.name} completed={forms[index].completed}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {step.name}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {showPicosDraftsAccordion && (
            <PicOSDraftsAccordion
              sx={theme => ({
                marginTop: 4,
                marginBottom: {
                  xl: 4,
                  lg: 4,
                  md: 4,
                  sm: 0,
                  xs: 0,
                },
              })}
              onDeleteClick={id =>
                handleDeletePicosDraft(id, selectedBanner.display)
              }
              onSelectToEditClick={id => handleEditPicosDraft(id)}
              draftsPicos={draftPicos}
            />
          )}
          <FlexBox.Col width="100%" sx={{ pt: 2 }}>
            <FlexBox.ColCenter
              sx={theme => ({
                width: 'fit-content',
                height: 'fit-content',
                backgroundColor: theme.palette.background.default,
                borderRadius: 1,
                boxShadow: theme.shadows[1],
                pt: 2,
                pb: {
                  xl: 4,
                  lg: 4,
                  md: 4,
                  sm: 0,
                  xs: 0,
                },
                alignSelf: 'center',
              })}
            >
              <FlexBox.Col
                sx={theme => ({
                  flexGrow: 1,
                  p: {
                    xl: theme.spacing(0, 2, 4),
                    lg: theme.spacing(0, 2, 4),
                    md: theme.spacing(0, 2, 4),
                    sm: theme.spacing(0, 0, 0),
                    xs: theme.spacing(0, 0, 0),
                  },
                })}
                alignItems="center"
              >
                {!isImageStep ? (
                  <>
                    <MuiForm
                      orderOfForms={orderOfForms as FormInputType[]}
                      {...activeForm}
                    />
                    <StoreGroupForm
                      show={
                        forms[0].formState?.radio.STORES.value === 'false' &&
                        activeStep === 0
                      }
                      form={SGF}
                    />
                  </>
                ) : (
                  <FlexBox.Col>
                    {priorityToEdit?.imageUrl && !imageFile && (
                      <Box mb={2}>
                        <ImageCarousel
                          title="Current PicOS Image"
                          images={[
                            {
                              uri: priorityToEdit.imageUrl,
                              id: priorityToEdit.id,
                              imageType: ImageType.IMAGE,
                            },
                          ]}
                          buttons={[]}
                        />
                      </Box>
                    )}
                    <FileUploader
                      title={`${imageFile ? '' : 'Upload '}New PicOS Image`}
                      onFilesSelected={files => setImageFile(files[0])}
                      files={imageFile ? [imageFile] : undefined}
                      onClearFiles={() => setImageFile(undefined)}
                      allowedFileTypes={allowedFileFormats}
                    />
                  </FlexBox.Col>
                )}
              </FlexBox.Col>
              <Stack direction="row" spacing={5} width={'100%'}>
                <FlexBox.RowBetween
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    paddingX: 4,
                  }}
                >
                  <Button
                    startIcon={
                      <IconButton size="small" sx={{ padding: 0 }}>
                        <Delete color="secondary" />
                      </IconButton>
                    }
                    variant="contained"
                    onClick={() => {
                      handleClearForms()
                      setImageFile(undefined)
                    }}
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                  <ButtonWithProgress
                    endIcon={
                      <IconButton size="small" sx={{ padding: 0 }}>
                        {incompleteOrLoading ? (
                          <ArrowRightAlt
                            color={
                              !activeStepCompleted ? 'disabled' : 'success'
                            }
                          />
                        ) : (
                          <Done
                            color={
                              incompleteOrLoading ? 'disabled' : 'secondary'
                            }
                          />
                        )}
                      </IconButton>
                    }
                    variant={incompleteOrLoading ? 'outlined' : 'contained'}
                    disabled={!activeStepCompleted}
                    onClick={
                      activeStep !== steps.picosImage.indexNum
                        ? handleStep((activeStep + 1) % totalSteps)
                        : handleSubmit
                    }
                    loading={createPicOSLoading || updatePicOSLoading}
                    color="success"
                  >
                    {!isImageStep ? 'Next' : 'Submit'}
                  </ButtonWithProgress>
                </FlexBox.RowBetween>
              </Stack>
            </FlexBox.ColCenter>
          </FlexBox.Col>
        </Grid>
        <Grid item {...gridAllScreens(3)} sm={0} xs={0} />
      </Grid>
    </PicOSLayout>
  )
}

export default CreatePicOSPriorityContainer
