/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GET_STORE_CHAINS,
  GetStoreChainsQuery,
  GetStoreChainsQueryVariables,
  StoreCategory,
} from '@dai/graphql'
import { useEffect, useState } from 'react'
import { SelectableOption, useDebouncedItemQuery } from '@dai/web-components'
import { FormPicOSPriority } from 'picos/picos.types'
import { PicosConstants } from 'picos/picos.constants'

export const usePicOSLogic = () => {
  const [selectedBanner, setSelectedBanner] = useState<SelectableOption>()
  const [priorityToEdit, setPriorityToEdit] = useState<FormPicOSPriority>(
    PicosConstants.EMPTY_PICOS,
  )
  const [isNewPriority, setIsNewPriority] = useState(true)
  const [storeTypesInCustomer, setStoreTypesInCustomer] = useState<
    StoreCategory[]
  >()
  const CustomerBannerLazyQuery = useDebouncedItemQuery<
    GetStoreChainsQuery,
    GetStoreChainsQueryVariables
  >({
    queryStr: GET_STORE_CHAINS,
    options: { fetchPolicy: 'cache-first' },
  })

  const setAndSaveSelectedBanner = (banner: SelectableOption) => {
    banner
      ? localStorage.setItem('banner', JSON.stringify(banner))
      : localStorage.removeItem('banner')
    setSelectedBanner(banner)
  }

  useEffect(() => {
    const oldBanner = JSON.parse(localStorage.getItem('banner') || '{}')
    if (oldBanner) {
      setSelectedBanner(oldBanner)
    }
  }, [])

  useEffect(() => {
    CustomerBannerLazyQuery.lazyQuery.query({
      variables: {
        input: {
          searchTerm: CustomerBannerLazyQuery.debouncedQuery,
        },
      },
    })
  }, [CustomerBannerLazyQuery.debouncedQuery])

  const banners: SelectableOption[] =
    CustomerBannerLazyQuery?.lazyQuery?.meta?.data?.storeChains?.results?.map(
      ban => ({
        display: ban.name,
        value: ban.id,
      }),
    ) || []

  return {
    state: {
      CustomerBannerLazyQuery,
      selectedBanner,
      setAndSaveSelectedBanner,
      priorityToEdit,
      setPriorityToEdit,
      storeTypesInCustomer,
      setStoreTypesInCustomer,
      isNewPriority,
      setIsNewPriority,
    },
    data: {
      banners,
    },
  }
}
