/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Clark Sandholtz <clarksandholtz@gmail.com>
 */

import React, { useContext } from 'react'
import { StyledFirebaseAuth } from 'react-firebaseui'
import { Redirect, useLocation } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'
import UserContext from '../context/UserContext'
import { auth, uiConfig } from '../api/Firebase'

const LoginContainer: React.FunctionComponent = () => {
  const { user, error } = useContext(UserContext)
  const { state } = useLocation()
  if (user) {
    return <Redirect to={{ pathname: '/', state }} />
  }
  return (
    <Stack
      height="100vh"
      spacing={4}
      justifyContent="center"
      alignItems="center"
      sx={theme => ({ backgroundColor: theme.palette.background.default })}
    >
      <img src="/logo-black.svg" width={300} />
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={auth}
        className="dai-firebase"
      />
      {error && (
        <Typography
          sx={theme => ({ color: theme.palette.error.main, maxWidth: 500 })}
        >
          {error}
        </Typography>
      )}
    </Stack>
  )
}

export default LoginContainer
