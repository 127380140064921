"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOUNDING_BOXES_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var WhiteRabbitProductFragment_1 = require("./WhiteRabbitProductFragment");
exports.BOUNDING_BOXES_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment BoundingBoxes on BoundingBoxSet {\n    id\n    created\n    modified\n    verified\n    verifiedBy {\n      id\n      firstName\n      lastName\n      email\n    }\n    verifiedDate\n    boundingBoxes {\n      id\n      label\n      verifiedLocation\n      elDoradoId\n      confidence\n      coordinates {\n        minX\n        maxX\n        minY\n        maxY\n      }\n      verified\n    }\n  }\n  ", "\n"], ["\n  fragment BoundingBoxes on BoundingBoxSet {\n    id\n    created\n    modified\n    verified\n    verifiedBy {\n      id\n      firstName\n      lastName\n      email\n    }\n    verifiedDate\n    boundingBoxes {\n      id\n      label\n      verifiedLocation\n      elDoradoId\n      confidence\n      coordinates {\n        minX\n        maxX\n        minY\n        maxY\n      }\n      verified\n    }\n  }\n  ", "\n"])), WhiteRabbitProductFragment_1.WHITE_RABBIT_SIMPLE_PRODUCT_FRAGMENT);
var templateObject_1;
