/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  DAITable,
  DAITableColumns,
  Filter,
  MuiFormModal,
  MuiFormModalProps,
  MuiTheme,
  SearchBar,
  TableContainerClass,
  FillContainerLoading,
  useDebouncedItemQuery,
} from '@dai/web-components'
import { SimpleStoreRow } from 'stores/stores.types'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { AssignmentTurnedIn, Save } from '@mui/icons-material'
import {
  GET_STORES,
  GetStoresQuery,
  GetStoresQueryVariables,
} from '@dai/graphql'
import { useState } from 'react'
import { RARRoute } from './types'
import { useStoresLogic } from '../Stores.logic'

type AddStoresToRouteModalProps = {
  selectedRoute: RARRoute | undefined
  storeIdsToIgnore?: string[]
  onSave: (
    selectedRoute: RARRoute,
    stores: GetStoresQuery['companyStores']['results'],
  ) => void
} & Omit<MuiFormModalProps, 'title' | 'actionButtonsProps'>

const columns: DAITableColumns<SimpleStoreRow>[] = [
  {
    field: 'store',
    headerName: 'Name',
    width: MuiTheme.sizes.table.column.width.lg,
  },
  {
    field: 'address',
    headerName: 'Address',
    width: MuiTheme.sizes.table.column.width.lg,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: MuiTheme.sizes.table.column.width.xs,
  },
  {
    field: 'customerId',
    headerName: 'Customer Id',
    width: MuiTheme.sizes.table.column.width.sm,
  },
]

const AddStoresToRouteModal: React.FC<AddStoresToRouteModalProps> = props => {
  const { handleClose, selectedRoute, onSave, storeIdsToIgnore = [] } = props
  const [loadingStores, setLoadingStores] = useState(false)
  const {
    state: {
      setStoreSearch,
      selectedIds,
      setSelectedIds,
      FilterProps,
      setHideInactive,
      hideInactive,
    },
    handle: { handlePageChange },
    ref: { tableRef },
    data: { isRedAdmin, storeRows, loading, count, PAGE_SIZE },
  } = useStoresLogic<SimpleStoreRow>({
    defaultToLargeStoresOnly: true,
  })

  const CompanyStoresLQ = useDebouncedItemQuery<
    GetStoresQuery,
    GetStoresQueryVariables
  >({
    queryStr: GET_STORES,
    options: { fetchPolicy: 'cache-and-network' },
  })

  const handleAddStores = async () => {
    if (selectedRoute) {
      setLoadingStores(true)
      const res = await CompanyStoresLQ.lazyQuery.query({
        variables: {
          input: {
            storeIds: selectedIds,
          },
        },
      })
      const stores = res.data?.companyStores.results || []
      setLoadingStores(false)
      setSelectedIds([])
      onSave(selectedRoute, stores)
      handleClose()
    }
  }

  return (
    <MuiFormModal
      {...props}
      title="Add Store(s) to Route"
      subtitle={`${selectedRoute?.rar.firstName} ${selectedRoute?.rar.lastName}`}
      actionButtonsProps={[
        {
          children: 'Save',
          onClick: handleAddStores,
          disabled: !selectedIds.length || loadingStores,
          startIcon: <Save color="secondary" />,
        },
      ]}
    >
      {loadingStores ? (
        <FillContainerLoading
          minHeight="40vh"
          Below={<Typography pt={2}>Saving Stores...</Typography>}
        />
      ) : (
        <DAITable
          className={TableContainerClass.MODAL}
          tableToolBarProps={{
            tableTitle: 'Stores',
            right: {
              others: [
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => {
                        setHideInactive(!hideInactive)
                      }}
                      checked={hideInactive}
                    />
                  }
                  label="Hide Inactive"
                />,
                <SearchBar
                  onChange={value => setStoreSearch(value)}
                  useResponsive
                />,
                <Filter filterTitle="Filter Stores" {...FilterProps} />,
              ],
            },
          }}
          ref={tableRef}
          rows={storeRows.filter(row => !storeIdsToIgnore.includes(row.id))}
          columns={columns}
          pageSize={PAGE_SIZE}
          rowCount={count}
          onPageChange={handlePageChange}
          loading={loading}
          disableBulkActions
          disableSelectAll
          showCheckbox={isRedAdmin}
          onSelectionChange={setSelectedIds}
        />
      )}
    </MuiFormModal>
  )
}

export default AddStoresToRouteModal
