/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Bryan Sheranian <bryan.sheranian@deliciousai.com>
 */

import { useEffect, useState } from 'react'
import { DAIFormState, SelectableOption } from '@dai/web-components'
import { StoreRow } from 'stores/stores.types'

export const useEditOptimizationsForm = ({
  stores,
}: {
  stores: StoreRow[]
}) => {
  const getInitialValue = (
    stores: StoreRow[],
    prop: keyof StoreRow,
  ): string => {
    if (stores.length && stores[0][prop]) {
      if (stores.every(store => store[prop] === stores[0][prop])) {
        return stores[0][prop]?.toString() || ''
      }
    }
    return ''
  }

  const highSalesVolumeInitial = getInitialValue(
    stores,
    'numTimesOptimizeHighPerYear',
  )
  const mediumSalesVolumeInitial = getInitialValue(
    stores,
    'numTimesOptimizeMediumPerYear',
  )
  const lowSalesVolumeInitial = getInitialValue(
    stores,
    'numTimesOptimizeLowPerYear',
  )
  const complianceScanFrequencyInitial = getInitialValue(
    stores,
    'timesPerWeekTwoDScan',
  )

  const EDIT_OPTIMIZATIONS_FORM = (input: {
    highSalesVolumeInitial: string
    mediumSalesVolumeInitial: string
    lowSalesVolumeInitial: string
    complianceScanFrequencyInitial: string
  }): DAIFormState => ({
    select: {
      highNumberOfOptimizations: {
        value: highSalesVolumeInitial,
        variant: 'filled',
        help: 'Optimizations per Year',
        label: 'High Sales Volume',
        input: {
          required: false,
        },
        options: Array.from(
          new Array(10),
          (_, index): SelectableOption => ({
            value: `${index + 1}`,
            display: `${index + 1 === 4 ? '4 (default)' : index + 1}`,
          }),
        ),
        gridProps: {
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
        },
      },
      mediumNumberOfOptimizations: {
        value: mediumSalesVolumeInitial,
        variant: 'filled',
        help: 'Optimizations per Year',
        label: 'Medium Sales Volume',
        input: {
          required: false,
        },
        options: Array.from(
          new Array(10),
          (_, index): SelectableOption => ({
            value: `${index + 1}`,
            display: `${index + 1 === 2 ? '2 (default)' : index + 1}`,
          }),
        ),
        gridProps: {
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
        },
      },
      lowNumberOfOptimizations: {
        value: lowSalesVolumeInitial,
        variant: 'filled',
        help: 'Optimizations per Year',
        label: 'Low Sales Volume',
        input: {
          required: false,
        },
        options: Array.from(
          new Array(10),
          (_, index): SelectableOption => ({
            value: `${index + 1}`,
            display: `${index + 1 === 1 ? '1 (default)' : index + 1}`,
          }),
        ),
        gridProps: {
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
        },
      },
      complianceScanFrequency: {
        value: complianceScanFrequencyInitial,
        variant: 'filled',
        help: 'Scans per Week',
        label: '2D Compliance Scan Frequency',
        input: {
          required: false,
        },
        options: Array.from(
          new Array(10),
          (_, index): SelectableOption => ({
            value: `${index + 1}`,
            display: `${index + 1 === 1 ? '1 (default)' : index + 1}`,
          }),
        ),
        gridProps: {
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
        },
      },
    },
    autocomplete: {},
    text: {},
    radio: {},
    searchSelect: {},
    checkbox: {},
  })

  const [formState, setFormState] = useState<DAIFormState>(
    EDIT_OPTIMIZATIONS_FORM({
      highSalesVolumeInitial,
      mediumSalesVolumeInitial,
      lowSalesVolumeInitial,
      complianceScanFrequencyInitial,
    }),
  )

  useEffect(() => {
    setFormState(
      EDIT_OPTIMIZATIONS_FORM({
        highSalesVolumeInitial,
        mediumSalesVolumeInitial,
        lowSalesVolumeInitial,
        complianceScanFrequencyInitial,
      }),
    )
  }, [
    highSalesVolumeInitial,
    mediumSalesVolumeInitial,
    lowSalesVolumeInitial,
    complianceScanFrequencyInitial,
  ])

  return {
    formState,
    setFormState,
  }
}
