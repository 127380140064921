"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PICOS_PACKAGING = void 0;
var client_1 = require("@apollo/client");
exports.GET_PICOS_PACKAGING = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery GetPicosPackaging ($input: PicosPackagingInput!){\n  picosPackaging(input: $input){\n\t\thasMore\n\t\tcount\n\t\tresults{\n\t\t\tid\n\t\t\tunit\n\t\t\tquantity\n\t\t\tcontainer\n\t\t\tpackagingString\n\t\t}\n  }\n}\n"], ["\nquery GetPicosPackaging ($input: PicosPackagingInput!){\n  picosPackaging(input: $input){\n\t\thasMore\n\t\tcount\n\t\tresults{\n\t\t\tid\n\t\t\tunit\n\t\t\tquantity\n\t\t\tcontainer\n\t\t\tpackagingString\n\t\t}\n  }\n}\n"])));
var templateObject_1;
