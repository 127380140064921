/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import PicOSContext from 'context/PicOSContext'
import { useSearchPriorities } from 'picos/hooks/use-search-priorities'
import { PicosConstants } from 'picos/picos.constants'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

export const useEditPicOSPrioritiesLogic = () => {
  const {
    state: searchState,
    data: searchData,
    PriorityLazyQuery,
  } = useSearchPriorities()
  const history = useHistory()
  const { setPriorityToEdit, setIsNewPriority, selectedBanner } = useContext(
    PicOSContext,
  )

  const bannerSelected: boolean = JSON.stringify(selectedBanner) !== '{}'

  const handleSelectCreatePriority = () => {
    setPriorityToEdit(PicosConstants.EMPTY_PICOS)
    setIsNewPriority(true)
    history.push('/picos/create')
  }

  return {
    state: {
      ...searchState,
      bannerSelected,
    },
    handle: {
      handleSelectCreatePriority,
    },
    data: {
      ...searchData,
    },
    PriorityLazyQuery,
  }
}
