/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext } from 'react'
import {
  Avatar,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { FlexBox, NavigationDrawerContext } from '@dai/web-components'
import { DateTimeConstants } from '@dai/common'
import {
  DisplayElements,
  MandateTypes,
  PicOSPriorities_picosPriorities_results,
} from '@dai/graphql'
import dayjs from 'dayjs'
import { Theme } from '@mui/material/styles'

type PicOSCardProps = {
  priority: PicOSPriorities_picosPriorities_results
  loading?: boolean
  handleEdit?: () => void
}

const PicOSCard: React.FC<PicOSCardProps> = props => {
  const { priority, loading, handleEdit } = props
  const { isOpen: drawerIsOpen } = useContext(NavigationDrawerContext)

  const ImageMixin = (theme: Theme) => {
    const scaleVal = handleEdit ? 0.85 : 0.65
    return {
      height: {
        xl: (theme.sizes.img.md - (drawerIsOpen ? 50 : 0)) * scaleVal,
        lg: (theme.sizes.img.md - (drawerIsOpen ? 50 : 0)) * scaleVal,
        md: theme.sizes.img.sm * scaleVal,
        sm: theme.sizes.img.sm * scaleVal,
        xs: theme.sizes.img.sm * scaleVal,
      },
      width: {
        xl: (theme.sizes.img.md - (drawerIsOpen ? 50 : 0)) * scaleVal,
        lg: (theme.sizes.img.md - (drawerIsOpen ? 50 : 0)) * scaleVal,
        md: theme.sizes.img.sm * scaleVal,
        sm: theme.sizes.img.sm * scaleVal,
        xs: theme.sizes.img.sm * scaleVal,
      },
    }
  }

  const FontMixin = () => ({
    fontSize: {
      xl: drawerIsOpen ? '1rem' : '1.25rem',
      lg: drawerIsOpen ? '1rem' : '1.25rem',
      md: '1rem',
      sm: '1rem',
      xs: '1rem',
    },
  })

  const TitleHeaderProps = () => {
    const singleLine = {
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 1,
    }
    const fontProps = FontMixin()
    return { ...singleLine, ...fontProps }
  }

  if (loading || priority === undefined) {
    return (
      <Card>
        <CardHeader
          disableTypography
          title={
            <>
              <Skeleton
                variant="rounded"
                sx={theme => ({
                  width: '80%',
                  height: theme.sizes.skeleton.md,
                  mb: `${theme.sizes.skeleton.xs}px`,
                })}
              />
              <Skeleton
                variant="rounded"
                sx={theme => ({
                  width: '60%',
                  height: theme.sizes.skeleton.md,
                })}
              />
            </>
          }
          action={
            <Skeleton
              sx={theme => ({
                width: theme.sizes.skeleton.xl,
                height: theme.sizes.skeleton.xl,
                mr: 1,
              })}
              variant="circular"
            />
          }
        />
        <CardActionArea disabled>
          <FlexBox.RowBetween>
            <Skeleton
              sx={theme => ({
                ...ImageMixin(theme),
                m: 2,
              })}
              variant="rounded"
            />
            <CardContent
              sx={theme => ({
                p: theme.spacing(0, 2),
                flexGrow: 1,
              })}
            >
              {Array.from({ length: 6 }).map((_, idx) => (
                <Skeleton
                  key={`${idx}`}
                  sx={theme => ({
                    height: theme.sizes.skeleton.md,
                    mb: `${theme.sizes.skeleton.xs}px`,
                  })}
                />
              ))}
            </CardContent>
          </FlexBox.RowBetween>
        </CardActionArea>
        <CardActions sx={{ flexGrow: 1, mx: 1 }}>
          <FlexBox.RowBetween flexGrow={1}>
            <Skeleton
              sx={theme => ({
                height: theme.sizes.skeleton.lg,
                width: theme.sizes.skeleton.xl,
              })}
            />
            <Skeleton
              sx={theme => ({
                height: theme.sizes.skeleton.lg,
                width: '30%',
              })}
            />
          </FlexBox.RowBetween>
        </CardActions>
        <Skeleton
          variant="rectangular"
          sx={theme => ({
            height: theme.sizes.skeleton.lg,
            width: '100%',
          })}
        />
      </Card>
    )
  }

  return (
    <Card>
      <Tooltip
        title={`${priority.priority}. ${priority.name}`}
        sx={{ flexGrow: 1, fontSize: 600 }}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: 24,
            },
          },
        }}
        arrow
      >
        <>
          {' '}
          {/* This needs to be here to avoid console warnings */}
          <CardHeader
            title={priority.name}
            titleTypographyProps={{
              sx: () => TitleHeaderProps(),
            }}
            action={
              <Avatar
                sx={theme => ({
                  bgcolor:
                    priority.mandateType === MandateTypes.HQ
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main,
                })}
                variant="rounded"
              >
                <Typography variant="h5">{priority.priority}</Typography>
              </Avatar>
            }
          />
        </>
      </Tooltip>
      <Tooltip
        title="Click to Edit Priority"
        sx={{ flexGrow: 1 }}
        PopperProps={{
          sx: { display: handleEdit ? 'block' : 'none' },
        }}
        arrow
      >
        <>
          {' '}
          {/* This needs to be here to avoid console warnings */}
          <CardActionArea
            disabled={!handleEdit}
            onClick={() => handleEdit && handleEdit()}
          >
            <FlexBox.RowBetween>
              <CardMedia
                component="img"
                image={priority?.image?.url}
                alt="priority image"
                sx={theme => ({
                  objectFit: 'contain',
                  ...ImageMixin(theme),
                })}
              />
              <CardContent
                sx={theme => ({
                  overflow: 'scroll',
                  wordBreak: 'break-word',
                  height: theme.sizes.img.md,
                  p: theme.spacing(0, 2),
                  flexGrow: 1,
                })}
              >
                <Typography variant="body2">{priority.description}</Typography>
              </CardContent>
            </FlexBox.RowBetween>
          </CardActionArea>
        </>
      </Tooltip>
      <CardActions sx={{ flexGrow: 1, mx: 1 }}>
        <FlexBox.RowBetween flexGrow={1}>
          <Chip
            sx={theme => ({ color: theme.palette.text.primary })}
            label="Score"
          />
          <Stack spacing={1} direction="row">
            <Chip
              sx={theme => ({ color: theme.palette.text.primary })}
              label={`${dayjs(priority.startDate).format(
                DateTimeConstants.MM_DD,
              )} — ${dayjs(priority.endDate).format(DateTimeConstants.MM_DD)}`}
            />
            {priority.displayElements === DisplayElements.AD && (
              <Chip label="OnAd" color="success" />
            )}
          </Stack>
        </FlexBox.RowBetween>
      </CardActions>
      <FlexBox.RowCenter
        sx={theme => ({
          bgcolor:
            priority.mandateType === MandateTypes.HQ
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
        })}
        py={0.5}
      >
        <Typography
          variant="h5"
          sx={theme => ({
            color: theme.palette.primary.light,
            ...FontMixin(),
          })}
        >
          {priority.mandateType === MandateTypes.HQ
            ? 'HQ Mandated'
            : 'Local Sell'}
        </Typography>
      </FlexBox.RowCenter>
    </Card>
  )
}

export default PicOSCard
