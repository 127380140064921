"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PICOS_PRODUCTS = void 0;
var client_1 = require("@apollo/client");
exports.GET_PICOS_PRODUCTS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery GetPicosProducts ($input: PicosProductInput!){\n  picosProducts(input: $input){\n    hasMore\n    count\n    results{\n      name\n      id\n    }\n  }\n}\n"], ["\nquery GetPicosProducts ($input: PicosProductInput!){\n  picosProducts(input: $input){\n    hasMore\n    count\n    results{\n      name\n      id\n    }\n  }\n}\n"])));
var templateObject_1;
