"use strict";
/**
 * Copyright © 2021-2022 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PICOS_USERS = exports.AUDIT_USERS = exports.GROUPS_ALLOWED_TO_LOGIN_ADMIN = void 0;
var graphql_1 = require("@dai/graphql");
exports.GROUPS_ALLOWED_TO_LOGIN_ADMIN = [
    graphql_1.DAIGroupEnum.RED_ADMINS,
    graphql_1.DAIGroupEnum.RED_MANAGERS,
    graphql_1.DAIGroupEnum.PICOS,
];
exports.AUDIT_USERS = [
    graphql_1.DAIGroupEnum.RED_ADMINS,
    graphql_1.DAIGroupEnum.RED_MANAGERS,
    graphql_1.DAIGroupEnum.AUDITORS,
];
exports.PICOS_USERS = [graphql_1.DAIGroupEnum.PICOS];
