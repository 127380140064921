/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  CollapsibleContent,
  DAITable,
  gridAllScreens,
  ReportContainerLayout,
  ReportLayout,
  TableContainerClass,
  useMuiForm,
  FlexBox,
  MuiFormController,
} from '@dai/web-components'
import { Grid, Stack } from '@mui/material'
import React from 'react'
import { useDisruptiveBrandsContainerLogic } from 'brands/brand-disruptor/containers/DisruptiveBrandsContainer.logic'
import { BrandExplorer } from '../components/BrandExplorer'
import { BrandGeography } from '../components/BrandGeography'
import { BrandHistory } from '../components/BrandHistory'
import { BrandLandscape } from '../components/BrandLandscape'
import { BrandSelector } from '../components/BrandSelector'

const DisruptiveBrandsContainer: React.FC = () => {
  const {
    state: { selectedBrands, setSelectedBrands, formState, setFormState },
    data: {
      trendingUpRows,
      trendingDownRows,
      trendingColumns,
      newBrandRows,
      newBrandsColumns,
      loading,
      startDate,
      endDate,
      dateRangeType,
    },
  } = useDisruptiveBrandsContainerLogic()

  const {
    handle: { handleOnChange },
  } = useMuiForm({ formState, setFormState })

  const moreInfo = formState.select.PERIOD.options.find(
    opt => opt.value === formState.select.PERIOD.value,
  )?.display

  return (
    <ReportContainerLayout
      Header={
        <FlexBox.RowRight>
          <MuiFormController.select
            // @ts-ignore
            formState={formState.select}
            handleOnChange={handleOnChange}
          />
        </FlexBox.RowRight>
      }
      Summary={
        <CollapsibleContent
          LeftComponent={<Stack direction="row" spacing={1} />}
        >
          <Grid container spacing={2}>
            <Grid item {...gridAllScreens(3)} lg={6} md={6} sm={6} xs={12}>
              <ReportLayout
                moreInfo={moreInfo}
                title={'SOVI Trending Up'}
                container
                Body={
                  <DAITable
                    className={TableContainerClass.MODAL}
                    rows={trendingUpRows}
                    columns={trendingColumns}
                    loading={loading}
                    showCheckbox={false}
                  />
                }
              />
            </Grid>
            <Grid item {...gridAllScreens(3)} lg={6} md={6} sm={6} xs={12}>
              <ReportLayout
                moreInfo={moreInfo}
                title={'SOVI Trending Down'}
                container
                Body={
                  <DAITable
                    className={TableContainerClass.MODAL}
                    rows={trendingDownRows}
                    columns={trendingColumns}
                    loading={loading}
                    showCheckbox={false}
                  />
                }
              />
            </Grid>
            <Grid item {...gridAllScreens(6)} lg={12} md={12} sm={12} xs={12}>
              <ReportLayout
                title={'New Brands'}
                container
                Body={
                  <DAITable
                    className={TableContainerClass.MODAL}
                    rows={newBrandRows}
                    columns={newBrandsColumns}
                    loading={loading}
                    showCheckbox={false}
                  />
                }
              />
            </Grid>
          </Grid>
        </CollapsibleContent>
      }
      Body={
        <ReportLayout
          title={'Brand Reports'}
          subtitle={moreInfo}
          HeaderRightComponent={
            <BrandSelector
              label="Selected Brands"
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
            />
          }
          container
          Body={
            <Grid container>
              <Grid item {...gridAllScreens(6)} md={12} sm={12} xs={12}>
                <BrandExplorer
                  selectedBrands={selectedBrands}
                  setSelectedBrands={b => setSelectedBrands(b)}
                  startDate={startDate}
                  endDate={endDate}
                  dateRangeType={dateRangeType}
                />
              </Grid>
              <Grid item {...gridAllScreens(6)} md={12} sm={12} xs={12}>
                <BrandLandscape
                  selectedBrands={selectedBrands}
                  setSelectedBrands={b => setSelectedBrands(b)}
                  startDate={startDate}
                  endDate={endDate}
                  dateRangeType={dateRangeType}
                />
              </Grid>
              <Grid item {...gridAllScreens(6)} md={12} sm={12} xs={12}>
                <BrandGeography
                  setSelectedBrands={b => setSelectedBrands(b)}
                  selectedBrands={selectedBrands}
                  startDate={startDate}
                  endDate={endDate}
                  dateRangeType={dateRangeType}
                />
              </Grid>
              <Grid item {...gridAllScreens(6)} md={12} sm={12} xs={12}>
                <BrandHistory
                  selectedBrands={selectedBrands}
                  setSelectedBrands={b => setSelectedBrands(b)}
                  startDate={startDate}
                  endDate={endDate}
                  dateRangeType={dateRangeType}
                />
              </Grid>
            </Grid>
          }
        />
      }
    />
  )
}

export default DisruptiveBrandsContainer
