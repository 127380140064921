/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useSearchPriorities } from 'picos/hooks/use-search-priorities'
import { useContext, useEffect, useState } from 'react'
import PicOSContext from 'context/PicOSContext'
import {
  PicOSPriorities_picosPriorities_results,
  UPDATE_PICOS_PRIORITY,
  UpdatePicOSPriorityMutation,
  UpdatePicOSPriorityMutationVariables,
} from '@dai/graphql'
import { swap } from 'react-grid-dnd'
import { useMutation } from '@apollo/client'
import { NotificationContext } from '@dai/web-components'
import { DateTimeConstants, DateTimeHelpers } from '@dai/common'

type Context = 'view' | 'edit'

export const useRankPicOSPrioritiesLogic = () => {
  const { selectedBanner } = useContext(PicOSContext)
  const { setSuccess } = useContext(NotificationContext)
  const {
    state: searchState,
    data: searchData,
    PriorityLazyQuery,
  } = useSearchPriorities()
  const [selectedDate, setSelectedDate] = useState<string>(
    searchData.currentWeek,
  )
  const [rankContext, setRankContext] = useState<Context>('view')
  const [rankedPriorities, setRankedPriorities] = useState<
    PicOSPriorities_picosPriorities_results[]
  >(searchData.priorities || [])

  const [updatePicosMutation] = useMutation<
    UpdatePicOSPriorityMutation,
    UpdatePicOSPriorityMutationVariables
  >(UPDATE_PICOS_PRIORITY)

  const dateOptions = searchState.FilterProps.filter.filterBy?.dateOptions
  const updateDateOptions = searchState.FilterProps.updateDateOptions

  const handleIsCurrentWeek = (date: string) => date === searchData.currentWeek

  const handleSelectDate = (date: string) => setSelectedDate(date)

  const handleToggleContext = () => {
    if (rankContext === 'view') {
      setRankContext('edit')
    } else {
      setRankContext('view')
      setRankedPriorities(searchData.priorities)
    }
  }

  const handleOnChangeRank = (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string,
  ) => {
    const result = swap(rankedPriorities, sourceIndex, targetIndex)
    setRankedPriorities(result)
  }

  const handleSaveRank = async () => {
    let index = 0
    for (const priority of rankedPriorities) {
      await updatePicosMutation({
        variables: {
          input: {
            picosId: priority.id,
            newPriority: index + 1,
          },
        },
      })
      index += 1
    }
    queryPriorities()
    setRankContext('view')
    setSuccess('Successfully saved PicOS priorities.')
  }

  const queryPriorities = () => {
    const startDate = new Date(selectedDate)
    if (!selectedBanner?.value) {
      return
    }
    PriorityLazyQuery.lazyQuery
      .query({
        variables: {
          input: {
            startDate: selectedDate,
            endDate: DateTimeHelpers.getDateWeekDelta(1, startDate),
            storeChainId: selectedBanner.value,
          },
        },
      })
      .then(res => setRankedPriorities(res.data?.picosPriorities.results || []))
  }

  useEffect(() => {
    if (selectedBanner) {
      queryPriorities()
    }
  }, [selectedDate, selectedBanner])

  return {
    state: {
      ...searchState,
      selectedDate,
      setSelectedDate,
      rankContext,
      setRankContext,
      rankedPriorities,
      setRankedPriorities,
      dateOptions,
      updateDateOptions,
    },
    handle: {
      handleIsCurrentWeek,
      handleSelectDate,
      handleToggleContext,
      handleOnChangeRank,
      handleSaveRank,
    },
    data: {
      ...searchData,
    },
    PriorityLazyQuery,
  }
}
