/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext, useState } from 'react'
import {
  DAIFormState,
  gridAllScreens,
  SelectableOption,
  useDebouncedItemQuery,
  useMuiForm,
  useInfiniteScrollAndSearch,
  MuiFormTypes,
} from '@dai/web-components'
import {
  GET_COMPANY_PRODUCT_CATEGORIES,
  GET_PICOS_BRANDS,
  GET_PICOS_PACKAGING,
  GET_PICOS_PRODUCTS,
  GetCompanyProductCategoriesQuery,
  GetCompanyProductCategoriesQueryVariables,
  GetPicosBrandsQuery,
  GetPicosBrandsQueryVariables,
  GetPicosPackagingQuery,
  GetPicosPackagingQueryVariables,
  GetPicosProductsQuery,
  GetPicosProductsQueryVariables,
  GetCompanyProductCategories_companyProductCategories,
} from '@dai/graphql'
import PicOSContext from 'context/PicOSContext'
import { uniqBy } from 'lodash'
import { OptionsCheckBox, helpMsg } from 'picos/components/OptionsCheckBox'

type OthersAllowedType = 'categories' | 'brands' | 'packaging' | 'products'

export interface PicosProductsForm
  extends DAIFormState<'text' | 'searchSelect'> {
  text: {
    PRICE: MuiFormTypes['text']
  }
  searchSelect: {
    BRAND: MuiFormTypes['searchSelect']
    PRODUCT: MuiFormTypes['searchSelect']
    PACKAGING: MuiFormTypes['searchSelect']
    CATEGORY: MuiFormTypes['searchSelect']
  }
}
const sharedSearchSelectProps = {
  selectAllEnabled: true,
  gridProps: { ...gridAllScreens(3), xs: 12 },
  variant: 'filled' as 'filled',
  multiple: true,
  required: true,
}

const getCategoryResults = (
  results: GetCompanyProductCategories_companyProductCategories[],
  filterIncludes: string,
  currentOptions: SelectableOption[],
) => {
  const queryResults = results
    .filter(cc => cc.category.toLowerCase().includes(filterIncludes))
    .map(c => ({
      display: c.category,
      value: c.id,
    }))

  return uniqBy([...queryResults, ...currentOptions], 'value')
}

export const usePicosProductsForm = () => {
  const { priorityToEdit, setPriorityToEdit } = useContext(PicOSContext)

  const [othersAllowed, setOthersAllowed] = useState<
    Record<OthersAllowedType, boolean>
  >({
    categories: false,
    brands: false,
    packaging: false,
    products: false,
  })
  const CategoryLazyQuery = useDebouncedItemQuery<
    GetCompanyProductCategoriesQuery,
    GetCompanyProductCategoriesQueryVariables
  >({
    queryStr: GET_COMPANY_PRODUCT_CATEGORIES,
    options: {
      fetchPolicy: 'cache-first',
      variables: { companyId: null },
    },
    queryOnMount: true,
  })
  const BrandLazyQuery = useDebouncedItemQuery<
    GetPicosBrandsQuery,
    GetPicosBrandsQueryVariables
  >({
    queryStr: GET_PICOS_BRANDS,
    options: { fetchPolicy: 'cache-first' },
  })
  const PackagingLazyQuery = useDebouncedItemQuery<
    GetPicosPackagingQuery,
    GetPicosPackagingQueryVariables
  >({
    queryStr: GET_PICOS_PACKAGING,
    options: { fetchPolicy: 'cache-first' },
  })
  const ProductLazyQuery = useDebouncedItemQuery<
    GetPicosProductsQuery,
    GetPicosProductsQueryVariables
  >({
    queryStr: GET_PICOS_PRODUCTS,
    options: { fetchPolicy: 'cache-first' },
  })
  const categoryQueryResults =
    CategoryLazyQuery.lazyQuery.meta.data?.companyProductCategories || []
  const brandQueryResults: SelectableOption[] =
    BrandLazyQuery?.lazyQuery?.meta?.data?.picosBrands?.results?.map(pic => ({
      display: pic.name,
      value: pic.id,
    })) || []
  const packagingQueryResults: SelectableOption[] =
    PackagingLazyQuery.lazyQuery.meta.data?.picosPackaging.results.map(
      pack => ({
        display: pack.packagingString,
        value: pack.id,
      }),
    ) || []
  const productQueryResults =
    ProductLazyQuery.lazyQuery.meta.data?.picosProducts.results.map(prod => ({
      display: prod.name,
      value: prod.id,
    })) || []

  const categoryIdsInput = priorityToEdit.categories.map(v => v.value)
  const brandIdsInput = priorityToEdit.brands.map(b => b.value)
  const packagingIdsInput = priorityToEdit.packaging.map(p => p.value)

  const { itemsCache: brandsCache } = useInfiniteScrollAndSearch({
    LazyQuery: BrandLazyQuery,
    QueryVariables: {
      input: {
        name: BrandLazyQuery.debouncedQuery,
        categoryIds: categoryIdsInput,
        offset: BrandLazyQuery.Pagination.offset,
      },
    },
    queryResponse: brandQueryResults,
    resetQueryDependencies: [priorityToEdit.categories],
  })
  const { itemsCache: packagingCache } = useInfiniteScrollAndSearch({
    LazyQuery: PackagingLazyQuery,
    QueryVariables: {
      input: {
        categoryIds: categoryIdsInput,
        brandIds: brandIdsInput,
        container: PackagingLazyQuery.debouncedQuery,
        offset: PackagingLazyQuery.Pagination.offset,
      },
    },
    queryResponse: packagingQueryResults,
    resetQueryDependencies: [priorityToEdit.categories, priorityToEdit.brands],
  })
  const { itemsCache: productCache } = useInfiniteScrollAndSearch({
    LazyQuery: ProductLazyQuery,
    QueryVariables: {
      input: {
        categoryIds: categoryIdsInput,
        brandIds: brandIdsInput,
        packagingIds: packagingIdsInput,
        name: ProductLazyQuery.debouncedQuery,
        offset: ProductLazyQuery.Pagination.offset,
      },
    },
    queryResponse: productQueryResults,
    resetQueryDependencies: [
      priorityToEdit.categories,
      priorityToEdit.brands,
      priorityToEdit.packaging,
    ],
  })

  const picosProductForm: PicosProductsForm = {
    searchSelect: {
      CATEGORY: {
        ...sharedSearchSelectProps,
        value: priorityToEdit.categories.map(pri => pri.value),
        options: getCategoryResults(
          categoryQueryResults,
          CategoryLazyQuery.debouncedQuery.toLowerCase(),
          priorityToEdit.categories,
        ),
        label: 'Category',
        setSearchText: CategoryLazyQuery.setItemQuery,
        loading: CategoryLazyQuery.lazyQuery.meta.loading,
        onChange: categories =>
          setPriorityToEdit({ ...priorityToEdit, categories }),
        Footer: (
          <OptionsCheckBox
            checked={othersAllowed.categories}
            onChange={() => handleToggleAllowed('categories')}
            label="Allow other categories"
            tooltip={helpMsg('categories')}
          />
        ),
        minWidth: 300,
      },
      BRAND: {
        ...sharedSearchSelectProps,
        value: priorityToEdit.brands.map(pri => pri.value),
        options: uniqBy(
          [...brandsCache, ...(priorityToEdit.brands || [])],
          'value',
        ),
        label: 'Brand',
        setSearchText: BrandLazyQuery.setItemQuery,
        loading: BrandLazyQuery.lazyQuery.meta.loading,
        onChange: brands => setPriorityToEdit({ ...priorityToEdit, brands }),
        help: 'Select one or more categories to load brands.',
        fetchMore: BrandLazyQuery.Pagination.handleOffset.nextPage,
        hasMore: BrandLazyQuery.lazyQuery.meta.data?.picosBrands.hasMore,
        Footer: (
          <OptionsCheckBox
            checked={othersAllowed.brands}
            onChange={() => handleToggleAllowed('brands')}
            label="Allow other brands"
            tooltip={helpMsg('brands')}
          />
        ),
        minWidth: 300,
      },
      PACKAGING: {
        ...sharedSearchSelectProps,
        value: priorityToEdit.packaging.map(pri => pri.value),
        options: uniqBy(
          [...packagingCache, ...(priorityToEdit.packaging || [])],
          'value',
        ),
        hasMore: PackagingLazyQuery.lazyQuery.meta.data?.picosPackaging.hasMore,
        fetchMore: PackagingLazyQuery.Pagination.handleOffset.nextPage,
        onChange: packaging =>
          setPriorityToEdit({ ...priorityToEdit, packaging }),
        required: true,
        label: 'Packaging',
        setSearchText: PackagingLazyQuery.setItemQuery,
        loading: PackagingLazyQuery.lazyQuery.meta.loading,
        help:
          'Select one or more categories and brands to load packaging types.',
        Footer: (
          <OptionsCheckBox
            checked={othersAllowed.packaging}
            onChange={() => handleToggleAllowed('packaging')}
            label="Allow other packaging"
            tooltip={helpMsg('packaging')}
          />
        ),
        minWidth: 300,
      },
      PRODUCT: {
        ...sharedSearchSelectProps,
        value: priorityToEdit.products?.map(pri => pri.value) || [],
        options: uniqBy(
          [...productCache, ...(priorityToEdit.products || [])],
          'value',
        ),
        label: 'Product',
        required: false,
        setSearchText: ProductLazyQuery.setItemQuery,
        loading: ProductLazyQuery.lazyQuery.meta.loading,
        onChange: products =>
          setPriorityToEdit({ ...priorityToEdit, products }),
        fetchMore: ProductLazyQuery.Pagination.handleOffset.nextPage,
        hasMore: ProductLazyQuery.lazyQuery.meta.data?.picosProducts.hasMore,
        Footer: (
          <OptionsCheckBox
            checked={othersAllowed.products}
            onChange={() => handleToggleAllowed('products')}
            label="Allow other product"
            tooltip={helpMsg('products')}
          />
        ),
        minWidth: 300,
      },
    },
    text: {
      PRICE: {
        input: {
          type: 'text',
          variant: 'standard',
          label: 'Price',
          placeholder:
            'ex. BOGO Free, Buy Two Get the Third 50% off, Promo Price: $2.89',
        },
        gridProps: gridAllScreens(12),
        value: priorityToEdit.pricing || '',
        onChange: pricing => setPriorityToEdit({ ...priorityToEdit, pricing }),
      },
    },
  }

  const handleToggleAllowed = (variant: OthersAllowedType) => {
    setOthersAllowed((prevState: Record<OthersAllowedType, boolean>) => ({
      ...prevState,
      [variant]: !prevState[variant],
    }))
  }

  const { data } = useMuiForm<PicosProductsForm>({
    formState: picosProductForm,
  })

  return {
    state: {
      formState: picosProductForm,
      othersAllowed,
    },
    data,
  }
}
