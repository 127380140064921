/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useEffect, useState } from 'react'
import {
  gridAllScreens,
  ProductFormattingHelpers,
  StringHelpers,
  SelectableOption,
  useProductSearch,
  CreateNewPopover,
  NotificationContext,
  DAIFormState,
  MuiFormTypes,
} from '@dai/web-components'
import {
  SABRE_CREATE_MANUFACTURER,
  SabreCreateManufacturerMutation,
  SabreCreateManufacturerMutationVariables,
  SABRE_CREATE_PRODUCT_FLAVOR,
  SabreCreateFlavorMutation,
  SabreCreateFlavorMutationVariables,
  SABRE_CREATE_BRAND,
  SabreCreateBrandMutation,
  SabreCreateBrandMutationVariables,
  SabreProductFragment,
  SabrePackagingUnits,
  SabrePackagingType,
} from '@dai/graphql'
import { Edit, EditOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import { useMutation } from '@apollo/client'
import { uniqBy } from 'lodash'

interface ProductForm extends DAIFormState<'searchSelect' | 'select' | 'text'> {
  text: {
    NAME: MuiFormTypes['text']
    UPC: MuiFormTypes['text']
    SOURCE: MuiFormTypes['text']
    SIZE: MuiFormTypes['text']
    COUNT: MuiFormTypes['text']
  }
  select: {
    UNITS: MuiFormTypes['select']
    CONTAINER: MuiFormTypes['select']
    // CATEGORY: MuiFormTypes['select']
  }
  searchSelect: {
    BRAND: MuiFormTypes['searchSelect']
    MANUFACTURER: MuiFormTypes['searchSelect']
    FLAVORS: MuiFormTypes['searchSelect']
  }
}

type EditProductFormProps = {
  product: Omit<SabreProductFragment, 'marketingImageUrl'> | undefined
  handleNameChange: (name: string) => void
  handleSizeChange: (size: string) => void
  handleCountChange: (count: string) => void
  handleUnitsChange: (units: SelectableOption[]) => void
  handleContainerChange: (container: SelectableOption[]) => void
  // handleCategoryChange: (category: SelectableOption[]) => void
  handleBrandChange: (brand: SelectableOption[]) => void
  handleManufacturerChange: (manufacturer: SelectableOption[]) => void
  handleFlavorsChange: (flavors: SelectableOption[]) => void
}

export const useEditProductForm = ({
  product,
  handleBrandChange,
  handleSizeChange,
  // handleCategoryChange,
  handleCountChange,
  handleContainerChange,
  handleFlavorsChange,
  handleManufacturerChange,
  handleNameChange,
  handleUnitsChange,
}: EditProductFormProps) => {
  const [editNameReadOnly, setEditNameReadOnly] = useState<boolean>(true)
  // TODO: we need to paginate some of these queries, i.e. Brand...
  //  that way we can enable infinite scrolling and improve the user's experience
  //  searching for brands is really slow right now.
  const {
    BrandLazyQuery: BLQ,
    ProductCategoriesLazyQuery: PCLQ,
    ManufacturerLazyQuery: MLQ,
    ProductFlavorsLazyQuery: PFLQ,
  } = useProductSearch()
  const notificationState = useContext(NotificationContext)

  const [uploadNewBrand] = useMutation<
    SabreCreateBrandMutation,
    SabreCreateBrandMutationVariables
  >(SABRE_CREATE_BRAND, { context: { endPoint: 'sabre' } })
  const [uploadNewFlavor] = useMutation<
    SabreCreateFlavorMutation,
    SabreCreateFlavorMutationVariables
  >(SABRE_CREATE_PRODUCT_FLAVOR, { context: { endPoint: 'sabre' } })
  const [uploadNewManufacturer] = useMutation<
    SabreCreateManufacturerMutation,
    SabreCreateManufacturerMutationVariables
  >(SABRE_CREATE_MANUFACTURER, { context: { endPoint: 'sabre' } })

  useEffect(() => {
    if (!StringHelpers.isNullOrEmpty(BLQ.debouncedQuery)) {
      BLQ.lazyQuery.query({
        variables: { input: { brandName: BLQ.debouncedQuery } },
      })
    }
  }, [BLQ.debouncedQuery, BLQ.Pagination.offset, BLQ.lazyQuery.query])

  useEffect(() => {
    PCLQ.lazyQuery.query({
      variables: { companyId: null },
    })
  }, [PCLQ.debouncedQuery, PCLQ.Pagination.offset, PCLQ.lazyQuery.query])

  useEffect(() => {
    if (!StringHelpers.isNullOrEmpty(MLQ.debouncedQuery)) {
      MLQ.lazyQuery.query({
        variables: { input: { searchTerm: MLQ.debouncedQuery } },
      })
    }
  }, [MLQ.debouncedQuery, MLQ.Pagination.offset, MLQ.lazyQuery.query])

  useEffect(() => {
    if (!StringHelpers.isNullOrEmpty(PFLQ.debouncedQuery)) {
      PFLQ.lazyQuery.query({
        variables: { input: { searchTerm: PFLQ.debouncedQuery } },
      })
    }
  }, [PFLQ.debouncedQuery, PFLQ.Pagination.offset, PFLQ.lazyQuery.query])

  const brands: SelectableOption[] =
    BLQ.lazyQuery.meta.data?.brands?.results.map(brand => ({
      display: brand.name,
      value: brand.id,
    })) || []
  const manufacturers: SelectableOption[] =
    MLQ.lazyQuery.meta.data?.manufacturers?.results.map(man => ({
      display: man.name,
      value: man.id,
    })) || []
  const flavors: SelectableOption[] =
    PFLQ.lazyQuery.meta.data?.flavors?.results?.map(fl => ({
      display: fl.name,
      value: fl.name,
    })) || []

  const packagingOptions = Object.keys(SabrePackagingUnits).map(packaging => ({
    display: `${StringHelpers.stringValue(
      packaging,
    )} (${ProductFormattingHelpers.formatEnumUnits(packaging)})`,
    value: packaging,
  }))
  const containerOptions = Object.keys(SabrePackagingType).map(packaging => ({
    display: StringHelpers.stringValue(packaging),
    value: StringHelpers.stringValue(packaging).toUpperCase(),
  }))
  // const companyCategoryOptions =
  //   PCLQ.lazyQuery.meta.data?.companyProductCategories.map(c => ({
  //     display: c.category,
  //     value: c.id,
  //   })) || []
  const brandOptions = product?.brand?.name
    ? [{ display: product.brand.name, value: product.brand.id }, ...brands]
    : brands
  const manufacturerOptions = product?.manufacturer
    ? [
        {
          display: product.manufacturer.name,
          value: product.manufacturer.name,
        },
        ...manufacturers,
      ]
    : manufacturers

  const flavorOptions = product?.flavors
    ? [
        ...product.flavors.map(fl => ({
          display: fl.name,
          value: fl.name,
        })),
        ...flavors,
      ]
    : flavors
  const EDIT_PRODUCT_FORM: ProductForm = {
    text: {
      NAME: {
        onChange: handleNameChange,
        input: {
          required: true,
          type: 'text',
          variant: 'standard',
          label: 'Name',
          InputProps: {
            readOnly: editNameReadOnly,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setEditNameReadOnly(!editNameReadOnly)}
                >
                  {editNameReadOnly ? <Edit /> : <EditOutlined />}
                </IconButton>
              </InputAdornment>
            ),
          },
        },
        help: editNameReadOnly
          ? 'Modifying the product name is locked. Click the edit button to unlock.'
          : '',
        gridProps: gridAllScreens(12),
        value: product?.name || '',
      },
      UPC: {
        input: {
          required: true,
          type: 'text',
          variant: 'standard',
          label: 'UPC',
          InputProps: {
            readOnly: true,
          },
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value: product?.upcA || '',
      },
      SOURCE: {
        input: {
          type: 'text',
          variant: 'standard',
          label: 'Source',
          InputProps: {
            readOnly: true,
          },
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value:
          product && product.source
            ? StringHelpers.stringValue(product.source)
            : '',
      },
      SIZE: {
        onChange: handleSizeChange,
        validationFn: (value: string) => {
          if (!StringHelpers.isNullOrEmpty(value)) {
            const num = Number(value)
            return {
              error: num <= 0,
              help: num <= 0 ? 'Size must be >= 1' : undefined,
            }
          }
          return {
            error: false,
            help: 'The fluid size of a single unit (i.e 2 in 2L; 12 in 12oz)',
          }
        },
        help: 'The fluid size of a single unit (i.e 2 in 2L; 12 in 12oz)',
        input: {
          type: 'number',
          variant: 'filled',
          label: 'Size',
          required: true,
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value: product?.packaging?.size || '',
      },
      COUNT: {
        onChange: handleCountChange,
        validationFn: (value: string) => {
          if (!StringHelpers.isNullOrEmpty(value)) {
            const num = Number(value)
            return {
              error: num <= 0,
              help: num <= 0 ? 'Count must be >= 1' : undefined,
            }
          }
          return {
            error: false,
            help:
              'Number of units in the product packaging (i.e 12 in a 12-pk)',
          }
        },
        help: 'Number of units in the product packaging (i.e 12 in a 12-pk)',
        input: {
          type: 'number',
          variant: 'filled',
          label: 'Count',
          required: true,
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value: product?.packaging?.quantity || '',
      },
    },
    select: {
      UNITS: {
        onChange: handleUnitsChange,
        help: 'Packaging units (i.e OZ or mL)',
        variant: 'filled',
        label: 'Units',
        options: packagingOptions,
        input: {
          required: true,
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value: product?.packaging?.unit || '',
      },
      CONTAINER: {
        onChange: handleContainerChange,
        help: 'Packaging container type (i.e Can or Bottle)',
        variant: 'filled',
        label: 'Container',
        options: containerOptions,
        input: {
          required: true,
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value: product?.packaging?.container || '',
      },
      // CATEGORY: {
      //   onChange: handleCategoryChange,
      //   help: 'Swire Company Category',
      //   variant: 'filled',
      //   label: 'Category',
      //   error: PCLQ.lazyQuery.meta.data === undefined,
      //   options: companyCategoryOptions,
      //   input: {
      //     required: true,
      //   },
      //   gridProps: { ...gridAllScreens(6), xs: 12 },
      //   value: product?.category?.id || '',
      // },
    },
    searchSelect: {
      BRAND: {
        onChange: handleBrandChange,
        label: 'Brand',
        gridProps: { ...gridAllScreens(6), xs: 12 },
        variant: 'filled',
        value: product?.brand?.id || '',
        options: uniqBy(brandOptions, 'value'),
        setSearchText: BLQ.setItemQuery,
        loading: BLQ.lazyQuery.meta.loading,
        required: true,
        Footer: (
          <CreateNewPopover
            buttonLabel="Create Brand"
            inputLabel="Brand Name"
            onSave={brandName =>
              uploadNewBrand({ variables: { input: { name: brandName } } })
                .then(res =>
                  notificationState.setSuccess(
                    `Successfully create new brand: "${res?.data?.createBrand?.brand.name}"`,
                  ),
                )
                .catch(err =>
                  notificationState.setError(
                    `Failed to create new brand: ${err}`,
                  ),
                )
            }
          />
        ),
      },
      MANUFACTURER: {
        onChange: handleManufacturerChange,
        label: 'Manufacturer',
        gridProps: { ...gridAllScreens(6), xs: 12 },
        variant: 'filled',
        value: product?.manufacturer?.name || 'Other',
        options: uniqBy(manufacturerOptions, 'value'),
        setSearchText: MLQ.setItemQuery,
        loading: MLQ.lazyQuery.meta.loading,
        required: true,
        Footer: (
          <CreateNewPopover
            buttonLabel="Create Manufacturer"
            inputLabel="Manufacturer Name"
            onSave={manName =>
              uploadNewManufacturer({
                variables: { input: { name: manName } },
              })
                .then(res =>
                  notificationState.setSuccess(
                    `Successfully create new manufacturer: "${res?.data?.createManufacturer?.manufacturer.name}"`,
                  ),
                )
                .catch(err =>
                  notificationState.setError(
                    `Failed to create new manufacturer: ${err}`,
                  ),
                )
            }
          />
        ),
      },
      FLAVORS: {
        onChange: handleFlavorsChange,
        label: 'Flavors',
        gridProps: { ...gridAllScreens(6), xs: 12 },
        variant: 'filled',
        value: product?.flavors.map(fl => fl.name) || [],
        options: uniqBy(flavorOptions, 'value'),
        setSearchText: PFLQ.setItemQuery,
        loading: PFLQ.lazyQuery.meta.loading,
        multiple: true,
        required: true,
        Footer: (
          <CreateNewPopover
            buttonLabel="Create Product Flavor"
            inputLabel="Product Flavor"
            onSave={flavor =>
              uploadNewFlavor({ variables: { input: { name: flavor } } })
                .then(res =>
                  notificationState.setSuccess(
                    `Successfully created new product flavor: "${res?.data?.createFlavor?.flavor.name}"`,
                  ),
                )
                .catch(err =>
                  notificationState.setError(
                    `Failed to create new product flavor: ${err}`,
                  ),
                )
            }
          />
        ),
      },
    },
  }

  return {
    formState: EDIT_PRODUCT_FORM,
  }
}
