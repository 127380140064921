"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_POINT_CLOUD = void 0;
var client_1 = require("@apollo/client");
exports.GET_POINT_CLOUD = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetPointCloud($pointCloudId: ID!) {\n    pointCloud(pointCloudId: $pointCloudId) {\n      id\n      verified\n      verifiedLocation\n      bucketFileName\n      labels\n      predicted\n      centroids\n      boundingCube {\n        x1 {\n          x\n          y\n          z\n        }\n        x2 {\n          x\n          y\n          z\n        }\n        y1 {\n          x\n          y\n          z\n        }\n        z1 {\n          x\n          y\n          z\n        }\n        width\n        height\n        depth\n      }\n      ... on DisplayAuditPointCloud {\n        keyframes {\n          id\n          bucketFileName\n          url\n          created\n        }\n      }\n      ... on MerchandiserPointCloud {\n        keyframes {\n          id\n          bucketFileName\n          url\n          created\n        }\n      }\n    }\n  }\n"], ["\n  query GetPointCloud($pointCloudId: ID!) {\n    pointCloud(pointCloudId: $pointCloudId) {\n      id\n      verified\n      verifiedLocation\n      bucketFileName\n      labels\n      predicted\n      centroids\n      boundingCube {\n        x1 {\n          x\n          y\n          z\n        }\n        x2 {\n          x\n          y\n          z\n        }\n        y1 {\n          x\n          y\n          z\n        }\n        z1 {\n          x\n          y\n          z\n        }\n        width\n        height\n        depth\n      }\n      ... on DisplayAuditPointCloud {\n        keyframes {\n          id\n          bucketFileName\n          url\n          created\n        }\n      }\n      ... on MerchandiserPointCloud {\n        keyframes {\n          id\n          bucketFileName\n          url\n          created\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
