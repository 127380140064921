/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useEffect, useState } from 'react'
import PicOSContext from 'context/PicOSContext'
import { usePicosPriorityForm } from 'picos/hooks/form/use-picos-priority.form'
import { usePicosStoreGroupForm } from 'picos/hooks/form/use-picos-store-group.form'
import { usePicosDisplayInfoForm } from 'picos/hooks/form/use-picos-display-info.form'
import { usePicosProductsForm } from 'picos/hooks/form/use-picos-products.form'
import { DZFile } from '@dai/common'
import { useLazyQuery, useMutation, useApolloClient } from '@apollo/client'
import {
  CREATE_PICOS_PRIORITY,
  CreatePicosPriorityMutation as CPP,
  CreatePicosPriorityMutationVariables,
  UPDATE_PICOS_PRIORITY,
  UpdatePicOSPriorityMutation,
  UpdatePicOSPriorityMutationVariables,
  StoreTypesQuery,
  StoreTypesQueryVariables,
  GET_STORE_TYPES,
  GET_VALID_IMAGE_FORMATS,
  StoreCategory,
} from '@dai/graphql'
import { useHistory } from 'react-router-dom'
import {
  useStepper,
  NotificationContext,
  SelectableOption,
} from '@dai/web-components'
import { FormPicOSPriority } from 'picos/picos.types'
import { debounce } from 'lodash'
import { PicosConstants } from 'picos/picos.constants'
import { uniqueId } from '@dai/common'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { CachedPicosPriority, PicOSHelpers } from '../../helpers/picos.helpers'
import { imageStorage } from '../../../../api/Firebase'

const debouncedWriteToLocalStorage = debounce(
  (
    priorityToEdit: FormPicOSPriority,
    selectedBanner: SelectableOption,
    picosId: string,
  ) =>
    PicOSHelpers.savePicosLocalStorage(
      priorityToEdit,
      selectedBanner.display,
      picosId,
    ),
  500,
)

const steps = {
  picosPriority: { name: 'PicOS Priority', indexNum: 0 },
  displayInfo: { name: 'Display Info', indexNum: 1 },
  products: { name: 'Products', indexNum: 2 },
  picosImage: { name: 'PicOS Image', indexNum: 3 },
}

export const useCreatePicOSPriorityLogic = (picosId: string) => {
  const {
    state: { activeStep },
    handle: { handleStep },
  } = useStepper(Object.values(steps).map(step => step.name))
  const {
    selectedBanner,
    setPriorityToEdit,
    priorityToEdit,
    isNewPriority,
    setIsNewPriority,
    setStoreTypesInCustomer,
  } = useContext(PicOSContext)

  const PicOSPriorityForm = usePicosPriorityForm()
  const SGF = usePicosStoreGroupForm()
  const DisplayInfoForm = usePicosDisplayInfoForm()
  const PicOSProductForm = usePicosProductsForm()
  const [imageFile, setImageFile] = useState<DZFile>()

  const [draftPicos, setDraftPicos] = useState<CachedPicosPriority[]>([])
  const [incompleteOrLoading, setIncompleteOrLoading] = useState<boolean>(true)
  const [allowedFileFormats, setAllowedFileFormats] = useState<string[]>([])

  const [getImageFormats] = useLazyQuery(GET_VALID_IMAGE_FORMATS, {
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      setAllowedFileFormats([...data.validImageFormat])
    },
  })

  useEffect(() => {
    getImageFormats()
  }, [])

  const savePicosImage = () => {
    if (imageFile) {
      const extension = imageFile.path.slice(
        imageFile.path.lastIndexOf('.') + 1,
      )
      const id = uniqueId()
      const uploadFilePath = `picos/${id}.${extension}`
      const storageRef = ref(imageStorage, uploadFilePath)
      const uploadTask = uploadBytesResumable(storageRef, imageFile)
      uploadTask.on('state_changed', {
        error: error => setError(`Image upload was unsuccessful.\n${error}`),
      })
      return { bucketFile: uploadFilePath, imageUuid: id }
    }
    return { bucketFile: '', imageUuid: '' }
  }

  useEffect(() => {
    if (isNewPriority) {
      if (selectedBanner?.display) {
        if (priorityToEdit.name && priorityToEdit.description) {
          debouncedWriteToLocalStorage(priorityToEdit, selectedBanner, picosId)
        }
      }
    }
  }, [priorityToEdit])

  useEffect(() => {
    const checkIfPriorityExistsAndSetIt = async () => {
      if (isNewPriority) {
        const picos = await PicOSHelpers.getPicosLocalStorage(picosId)
        if (picos) {
          setPriorityToEdit(picos)
        }
      }
    }
    checkIfPriorityExistsAndSetIt()
  }, [picosId])

  const getDraftsForBanner = async () => {
    if (selectedBanner?.display) {
      const drafts = await PicOSHelpers.getAllPicosPrioritiesFromLocalStorage(
        selectedBanner?.display,
      )
      if (drafts) {
        setDraftPicos(drafts)
      }
    }
  }

  useEffect(() => {
    getDraftsForBanner()
  }, [selectedBanner?.display])

  const { setSuccess, setError } = useContext(NotificationContext)
  const history = useHistory()

  const [createPicosMutation, { loading: createPicOSLoading }] = useMutation<
    CPP,
    CreatePicosPriorityMutationVariables
  >(CREATE_PICOS_PRIORITY)

  const [updatePicosMutation, { loading: updatePicOSLoading }] = useMutation<
    UpdatePicOSPriorityMutation,
    UpdatePicOSPriorityMutationVariables
  >(UPDATE_PICOS_PRIORITY)

  const [getStoreTypes] = useLazyQuery<
    StoreTypesQuery,
    StoreTypesQueryVariables
  >(GET_STORE_TYPES, {
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      setStoreTypesInCustomer(data.storeTypes as StoreCategory[])
    },
  })

  const client = useApolloClient()

  useEffect(() => {
    if (PicOSPriorityForm.state.formState?.radio.STORES.value === 'true') {
      getStoreTypes({
        variables: {
          input: {
            bannerId: selectedBanner?.value,
          },
        },
      })
    } else if (
      PicOSPriorityForm.state.formState?.radio.STORES.value === 'false'
    ) {
      if (Array.isArray(SGF.state.formState?.searchSelect.GROUPS.value)) {
        getStoreTypes({
          variables: {
            input: {
              groupIds: SGF.state.formState?.searchSelect.GROUPS.value,
            },
          },
        })
      }
    }
  }, [priorityToEdit])

  const forms = {
    0: {
      completed:
        PicOSPriorityForm.data.canSubmit &&
        (PicOSPriorityForm.state.formState?.radio.STORES.value === 'true' ||
          SGF.data.canSubmit),
      formState: PicOSPriorityForm.state.formState,
    },
    1: {
      completed: DisplayInfoForm.data.canSubmit,
      formState: DisplayInfoForm.state.formState,
    },
    2: {
      completed: PicOSProductForm.data.canSubmit,
      formState: PicOSProductForm.state.formState,
    },
    3: {
      completed: !!imageFile || !!(priorityToEdit && priorityToEdit.imageUrl),
    },
  }

  const isImageStep = activeStep === 3

  const totalSteps = Object.values(forms).length
  const completedSteps = Object.values(forms).filter(form => form.completed)
    .length
  const activeStepCompleted = Object.values(forms).some(
    (key, idx) => idx === activeStep && key.completed,
  )
  // @ts-ignore
  const orderOfForms = Object.keys(forms[activeStep].formState || {})

  const handleClearForms = () => {
    setPriorityToEdit(PicosConstants.EMPTY_PICOS)
  }

  const handleSubmitUpdatePicOS = () => {
    const {
      bucketFile: newBucketFile,
      imageUuid: newImageUuid,
    } = savePicosImage()
    updatePicosMutation({
      variables: {
        input: {
          picosId: priorityToEdit.id,
          newPriorityName: priorityToEdit.name,
          newPriority: priorityToEdit.priorityNum,
          newStartDate: priorityToEdit.startDate,
          newEndDate: priorityToEdit.endDate,
          newMandateType: priorityToEdit.mandateType,
          newDescription: priorityToEdit.description,
          newCost: priorityToEdit.cost,
          newStoreGroupIds: priorityToEdit.isAllStores
            ? []
            : priorityToEdit.storeGroups.map(s => s.value),
          newDisplayElements: priorityToEdit.displayElements,
          newDisplayTypeIds: priorityToEdit.displayTypes.map(o => o.value),
          newDisplayLocationIds: priorityToEdit.displayLocations.map(
            o => o.value,
          ),
          newSwirePosEleReq: priorityToEdit.swirePosElementRequired,
          newOtherDisplayTypesAllowed: priorityToEdit.otherDisplayTypesAllowed,
          newOtherDisplayLocationsAllowed:
            priorityToEdit.otherDisplayLocationsAllowed,
          newOtherCategories: priorityToEdit.otherCategoriesAllowed,
          newOtherBrands: priorityToEdit.otherBrandsAllowed,
          newOtherPackaging: priorityToEdit.otherPackagingAllowed,
          newOtherProducts: priorityToEdit.otherProductsAllowed,
          newCategoryIds: priorityToEdit.categories.map(c => c.value),
          newBrandIds: priorityToEdit.brands.map(b => b.value),
          newPackagingIds: priorityToEdit.packaging.map(p => p.value),
          newProductIds: priorityToEdit.products?.map(p => p.value),
          newPricingInfo: priorityToEdit.pricing,
          newBucketFilePath: newBucketFile,
          newPicosImageUuid: newImageUuid,
        },
      },
    })
      .then(() => {
        setSuccess('Successfully updated PicOS Priority.')
        history.push(`/picos/edit`)
      })
      .catch(error => setError(error))
  }

  const handleSubmitCreatePicOS = () => {
    const { bucketFile, imageUuid } = savePicosImage()
    if (!selectedBanner) {
      return
    }
    if (
      !priorityToEdit?.displayElements ||
      !priorityToEdit.mandateType ||
      !priorityToEdit.priorityNum
    ) {
      return
    }
    createPicosMutation({
      variables: {
        input: {
          name: priorityToEdit.name,
          priority: priorityToEdit.priorityNum,
          startDate: priorityToEdit.startDate,
          endDate: priorityToEdit.endDate,
          mandateType: priorityToEdit.mandateType,
          description: priorityToEdit.description,
          cost: priorityToEdit.cost,
          storeGroupIds: priorityToEdit.isAllStores
            ? []
            : priorityToEdit.storeGroups.map(s => s.value),
          displayElements: priorityToEdit.displayElements,
          displayTypeIds: priorityToEdit.displayTypes.map(o => o.value),
          displayLocationIds: priorityToEdit.displayLocations.map(o => o.value),
          swirePosEleReq: priorityToEdit.swirePosElementRequired,
          otherDisplayTypesAllowed: priorityToEdit.otherDisplayTypesAllowed,
          otherDisplayLocationsAllowed:
            priorityToEdit.otherDisplayLocationsAllowed,
          otherCategories: priorityToEdit.otherCategoriesAllowed,
          otherBrands: priorityToEdit.otherBrandsAllowed,
          otherPackaging: priorityToEdit.otherPackagingAllowed,
          otherProducts: priorityToEdit.otherProductsAllowed,
          categoryIds: priorityToEdit.categories.map(c => c.value),
          brandIds: priorityToEdit.brands.map(b => b.value),
          packagingIds: priorityToEdit.packaging.map(p => p.value),
          productIds: priorityToEdit.products?.map(p => p.value),
          pricingInfo: priorityToEdit.pricing,
          storeChainId: selectedBanner.value,
          picosImageUuid: imageUuid,
          bucketFilePath: bucketFile,
        },
      },
    })
      .then(() => {
        setSuccess('Successfully created new PicOS Priority.')
        PicOSHelpers.removePicosPriorityFromLocalStorage(
          selectedBanner.display,
          picosId,
        )
        history.push(`/picos/edit`)
      })
      .catch(error => setError(error))
  }

  const handleSubmit = () => {
    if (isNewPriority) {
      handleSubmitCreatePicOS()
    } else {
      handleSubmitUpdatePicOS()
      client.clearStore()
    }
  }

  const handleDeletePicosDraft = (id: string, banner: string) => {
    PicOSHelpers.removePicosPriorityFromLocalStorage(banner, id).then(() => {
      getDraftsForBanner()
    })
  }
  const handleEditPicosDraft = (id: string) => {
    setIsNewPriority(true)
    history.push(`/picos/create/${id}`)
  }

  useEffect(() => {
    setIncompleteOrLoading(
      completedSteps !== totalSteps || createPicOSLoading || updatePicOSLoading,
    )
  }, [completedSteps, totalSteps, createPicOSLoading, updatePicOSLoading])

  return {
    state: {
      activeStep,
      imageFile,
      draftPicos,
      incompleteOrLoading,
      setImageFile,
    },
    data: {
      forms,
      isImageStep,
      totalSteps,
      completedSteps,
      activeStepCompleted,
      orderOfForms,
      steps,
      createPicOSLoading,
      updatePicOSLoading,
      allowedFileFormats,
    },
    forms: {
      PicOSPriorityForm,
      SGF,
      DisplayInfoForm,
      PicOSProductForm,
    },
    handle: {
      handleStep,
      handleSubmit,
      handleClearForms,
      handleDeletePicosDraft,
      handleEditPicosDraft,
    },
  }
}
