"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MLModelType = exports.LocalVersionColumns = exports.LocalShippingColumns = exports.LocalProductFlavorRelationColumns = exports.LocalProductColumns = exports.LocalPackagingColumns = exports.LocalFlavorColumns = exports.LocalCompanyCategoryColumns = exports.IssueType = exports.InventoryTransactionMemo = exports.InventoryProductInventoryTransactionMemoChoices = exports.ImageType = exports.GroupTypes = exports.ForStoreSize = exports.FlaggedType = exports.FlaggedLevel = exports.FlagModelType = exports.FlagAppType = exports.ErrorLevel = exports.DoorHandlePlacement = exports.DisplaysDisplayAreaOldChoices = exports.DisplaysAssociatedStoreTypesCategoryChoices = exports.DisplayTypeAppRule = exports.DisplayTaskType = exports.DisplaySalesVolume = exports.DisplayElements = exports.DisplayActionType = exports.DeviceType = exports.DateRangeType = exports.DAIGroupEnum = exports.CompliancePlanogramTypeChoices = exports.CompliancePlanogramProductOrientationChoices = exports.ComplianceIssue = exports.CompanyUsersSortBy = exports.CompanyStoreSortBy = exports.CompanyStoreFilterOptions = exports.CommonStatusStatusTypeChoices = exports.CommonStatusStatusChoices = exports.CommonFlaggedReasonTypeChoices = exports.CommonFlaggedReasonOptionTypeChoices = exports.BadgeType = exports.BadgeGraphicType = exports.AuditsOrderBy = exports.AuditsAuditQuestionCategoryChoices = exports.AssignOrCreatedUserTask = exports.AppMLModelType = exports.AiMLModelModelTypeChoices = exports.AggregationMethod = exports.AggregateBy = exports.AdminPortalAuditsOrderBy = void 0;
exports.ZoneTier = exports.VerifiedLocation = exports.VerificationDecision = exports.UsersFCMTokenDeviceTypeChoices = exports.UserProductivityModelOptions = exports.UploadStatus = exports.StoresStoreCategoryChoices = exports.StoreTaskSortBy = exports.StoreTaskFilterOptions = exports.StoreOrderBy = exports.StoreFilterOut = exports.StoreFilter = exports.StoreDisplaysSortBy = exports.StoreDisplaysFilterOptions = exports.StoreCategory = exports.StoreActionType = exports.SortIssueDirection = exports.SortIssueBy = exports.SortByDirection = exports.SortBy = exports.SimpleUserProductivityModelOptions = exports.SceneTypeEnum = exports.ScanType = exports.ScanPurpose = exports.RestockAdjustmentType = exports.ProductsProductSourceChoices = exports.ProductsPackagingUnitChoices = exports.ProductsPackagingContainerChoices = exports.ProductTypeEnum = exports.ProductSortBy = exports.PlanogramStatus = exports.PlanogramProductOrientation = exports.Period = exports.PerformanceReportFilter = exports.PackagingUnits = exports.PackagingType = exports.OrderByDirection = exports.MerchandisingState = exports.MerchandisingAction = exports.MerchandiserScanType = exports.MapsStoreAreaDefaultForStoreSizeChoices = exports.MandateTypes = exports.MLPurpose = void 0;
var AdminPortalAuditsOrderBy;
(function (AdminPortalAuditsOrderBy) {
    AdminPortalAuditsOrderBy["DATE"] = "DATE";
    AdminPortalAuditsOrderBy["EMPLOYEE"] = "EMPLOYEE";
    AdminPortalAuditsOrderBy["SCORE"] = "SCORE";
    AdminPortalAuditsOrderBy["STORE"] = "STORE";
})(AdminPortalAuditsOrderBy = exports.AdminPortalAuditsOrderBy || (exports.AdminPortalAuditsOrderBy = {}));
/** An enumeration. */
var AggregateBy;
(function (AggregateBy) {
    AggregateBy["ALL_TIME"] = "ALL_TIME";
    AggregateBy["DAY"] = "DAY";
    AggregateBy["MONTH"] = "MONTH";
    AggregateBy["QUARTER"] = "QUARTER";
    AggregateBy["WEEK"] = "WEEK";
    AggregateBy["YEAR"] = "YEAR";
})(AggregateBy = exports.AggregateBy || (exports.AggregateBy = {}));
/** An enumeration. */
var AggregationMethod;
(function (AggregationMethod) {
    AggregationMethod["AVG"] = "AVG";
    AggregationMethod["COUNT"] = "COUNT";
    AggregationMethod["MEDIAN"] = "MEDIAN";
    AggregationMethod["SUM"] = "SUM";
})(AggregationMethod = exports.AggregationMethod || (exports.AggregationMethod = {}));
/** An enumeration. */
var AiMLModelModelTypeChoices;
(function (AiMLModelModelTypeChoices) {
    /** Bev */
    AiMLModelModelTypeChoices["BEV"] = "BEV";
    /** Binary */
    AiMLModelModelTypeChoices["BINARY"] = "BINARY";
    /** DaiZero */
    AiMLModelModelTypeChoices["DAI_ZERO"] = "DAI_ZERO";
    /** DensityNeonet */
    AiMLModelModelTypeChoices["DENSITY_NEONET"] = "DENSITY_NEONET";
    /** Meta */
    AiMLModelModelTypeChoices["META"] = "META";
    /** Neonet */
    AiMLModelModelTypeChoices["NEONET"] = "NEONET";
    /** PointBeamScorer */
    AiMLModelModelTypeChoices["POINT_BEAM_SCORER"] = "POINT_BEAM_SCORER";
})(AiMLModelModelTypeChoices = exports.AiMLModelModelTypeChoices || (exports.AiMLModelModelTypeChoices = {}));
/**
 * To simplify the frontend, we'll just expose the main model types.
 * The backend will serve up the correct type (Audit/Merch) based on user group
 */
var AppMLModelType;
(function (AppMLModelType) {
    AppMLModelType["BEV"] = "BEV";
    AppMLModelType["BINARY"] = "BINARY";
    AppMLModelType["DAI_ZERO"] = "DAI_ZERO";
    AppMLModelType["DENSITY_NEONET"] = "DENSITY_NEONET";
    AppMLModelType["META"] = "META";
    AppMLModelType["NEONET"] = "NEONET";
    AppMLModelType["POINT_BEAM_SCORER"] = "POINT_BEAM_SCORER";
})(AppMLModelType = exports.AppMLModelType || (exports.AppMLModelType = {}));
/** An enumeration. */
var AssignOrCreatedUserTask;
(function (AssignOrCreatedUserTask) {
    AssignOrCreatedUserTask["ASSIGNED"] = "ASSIGNED";
    AssignOrCreatedUserTask["CREATED"] = "CREATED";
})(AssignOrCreatedUserTask = exports.AssignOrCreatedUserTask || (exports.AssignOrCreatedUserTask = {}));
/** An enumeration. */
var AuditsAuditQuestionCategoryChoices;
(function (AuditsAuditQuestionCategoryChoices) {
    /** all */
    AuditsAuditQuestionCategoryChoices["AL"] = "AL";
    /** small */
    AuditsAuditQuestionCategoryChoices["CR"] = "CR";
    /** drug */
    AuditsAuditQuestionCategoryChoices["DR"] = "DR";
    /** home improvement */
    AuditsAuditQuestionCategoryChoices["HM"] = "HM";
    /** large */
    AuditsAuditQuestionCategoryChoices["LG"] = "LG";
    /** value */
    AuditsAuditQuestionCategoryChoices["VL"] = "VL";
})(AuditsAuditQuestionCategoryChoices = exports.AuditsAuditQuestionCategoryChoices || (exports.AuditsAuditQuestionCategoryChoices = {}));
var AuditsOrderBy;
(function (AuditsOrderBy) {
    AuditsOrderBy["COMPLETED"] = "COMPLETED";
    AuditsOrderBy["DATE"] = "DATE";
    AuditsOrderBy["STORE"] = "STORE";
})(AuditsOrderBy = exports.AuditsOrderBy || (exports.AuditsOrderBy = {}));
/** An enumeration. */
var BadgeGraphicType;
(function (BadgeGraphicType) {
    BadgeGraphicType["LG"] = "LG";
    BadgeGraphicType["LK"] = "LK";
    BadgeGraphicType["SM"] = "SM";
})(BadgeGraphicType = exports.BadgeGraphicType || (exports.BadgeGraphicType = {}));
/** An enumeration. */
var BadgeType;
(function (BadgeType) {
    BadgeType["COMPLIANCE"] = "COMPLIANCE";
    BadgeType["COVERAGE"] = "COVERAGE";
    BadgeType["CROWN"] = "CROWN";
    BadgeType["MILESTONE"] = "MILESTONE";
    BadgeType["TRAINING"] = "TRAINING";
})(BadgeType = exports.BadgeType || (exports.BadgeType = {}));
/** An enumeration. */
var CommonFlaggedReasonOptionTypeChoices;
(function (CommonFlaggedReasonOptionTypeChoices) {
    /** Common */
    CommonFlaggedReasonOptionTypeChoices["COMMON"] = "COMMON";
    /** Image */
    CommonFlaggedReasonOptionTypeChoices["IMAGE"] = "IMAGE";
    /** Planogram Segment */
    CommonFlaggedReasonOptionTypeChoices["PLAN_SEG"] = "PLAN_SEG";
    /** Product */
    CommonFlaggedReasonOptionTypeChoices["PRODUCT"] = "PRODUCT";
    /** Store Map */
    CommonFlaggedReasonOptionTypeChoices["STORE_MAP"] = "STORE_MAP";
})(CommonFlaggedReasonOptionTypeChoices = exports.CommonFlaggedReasonOptionTypeChoices || (exports.CommonFlaggedReasonOptionTypeChoices = {}));
/** An enumeration. */
var CommonFlaggedReasonTypeChoices;
(function (CommonFlaggedReasonTypeChoices) {
    /** Common */
    CommonFlaggedReasonTypeChoices["COMMON"] = "COMMON";
    /** Image */
    CommonFlaggedReasonTypeChoices["IMAGE"] = "IMAGE";
    /** Planogram Segment */
    CommonFlaggedReasonTypeChoices["PLAN_SEG"] = "PLAN_SEG";
    /** Product */
    CommonFlaggedReasonTypeChoices["PRODUCT"] = "PRODUCT";
    /** Store Map */
    CommonFlaggedReasonTypeChoices["STORE_MAP"] = "STORE_MAP";
})(CommonFlaggedReasonTypeChoices = exports.CommonFlaggedReasonTypeChoices || (exports.CommonFlaggedReasonTypeChoices = {}));
/** An enumeration. */
var CommonStatusStatusChoices;
(function (CommonStatusStatusChoices) {
    /** Complete */
    CommonStatusStatusChoices["COMPLETE"] = "COMPLETE";
    /** Failed */
    CommonStatusStatusChoices["FAILED"] = "FAILED";
    /** Pending */
    CommonStatusStatusChoices["PENDING"] = "PENDING";
})(CommonStatusStatusChoices = exports.CommonStatusStatusChoices || (exports.CommonStatusStatusChoices = {}));
/** An enumeration. */
var CommonStatusStatusTypeChoices;
(function (CommonStatusStatusTypeChoices) {
    /** Product Health Score Set */
    CommonStatusStatusTypeChoices["PRODUCT_HEALTH_SCORE_SET"] = "PRODUCT_HEALTH_SCORE_SET";
    /** Upload Stores */
    CommonStatusStatusTypeChoices["UPLOAD_STORES"] = "UPLOAD_STORES";
})(CommonStatusStatusTypeChoices = exports.CommonStatusStatusTypeChoices || (exports.CommonStatusStatusTypeChoices = {}));
var CompanyStoreFilterOptions;
(function (CompanyStoreFilterOptions) {
    CompanyStoreFilterOptions["CONVENIENCE_RETAIL"] = "CONVENIENCE_RETAIL";
    CompanyStoreFilterOptions["DRUG"] = "DRUG";
    CompanyStoreFilterOptions["HOME_IMPROVEMENT"] = "HOME_IMPROVEMENT";
    CompanyStoreFilterOptions["LARGE_STORE"] = "LARGE_STORE";
    CompanyStoreFilterOptions["MY_STORES"] = "MY_STORES";
    CompanyStoreFilterOptions["NEEDS_AUDIT"] = "NEEDS_AUDIT";
    CompanyStoreFilterOptions["NEVER_AUDITED"] = "NEVER_AUDITED";
    CompanyStoreFilterOptions["VALUE"] = "VALUE";
})(CompanyStoreFilterOptions = exports.CompanyStoreFilterOptions || (exports.CompanyStoreFilterOptions = {}));
var CompanyStoreSortBy;
(function (CompanyStoreSortBy) {
    CompanyStoreSortBy["ALPHABETICAL_REVERSE"] = "ALPHABETICAL_REVERSE";
    CompanyStoreSortBy["ALPHABETICAL_STANDARD"] = "ALPHABETICAL_STANDARD";
    CompanyStoreSortBy["AUDITED_NEW_TO_OLD"] = "AUDITED_NEW_TO_OLD";
    CompanyStoreSortBy["AUDITED_OLD_TO_NEW"] = "AUDITED_OLD_TO_NEW";
    CompanyStoreSortBy["LOCATION_CLOSE_TO_FAR"] = "LOCATION_CLOSE_TO_FAR";
    CompanyStoreSortBy["LOCATION_FAR_TO_CLOSE"] = "LOCATION_FAR_TO_CLOSE";
})(CompanyStoreSortBy = exports.CompanyStoreSortBy || (exports.CompanyStoreSortBy = {}));
var CompanyUsersSortBy;
(function (CompanyUsersSortBy) {
    CompanyUsersSortBy["ALPHABETICAL_REVERSE"] = "ALPHABETICAL_REVERSE";
    CompanyUsersSortBy["ALPHABETICAL_STANDARD"] = "ALPHABETICAL_STANDARD";
})(CompanyUsersSortBy = exports.CompanyUsersSortBy || (exports.CompanyUsersSortBy = {}));
/** An enumeration. */
var ComplianceIssue;
(function (ComplianceIssue) {
    ComplianceIssue["EMPTY_SLOTS"] = "EMPTY_SLOTS";
    ComplianceIssue["ENCROACHED"] = "ENCROACHED";
    ComplianceIssue["EXTRA_FACINGS"] = "EXTRA_FACINGS";
    ComplianceIssue["INCORRECT_LOCATION"] = "INCORRECT_LOCATION";
    ComplianceIssue["INCORRECT_ORIENTATION"] = "INCORRECT_ORIENTATION";
    ComplianceIssue["INCORRECT_PRODUCT"] = "INCORRECT_PRODUCT";
    ComplianceIssue["MIXED_FACING"] = "MIXED_FACING";
    ComplianceIssue["OUT_OF_STOCK"] = "OUT_OF_STOCK";
})(ComplianceIssue = exports.ComplianceIssue || (exports.ComplianceIssue = {}));
/** An enumeration. */
var CompliancePlanogramProductOrientationChoices;
(function (CompliancePlanogramProductOrientationChoices) {
    /** Front */
    CompliancePlanogramProductOrientationChoices["FRONT"] = "FRONT";
    /** Side */
    CompliancePlanogramProductOrientationChoices["SIDE"] = "SIDE";
    /** Top */
    CompliancePlanogramProductOrientationChoices["TOP"] = "TOP";
})(CompliancePlanogramProductOrientationChoices = exports.CompliancePlanogramProductOrientationChoices || (exports.CompliancePlanogramProductOrientationChoices = {}));
/** An enumeration. */
var CompliancePlanogramTypeChoices;
(function (CompliancePlanogramTypeChoices) {
    /** Ideal Inventory State */
    CompliancePlanogramTypeChoices["IDEAL_INVENTORY"] = "IDEAL_INVENTORY";
    /** Modular Compliance */
    CompliancePlanogramTypeChoices["MODCOM"] = "MODCOM";
})(CompliancePlanogramTypeChoices = exports.CompliancePlanogramTypeChoices || (exports.CompliancePlanogramTypeChoices = {}));
/** An enumeration. */
var DAIGroupEnum;
(function (DAIGroupEnum) {
    DAIGroupEnum["AUDITORS"] = "AUDITORS";
    DAIGroupEnum["BRAND_AGENTS"] = "BRAND_AGENTS";
    DAIGroupEnum["COMPANY_ADMINS"] = "COMPANY_ADMINS";
    DAIGroupEnum["IMAGE_LABELERS"] = "IMAGE_LABELERS";
    DAIGroupEnum["IMAGE_REVIEWERS"] = "IMAGE_REVIEWERS";
    DAIGroupEnum["LABELERS"] = "LABELERS";
    DAIGroupEnum["MAPPERS"] = "MAPPERS";
    DAIGroupEnum["MAPPER_ADMINS"] = "MAPPER_ADMINS";
    DAIGroupEnum["MERCHANDISERS"] = "MERCHANDISERS";
    DAIGroupEnum["MERCH_MANAGERS"] = "MERCH_MANAGERS";
    DAIGroupEnum["PICOS"] = "PICOS";
    DAIGroupEnum["PLAN_COM"] = "PLAN_COM";
    DAIGroupEnum["PLAN_COM_AUDITOR"] = "PLAN_COM_AUDITOR";
    DAIGroupEnum["POINT_CLOUD_VERIFIERS"] = "POINT_CLOUD_VERIFIERS";
    DAIGroupEnum["QA"] = "QA";
    DAIGroupEnum["RED_ADMINS"] = "RED_ADMINS";
    DAIGroupEnum["RED_MANAGERS"] = "RED_MANAGERS";
    DAIGroupEnum["REPORT_VIEWERS"] = "REPORT_VIEWERS";
    DAIGroupEnum["SALES"] = "SALES";
    DAIGroupEnum["SALES_ADMINS"] = "SALES_ADMINS";
    DAIGroupEnum["SALES_MANAGERS"] = "SALES_MANAGERS";
    DAIGroupEnum["STORE_MAP_SCANNERS"] = "STORE_MAP_SCANNERS";
})(DAIGroupEnum = exports.DAIGroupEnum || (exports.DAIGroupEnum = {}));
var DateRangeType;
(function (DateRangeType) {
    DateRangeType["NOW_VS_PAST"] = "NOW_VS_PAST";
    DateRangeType["YoY"] = "YoY";
})(DateRangeType = exports.DateRangeType || (exports.DateRangeType = {}));
/** An enumeration. */
var DeviceType;
(function (DeviceType) {
    DeviceType["MOBILE"] = "MOBILE";
    DeviceType["TABLET"] = "TABLET";
})(DeviceType = exports.DeviceType || (exports.DeviceType = {}));
/** An enumeration. */
var DisplayActionType;
(function (DisplayActionType) {
    DisplayActionType["CREATED"] = "CREATED";
    DisplayActionType["EDITED"] = "EDITED";
    DisplayActionType["MOVED"] = "MOVED";
    DisplayActionType["RESTOCK"] = "RESTOCK";
    DisplayActionType["RETIRED"] = "RETIRED";
    DisplayActionType["SET_PLAN"] = "SET_PLAN";
    DisplayActionType["THREE_D"] = "THREE_D";
    DisplayActionType["TWO_D"] = "TWO_D";
})(DisplayActionType = exports.DisplayActionType || (exports.DisplayActionType = {}));
/** An enumeration. */
var DisplayElements;
(function (DisplayElements) {
    DisplayElements["AD"] = "AD";
    DisplayElements["SUSTAIN"] = "SUSTAIN";
})(DisplayElements = exports.DisplayElements || (exports.DisplayElements = {}));
/** An enumeration. */
var DisplaySalesVolume;
(function (DisplaySalesVolume) {
    DisplaySalesVolume["HIGH"] = "HIGH";
    DisplaySalesVolume["LOW"] = "LOW";
    DisplaySalesVolume["MEDIUM"] = "MEDIUM";
})(DisplaySalesVolume = exports.DisplaySalesVolume || (exports.DisplaySalesVolume = {}));
/** An enumeration. */
var DisplayTaskType;
(function (DisplayTaskType) {
    DisplayTaskType["ADD_DISPLAY"] = "ADD_DISPLAY";
    DisplayTaskType["MOVE_DISPLAY"] = "MOVE_DISPLAY";
    DisplayTaskType["RESTOCK_DISPLAY"] = "RESTOCK_DISPLAY";
    DisplayTaskType["RETIRE_DISPLAY"] = "RETIRE_DISPLAY";
})(DisplayTaskType = exports.DisplayTaskType || (exports.DisplayTaskType = {}));
/** An enumeration. */
var DisplayTypeAppRule;
(function (DisplayTypeAppRule) {
    DisplayTypeAppRule["CAN_SET_DEPTH"] = "CAN_SET_DEPTH";
    DisplayTypeAppRule["COOLER"] = "COOLER";
    DisplayTypeAppRule["EXCLUDE_PRESET_CUBE"] = "EXCLUDE_PRESET_CUBE";
    DisplayTypeAppRule["HAS_COOLER_DOORS"] = "HAS_COOLER_DOORS";
    DisplayTypeAppRule["HAS_DOOR_HANDLE"] = "HAS_DOOR_HANDLE";
    DisplayTypeAppRule["SLOTTED"] = "SLOTTED";
})(DisplayTypeAppRule = exports.DisplayTypeAppRule || (exports.DisplayTypeAppRule = {}));
/** An enumeration. */
var DisplaysAssociatedStoreTypesCategoryChoices;
(function (DisplaysAssociatedStoreTypesCategoryChoices) {
    /** Convenience Retail */
    DisplaysAssociatedStoreTypesCategoryChoices["CR"] = "CR";
    /** Drug */
    DisplaysAssociatedStoreTypesCategoryChoices["DR"] = "DR";
    /** Home Improvement */
    DisplaysAssociatedStoreTypesCategoryChoices["HM"] = "HM";
    /** Large */
    DisplaysAssociatedStoreTypesCategoryChoices["LG"] = "LG";
    /** Value */
    DisplaysAssociatedStoreTypesCategoryChoices["VL"] = "VL";
})(DisplaysAssociatedStoreTypesCategoryChoices = exports.DisplaysAssociatedStoreTypesCategoryChoices || (exports.DisplaysAssociatedStoreTypesCategoryChoices = {}));
/** An enumeration. */
var DisplaysDisplayAreaOldChoices;
(function (DisplaysDisplayAreaOldChoices) {
    /** Back Alley */
    DisplaysDisplayAreaOldChoices["BA"] = "BA";
    /** Bakery */
    DisplaysDisplayAreaOldChoices["BK"] = "BK";
    /** Deli */
    DisplaysDisplayAreaOldChoices["DL"] = "DL";
    /** Entrance */
    DisplaysDisplayAreaOldChoices["EN"] = "EN";
    /** Lobby */
    DisplaysDisplayAreaOldChoices["LO"] = "LO";
    /** Produce */
    DisplaysDisplayAreaOldChoices["PR"] = "PR";
    /** Secondary Floor */
    DisplaysDisplayAreaOldChoices["SF"] = "SF";
    /** Unknown */
    DisplaysDisplayAreaOldChoices["UN"] = "UN";
})(DisplaysDisplayAreaOldChoices = exports.DisplaysDisplayAreaOldChoices || (exports.DisplaysDisplayAreaOldChoices = {}));
/** An enumeration. */
var DoorHandlePlacement;
(function (DoorHandlePlacement) {
    DoorHandlePlacement["LEFT"] = "LEFT";
    DoorHandlePlacement["MIDDLE"] = "MIDDLE";
    DoorHandlePlacement["RIGHT"] = "RIGHT";
    DoorHandlePlacement["SLIDING"] = "SLIDING";
})(DoorHandlePlacement = exports.DoorHandlePlacement || (exports.DoorHandlePlacement = {}));
/** An enumeration. */
var ErrorLevel;
(function (ErrorLevel) {
    ErrorLevel["ERROR"] = "ERROR";
    ErrorLevel["WARNING"] = "WARNING";
})(ErrorLevel = exports.ErrorLevel || (exports.ErrorLevel = {}));
var FlagAppType;
(function (FlagAppType) {
    FlagAppType["DISPLAYS"] = "DISPLAYS";
    FlagAppType["IMAGES"] = "IMAGES";
    FlagAppType["PRODUCTS"] = "PRODUCTS";
})(FlagAppType = exports.FlagAppType || (exports.FlagAppType = {}));
var FlagModelType;
(function (FlagModelType) {
    FlagModelType["DISPLAY_AUDIT_IMAGE"] = "DISPLAY_AUDIT_IMAGE";
    FlagModelType["DISPLAY_SEGMENT"] = "DISPLAY_SEGMENT";
    FlagModelType["PRODUCT"] = "PRODUCT";
})(FlagModelType = exports.FlagModelType || (exports.FlagModelType = {}));
/** An enumeration. */
var FlaggedLevel;
(function (FlaggedLevel) {
    FlaggedLevel["DELETE"] = "DELETE";
    FlaggedLevel["N_REVIEW"] = "N_REVIEW";
    FlaggedLevel["RED"] = "RED";
    FlaggedLevel["UNFLAG"] = "UNFLAG";
    FlaggedLevel["YELLOW"] = "YELLOW";
})(FlaggedLevel = exports.FlaggedLevel || (exports.FlaggedLevel = {}));
/** An enumeration. */
var FlaggedType;
(function (FlaggedType) {
    FlaggedType["COMMON"] = "COMMON";
    FlaggedType["IMAGE"] = "IMAGE";
    FlaggedType["PLANOGRAM_SEGMENT"] = "PLANOGRAM_SEGMENT";
    FlaggedType["PRODUCT"] = "PRODUCT";
    FlaggedType["STORE_MAP"] = "STORE_MAP";
})(FlaggedType = exports.FlaggedType || (exports.FlaggedType = {}));
/** An enumeration. */
var ForStoreSize;
(function (ForStoreSize) {
    ForStoreSize["ALL"] = "ALL";
    ForStoreSize["DRUG"] = "DRUG";
    ForStoreSize["HOME_IMPROVEMENT"] = "HOME_IMPROVEMENT";
    ForStoreSize["LARGE"] = "LARGE";
    ForStoreSize["SMALL"] = "SMALL";
    ForStoreSize["VALUE"] = "VALUE";
})(ForStoreSize = exports.ForStoreSize || (exports.ForStoreSize = {}));
/** An enumeration. */
var GroupTypes;
(function (GroupTypes) {
    GroupTypes["MERCH"] = "MERCH";
    GroupTypes["PICOS"] = "PICOS";
    GroupTypes["REDAUDIT"] = "REDAUDIT";
})(GroupTypes = exports.GroupTypes || (exports.GroupTypes = {}));
var ImageType;
(function (ImageType) {
    ImageType["AUDIT_ANSWER"] = "AUDIT_ANSWER";
    ImageType["DISPLAY"] = "DISPLAY";
    ImageType["DISPLAY_AUDIT"] = "DISPLAY_AUDIT";
    ImageType["DISPLAY_RESTOCK"] = "DISPLAY_RESTOCK";
    ImageType["EL_DORADO"] = "EL_DORADO";
    ImageType["IMAGE"] = "IMAGE";
    ImageType["MARKETING"] = "MARKETING";
    ImageType["MODCOM_COMPLIANCE"] = "MODCOM_COMPLIANCE";
    ImageType["MULTI_PRODUCT"] = "MULTI_PRODUCT";
    ImageType["PLANOGRAM"] = "PLANOGRAM";
    ImageType["PRODUCT"] = "PRODUCT";
    ImageType["SCAN"] = "SCAN";
    ImageType["STORE"] = "STORE";
    ImageType["TRAINING"] = "TRAINING";
    ImageType["USER"] = "USER";
})(ImageType = exports.ImageType || (exports.ImageType = {}));
/** An enumeration. */
var InventoryProductInventoryTransactionMemoChoices;
(function (InventoryProductInventoryTransactionMemoChoices) {
    /** Adjustment from manual count */
    InventoryProductInventoryTransactionMemoChoices["AD"] = "AD";
    /** Reset Inventory with new counts from 3D Scan */
    InventoryProductInventoryTransactionMemoChoices["PS"] = "PS";
    /** Return of damaged or expired product */
    InventoryProductInventoryTransactionMemoChoices["RE"] = "RE";
    /** Restock of product */
    InventoryProductInventoryTransactionMemoChoices["RS"] = "RS";
    /** Sale */
    InventoryProductInventoryTransactionMemoChoices["SA"] = "SA";
    /** Received shipment from main warehouse */
    InventoryProductInventoryTransactionMemoChoices["SH"] = "SH";
    /** Transfer of product */
    InventoryProductInventoryTransactionMemoChoices["TR"] = "TR";
})(InventoryProductInventoryTransactionMemoChoices = exports.InventoryProductInventoryTransactionMemoChoices || (exports.InventoryProductInventoryTransactionMemoChoices = {}));
/** An enumeration. */
var InventoryTransactionMemo;
(function (InventoryTransactionMemo) {
    InventoryTransactionMemo["ADJUSTMENT"] = "ADJUSTMENT";
    InventoryTransactionMemo["POST_SCAN"] = "POST_SCAN";
    InventoryTransactionMemo["RESTOCK"] = "RESTOCK";
    InventoryTransactionMemo["RETURN"] = "RETURN";
    InventoryTransactionMemo["SALE"] = "SALE";
    InventoryTransactionMemo["SHIPMENT"] = "SHIPMENT";
    InventoryTransactionMemo["TRANSFER"] = "TRANSFER";
})(InventoryTransactionMemo = exports.InventoryTransactionMemo || (exports.InventoryTransactionMemo = {}));
/** An enumeration. */
var IssueType;
(function (IssueType) {
    IssueType["DISPLAY_AUDIT"] = "DISPLAY_AUDIT";
})(IssueType = exports.IssueType || (exports.IssueType = {}));
/** An enumeration. */
var LocalCompanyCategoryColumns;
(function (LocalCompanyCategoryColumns) {
    LocalCompanyCategoryColumns["category"] = "category";
    LocalCompanyCategoryColumns["company_category_id"] = "company_category_id";
    LocalCompanyCategoryColumns["parent_category"] = "parent_category";
})(LocalCompanyCategoryColumns = exports.LocalCompanyCategoryColumns || (exports.LocalCompanyCategoryColumns = {}));
/** An enumeration. */
var LocalFlavorColumns;
(function (LocalFlavorColumns) {
    LocalFlavorColumns["display_flavor"] = "display_flavor";
    LocalFlavorColumns["flavor"] = "flavor";
})(LocalFlavorColumns = exports.LocalFlavorColumns || (exports.LocalFlavorColumns = {}));
/** An enumeration. */
var LocalPackagingColumns;
(function (LocalPackagingColumns) {
    LocalPackagingColumns["container"] = "container";
    LocalPackagingColumns["packaging_id"] = "packaging_id";
    LocalPackagingColumns["quantity"] = "quantity";
    LocalPackagingColumns["size"] = "size";
    LocalPackagingColumns["unit"] = "unit";
})(LocalPackagingColumns = exports.LocalPackagingColumns || (exports.LocalPackagingColumns = {}));
/** An enumeration. */
var LocalProductColumns;
(function (LocalProductColumns) {
    LocalProductColumns["appearances_in_audit"] = "appearances_in_audit";
    LocalProductColumns["brand"] = "brand";
    LocalProductColumns["brand_order"] = "brand_order";
    LocalProductColumns["category_order"] = "category_order";
    LocalProductColumns["common_name"] = "common_name";
    LocalProductColumns["company_category_id"] = "company_category_id";
    LocalProductColumns["geometry_id"] = "geometry_id";
    LocalProductColumns["is_active"] = "is_active";
    LocalProductColumns["manufacturer"] = "manufacturer";
    LocalProductColumns["marketing_image_url"] = "marketing_image_url";
    LocalProductColumns["name"] = "name";
    LocalProductColumns["packaging_id"] = "packaging_id";
    LocalProductColumns["planogram_thumbnail_image_url"] = "planogram_thumbnail_image_url";
    LocalProductColumns["product_id"] = "product_id";
    LocalProductColumns["product_order"] = "product_order";
    LocalProductColumns["shipping_id"] = "shipping_id";
    LocalProductColumns["upc_a"] = "upc_a";
    LocalProductColumns["upc_e"] = "upc_e";
})(LocalProductColumns = exports.LocalProductColumns || (exports.LocalProductColumns = {}));
/** An enumeration. */
var LocalProductFlavorRelationColumns;
(function (LocalProductFlavorRelationColumns) {
    LocalProductFlavorRelationColumns["flavor"] = "flavor";
    LocalProductFlavorRelationColumns["product_id"] = "product_id";
})(LocalProductFlavorRelationColumns = exports.LocalProductFlavorRelationColumns || (exports.LocalProductFlavorRelationColumns = {}));
/** An enumeration. */
var LocalShippingColumns;
(function (LocalShippingColumns) {
    LocalShippingColumns["consumables_per_case"] = "consumables_per_case";
    LocalShippingColumns["products_per_case"] = "products_per_case";
    LocalShippingColumns["shipping_id"] = "shipping_id";
})(LocalShippingColumns = exports.LocalShippingColumns || (exports.LocalShippingColumns = {}));
/** An enumeration. */
var LocalVersionColumns;
(function (LocalVersionColumns) {
    LocalVersionColumns["last_updated"] = "last_updated";
})(LocalVersionColumns = exports.LocalVersionColumns || (exports.LocalVersionColumns = {}));
/** An enumeration. */
var MLModelType;
(function (MLModelType) {
    MLModelType["BEV"] = "BEV";
    MLModelType["BINARY"] = "BINARY";
    MLModelType["DAI_ZERO"] = "DAI_ZERO";
    MLModelType["DENSITY_NEONET"] = "DENSITY_NEONET";
    MLModelType["META"] = "META";
    MLModelType["NEONET"] = "NEONET";
    MLModelType["POINT_BEAM_SCORER"] = "POINT_BEAM_SCORER";
})(MLModelType = exports.MLModelType || (exports.MLModelType = {}));
/** An enumeration. */
var MLPurpose;
(function (MLPurpose) {
    MLPurpose["TRAINING"] = "TRAINING";
    MLPurpose["VALIDATION"] = "VALIDATION";
})(MLPurpose = exports.MLPurpose || (exports.MLPurpose = {}));
/** An enumeration. */
var MandateTypes;
(function (MandateTypes) {
    MandateTypes["HQ"] = "HQ";
    MandateTypes["LOCAL"] = "LOCAL";
})(MandateTypes = exports.MandateTypes || (exports.MandateTypes = {}));
/** An enumeration. */
var MapsStoreAreaDefaultForStoreSizeChoices;
(function (MapsStoreAreaDefaultForStoreSizeChoices) {
    /** all */
    MapsStoreAreaDefaultForStoreSizeChoices["AL"] = "AL";
    /** small */
    MapsStoreAreaDefaultForStoreSizeChoices["CR"] = "CR";
    /** drug */
    MapsStoreAreaDefaultForStoreSizeChoices["DR"] = "DR";
    /** home improvement */
    MapsStoreAreaDefaultForStoreSizeChoices["HM"] = "HM";
    /** large */
    MapsStoreAreaDefaultForStoreSizeChoices["LG"] = "LG";
    /** value */
    MapsStoreAreaDefaultForStoreSizeChoices["VL"] = "VL";
})(MapsStoreAreaDefaultForStoreSizeChoices = exports.MapsStoreAreaDefaultForStoreSizeChoices || (exports.MapsStoreAreaDefaultForStoreSizeChoices = {}));
/** An enumeration. */
var MerchandiserScanType;
(function (MerchandiserScanType) {
    MerchandiserScanType["ADJUSTMENT"] = "ADJUSTMENT";
    MerchandiserScanType["PRESCAN"] = "PRESCAN";
    MerchandiserScanType["RESTOCK"] = "RESTOCK";
})(MerchandiserScanType = exports.MerchandiserScanType || (exports.MerchandiserScanType = {}));
/** An enumeration. */
var MerchandisingAction;
(function (MerchandisingAction) {
    MerchandisingAction["NONE"] = "NONE";
    MerchandisingAction["THREE_D"] = "THREE_D";
    MerchandisingAction["TWO_D"] = "TWO_D";
})(MerchandisingAction = exports.MerchandisingAction || (exports.MerchandisingAction = {}));
/** An enumeration. */
var MerchandisingState;
(function (MerchandisingState) {
    MerchandisingState["EXCLUDED"] = "EXCLUDED";
    MerchandisingState["OPTIMIZED"] = "OPTIMIZED";
    MerchandisingState["OPTIMIZING"] = "OPTIMIZING";
    MerchandisingState["REMOVED"] = "REMOVED";
    MerchandisingState["UNOPTIMIZED"] = "UNOPTIMIZED";
})(MerchandisingState = exports.MerchandisingState || (exports.MerchandisingState = {}));
var OrderByDirection;
(function (OrderByDirection) {
    OrderByDirection["ASCENDING"] = "ASCENDING";
    OrderByDirection["DESCENDING"] = "DESCENDING";
})(OrderByDirection = exports.OrderByDirection || (exports.OrderByDirection = {}));
/** An enumeration. */
var PackagingType;
(function (PackagingType) {
    PackagingType["ALUMINUM_BOTTLE"] = "ALUMINUM_BOTTLE";
    PackagingType["BAG"] = "BAG";
    PackagingType["BOTTLE"] = "BOTTLE";
    PackagingType["BOX"] = "BOX";
    PackagingType["CAN"] = "CAN";
    PackagingType["CANISTER"] = "CANISTER";
    PackagingType["CARTON"] = "CARTON";
    PackagingType["GLASS_BOTTLE"] = "GLASS_BOTTLE";
    PackagingType["JAR"] = "JAR";
    PackagingType["PACKET"] = "PACKET";
})(PackagingType = exports.PackagingType || (exports.PackagingType = {}));
/** An enumeration. */
var PackagingUnits;
(function (PackagingUnits) {
    PackagingUnits["GALLONS"] = "GALLONS";
    PackagingUnits["LITERS"] = "LITERS";
    PackagingUnits["MILLILITERS"] = "MILLILITERS";
    PackagingUnits["OUNCES"] = "OUNCES";
})(PackagingUnits = exports.PackagingUnits || (exports.PackagingUnits = {}));
var PerformanceReportFilter;
(function (PerformanceReportFilter) {
    PerformanceReportFilter["DISPLAYS"] = "DISPLAYS";
    PerformanceReportFilter["ROUTES"] = "ROUTES";
    PerformanceReportFilter["STORES"] = "STORES";
    PerformanceReportFilter["TERRITORIES"] = "TERRITORIES";
})(PerformanceReportFilter = exports.PerformanceReportFilter || (exports.PerformanceReportFilter = {}));
/** An enumeration. */
var Period;
(function (Period) {
    Period["ALL_TIME"] = "ALL_TIME";
    Period["DAY"] = "DAY";
    Period["MONTH"] = "MONTH";
    Period["QUARTER"] = "QUARTER";
    Period["WEEK"] = "WEEK";
    Period["YEAR"] = "YEAR";
})(Period = exports.Period || (exports.Period = {}));
/** An enumeration. */
var PlanogramProductOrientation;
(function (PlanogramProductOrientation) {
    PlanogramProductOrientation["FRONT"] = "FRONT";
    PlanogramProductOrientation["SIDE"] = "SIDE";
    PlanogramProductOrientation["TOP"] = "TOP";
})(PlanogramProductOrientation = exports.PlanogramProductOrientation || (exports.PlanogramProductOrientation = {}));
/** An enumeration. */
var PlanogramStatus;
(function (PlanogramStatus) {
    PlanogramStatus["APPROVED"] = "APPROVED";
    PlanogramStatus["DENIED"] = "DENIED";
    PlanogramStatus["DRAFT"] = "DRAFT";
    PlanogramStatus["EFFECTIVE"] = "EFFECTIVE";
    PlanogramStatus["EXPIRED"] = "EXPIRED";
    PlanogramStatus["ORPHANED"] = "ORPHANED";
})(PlanogramStatus = exports.PlanogramStatus || (exports.PlanogramStatus = {}));
var ProductSortBy;
(function (ProductSortBy) {
    ProductSortBy["AUDIT_APP_HIGH_TO_LOW"] = "AUDIT_APP_HIGH_TO_LOW";
    ProductSortBy["AUDIT_APP_LOW_TO_HIGH"] = "AUDIT_APP_LOW_TO_HIGH";
    ProductSortBy["RECENTLY_ADDED"] = "RECENTLY_ADDED";
    ProductSortBy["RECENTLY_MODIFIED"] = "RECENTLY_MODIFIED";
})(ProductSortBy = exports.ProductSortBy || (exports.ProductSortBy = {}));
/** An enumeration. */
var ProductTypeEnum;
(function (ProductTypeEnum) {
    ProductTypeEnum["BOTTLE_20OZ"] = "BOTTLE_20OZ";
    ProductTypeEnum["CANBOX_12OZ_8"] = "CANBOX_12OZ_8";
    ProductTypeEnum["CANBOX_12OZ_24"] = "CANBOX_12OZ_24";
    ProductTypeEnum["CANBOX_16OZ_4"] = "CANBOX_16OZ_4";
    ProductTypeEnum["CAN_7_5OZ_6"] = "CAN_7_5OZ_6";
    ProductTypeEnum["CAN_16OZ"] = "CAN_16OZ";
    ProductTypeEnum["CEREALBOX"] = "CEREALBOX";
    ProductTypeEnum["DOYPACK"] = "DOYPACK";
    ProductTypeEnum["FLATSIDEBOTTLE_17OZ"] = "FLATSIDEBOTTLE_17OZ";
    ProductTypeEnum["GATORADEBOTTLE_12OZ_12"] = "GATORADEBOTTLE_12OZ_12";
    ProductTypeEnum["GATORADEBOTTLE_20OZ"] = "GATORADEBOTTLE_20OZ";
    ProductTypeEnum["GATORADEBOTTLE_20OZ_8"] = "GATORADEBOTTLE_20OZ_8";
    ProductTypeEnum["GATORADEBOTTLE_32OZ"] = "GATORADEBOTTLE_32OZ";
    ProductTypeEnum["ISOTONICBOTTLE_16OZ"] = "ISOTONICBOTTLE_16OZ";
    ProductTypeEnum["LAYS"] = "LAYS";
    ProductTypeEnum["ONELITER"] = "ONELITER";
    ProductTypeEnum["SIXPACK"] = "SIXPACK";
    ProductTypeEnum["SLIMCAN_12OZ"] = "SLIMCAN_12OZ";
    ProductTypeEnum["TWELVEPACK"] = "TWELVEPACK";
    ProductTypeEnum["TWOLITER"] = "TWOLITER";
    ProductTypeEnum["WRAPPEDFLATSIDEBOTTLE_17OZ_6"] = "WRAPPEDFLATSIDEBOTTLE_17OZ_6";
    ProductTypeEnum["WRAPPEDFLATSIDEBOTTLE_17OZ_12"] = "WRAPPEDFLATSIDEBOTTLE_17OZ_12";
    ProductTypeEnum["WRAPPEDFLATSIDEBOTTLE_17OZ_24"] = "WRAPPEDFLATSIDEBOTTLE_17OZ_24";
})(ProductTypeEnum = exports.ProductTypeEnum || (exports.ProductTypeEnum = {}));
var ProductsPackagingContainerChoices;
(function (ProductsPackagingContainerChoices) {
    ProductsPackagingContainerChoices["ALUMINUM_BOTTLE"] = "ALUMINUM_BOTTLE";
    ProductsPackagingContainerChoices["BAG"] = "BAG";
    ProductsPackagingContainerChoices["BOTTLE"] = "BOTTLE";
    ProductsPackagingContainerChoices["BOX"] = "BOX";
    ProductsPackagingContainerChoices["CAN"] = "CAN";
    ProductsPackagingContainerChoices["CANISTER"] = "CANISTER";
    ProductsPackagingContainerChoices["CARTON"] = "CARTON";
    ProductsPackagingContainerChoices["GLASS_BOTTLE"] = "GLASS_BOTTLE";
    ProductsPackagingContainerChoices["JAR"] = "JAR";
    ProductsPackagingContainerChoices["PACKET"] = "PACKET";
})(ProductsPackagingContainerChoices = exports.ProductsPackagingContainerChoices || (exports.ProductsPackagingContainerChoices = {}));
var ProductsPackagingUnitChoices;
(function (ProductsPackagingUnitChoices) {
    ProductsPackagingUnitChoices["G"] = "G";
    ProductsPackagingUnitChoices["L"] = "L";
    ProductsPackagingUnitChoices["ML"] = "ML";
    ProductsPackagingUnitChoices["OZ"] = "OZ";
})(ProductsPackagingUnitChoices = exports.ProductsPackagingUnitChoices || (exports.ProductsPackagingUnitChoices = {}));
var ProductsProductSourceChoices;
(function (ProductsProductSourceChoices) {
    ProductsProductSourceChoices["COKE_PLANOGRAM"] = "COKE_PLANOGRAM";
    ProductsProductSourceChoices["KROGER"] = "KROGER";
    ProductsProductSourceChoices["OTHER"] = "OTHER";
    ProductsProductSourceChoices["SALSIFY"] = "SALSIFY";
    ProductsProductSourceChoices["SWIRE"] = "SWIRE";
    ProductsProductSourceChoices["TARGET"] = "TARGET";
    ProductsProductSourceChoices["WALMART"] = "WALMART";
})(ProductsProductSourceChoices = exports.ProductsProductSourceChoices || (exports.ProductsProductSourceChoices = {}));
/**
 * Determines if a display restock is resetting the inventory (SET_INVENTORY)
 * or adding product back onto the display (RESTOCK)
 */
var RestockAdjustmentType;
(function (RestockAdjustmentType) {
    RestockAdjustmentType["RESTOCK"] = "RESTOCK";
    RestockAdjustmentType["SET_INVENTORY"] = "SET_INVENTORY";
})(RestockAdjustmentType = exports.RestockAdjustmentType || (exports.RestockAdjustmentType = {}));
/** An enumeration. */
var ScanPurpose;
(function (ScanPurpose) {
    ScanPurpose["AUDIT"] = "AUDIT";
    ScanPurpose["MBF_ACTIVITY"] = "MBF_ACTIVITY";
    ScanPurpose["POST_RESTOCK"] = "POST_RESTOCK";
    ScanPurpose["PRE_RESTOCK"] = "PRE_RESTOCK";
    ScanPurpose["TRAINING"] = "TRAINING";
})(ScanPurpose = exports.ScanPurpose || (exports.ScanPurpose = {}));
/** An enumeration. */
var ScanType;
(function (ScanType) {
    ScanType["THREE_D"] = "THREE_D";
    ScanType["TWO_D"] = "TWO_D";
})(ScanType = exports.ScanType || (exports.ScanType = {}));
/** An enumeration. */
var SceneTypeEnum;
(function (SceneTypeEnum) {
    SceneTypeEnum["AISLE"] = "AISLE";
    SceneTypeEnum["CASE_STACK"] = "CASE_STACK";
    SceneTypeEnum["COLD_VAULT"] = "COLD_VAULT";
    SceneTypeEnum["FRIDGE"] = "FRIDGE";
    SceneTypeEnum["FRIDGE_EC"] = "FRIDGE_EC";
    SceneTypeEnum["MIXED_SNACKS"] = "MIXED_SNACKS";
    SceneTypeEnum["ONE_SIDED_ENDCAP"] = "ONE_SIDED_ENDCAP";
    SceneTypeEnum["PALLET_DROP"] = "PALLET_DROP";
    SceneTypeEnum["SHELF"] = "SHELF";
    SceneTypeEnum["SNACKS"] = "SNACKS";
    SceneTypeEnum["STANDALONE_RACK"] = "STANDALONE_RACK";
    SceneTypeEnum["THREE_SIDED_ENDCAP"] = "THREE_SIDED_ENDCAP";
    SceneTypeEnum["TWO_SIDED_ENDCAP"] = "TWO_SIDED_ENDCAP";
})(SceneTypeEnum = exports.SceneTypeEnum || (exports.SceneTypeEnum = {}));
/** An enumeration. */
var SimpleUserProductivityModelOptions;
(function (SimpleUserProductivityModelOptions) {
    SimpleUserProductivityModelOptions["TEAM"] = "TEAM";
    SimpleUserProductivityModelOptions["USER"] = "USER";
})(SimpleUserProductivityModelOptions = exports.SimpleUserProductivityModelOptions || (exports.SimpleUserProductivityModelOptions = {}));
var SortBy;
(function (SortBy) {
    SortBy["ADDRESS"] = "ADDRESS";
    SortBy["CATEGORY"] = "CATEGORY";
    SortBy["CHAIN"] = "CHAIN";
    SortBy["CUSTOMER_ID"] = "CUSTOMER_ID";
    SortBy["FORMULA_GROUP"] = "FORMULA_GROUP";
    SortBy["IS_ACTIVE"] = "IS_ACTIVE";
    SortBy["REGION"] = "REGION";
    SortBy["STORE_NAME"] = "STORE_NAME";
})(SortBy = exports.SortBy || (exports.SortBy = {}));
var SortByDirection;
(function (SortByDirection) {
    SortByDirection["ASCENDING"] = "ASCENDING";
    SortByDirection["DESCENDING"] = "DESCENDING";
})(SortByDirection = exports.SortByDirection || (exports.SortByDirection = {}));
var SortIssueBy;
(function (SortIssueBy) {
    SortIssueBy["CREATED"] = "CREATED";
    SortIssueBy["DISPLAY"] = "DISPLAY";
    SortIssueBy["ISSUE_TYPES"] = "ISSUE_TYPES";
    SortIssueBy["IS_RESOLVED"] = "IS_RESOLVED";
    SortIssueBy["REASON"] = "REASON";
    SortIssueBy["STORE_NAME"] = "STORE_NAME";
})(SortIssueBy = exports.SortIssueBy || (exports.SortIssueBy = {}));
var SortIssueDirection;
(function (SortIssueDirection) {
    SortIssueDirection["ASCENDING"] = "ASCENDING";
    SortIssueDirection["DESCENDING"] = "DESCENDING";
})(SortIssueDirection = exports.SortIssueDirection || (exports.SortIssueDirection = {}));
/** An enumeration. */
var StoreActionType;
(function (StoreActionType) {
    StoreActionType["EDITED"] = "EDITED";
    StoreActionType["PAUSED"] = "PAUSED";
    StoreActionType["RESUMED"] = "RESUMED";
    StoreActionType["STOPPED"] = "STOPPED";
})(StoreActionType = exports.StoreActionType || (exports.StoreActionType = {}));
/** An enumeration. */
var StoreCategory;
(function (StoreCategory) {
    StoreCategory["CR"] = "CR";
    StoreCategory["DR"] = "DR";
    StoreCategory["HM"] = "HM";
    StoreCategory["LG"] = "LG";
    StoreCategory["VL"] = "VL";
})(StoreCategory = exports.StoreCategory || (exports.StoreCategory = {}));
var StoreDisplaysFilterOptions;
(function (StoreDisplaysFilterOptions) {
    StoreDisplaysFilterOptions["CASE_STACKS"] = "CASE_STACKS";
    StoreDisplaysFilterOptions["COLD_VAULT"] = "COLD_VAULT";
    StoreDisplaysFilterOptions["COOLER"] = "COOLER";
    StoreDisplaysFilterOptions["END_CAP"] = "END_CAP";
    StoreDisplaysFilterOptions["OTHER"] = "OTHER";
    StoreDisplaysFilterOptions["PALLET_DROP"] = "PALLET_DROP";
    StoreDisplaysFilterOptions["STANDALONE_RACK"] = "STANDALONE_RACK";
})(StoreDisplaysFilterOptions = exports.StoreDisplaysFilterOptions || (exports.StoreDisplaysFilterOptions = {}));
var StoreDisplaysSortBy;
(function (StoreDisplaysSortBy) {
    StoreDisplaysSortBy["CREATED"] = "CREATED";
    StoreDisplaysSortBy["CREATED_REVERSE"] = "CREATED_REVERSE";
    StoreDisplaysSortBy["STORE_AREA_ALPHABETICAL_REVERSE"] = "STORE_AREA_ALPHABETICAL_REVERSE";
    StoreDisplaysSortBy["STORE_AREA_ALPHABETICAL_STANDARD"] = "STORE_AREA_ALPHABETICAL_STANDARD";
})(StoreDisplaysSortBy = exports.StoreDisplaysSortBy || (exports.StoreDisplaysSortBy = {}));
var StoreFilter;
(function (StoreFilter) {
    StoreFilter["NEEDS_TO_BE_AUDITED"] = "NEEDS_TO_BE_AUDITED";
})(StoreFilter = exports.StoreFilter || (exports.StoreFilter = {}));
var StoreFilterOut;
(function (StoreFilterOut) {
    StoreFilterOut["NO_NEED_FOR_AUDIT"] = "NO_NEED_FOR_AUDIT";
})(StoreFilterOut = exports.StoreFilterOut || (exports.StoreFilterOut = {}));
var StoreOrderBy;
(function (StoreOrderBy) {
    StoreOrderBy["CITY"] = "CITY";
    StoreOrderBy["DISTANCE"] = "DISTANCE";
    StoreOrderBy["NOTIFICATIONS"] = "NOTIFICATIONS";
    StoreOrderBy["RECENT_AUDIT_FIRST"] = "RECENT_AUDIT_FIRST";
    StoreOrderBy["RECENT_AUDIT_LAST"] = "RECENT_AUDIT_LAST";
    StoreOrderBy["STATE"] = "STATE";
    StoreOrderBy["STORE_NAME"] = "STORE_NAME";
})(StoreOrderBy = exports.StoreOrderBy || (exports.StoreOrderBy = {}));
var StoreTaskFilterOptions;
(function (StoreTaskFilterOptions) {
    StoreTaskFilterOptions["COMPLETE"] = "COMPLETE";
    StoreTaskFilterOptions["NOT_COMPLETE"] = "NOT_COMPLETE";
})(StoreTaskFilterOptions = exports.StoreTaskFilterOptions || (exports.StoreTaskFilterOptions = {}));
var StoreTaskSortBy;
(function (StoreTaskSortBy) {
    StoreTaskSortBy["COMPLETE"] = "COMPLETE";
    StoreTaskSortBy["CREATED"] = "CREATED";
    StoreTaskSortBy["CREATED_REVERSE"] = "CREATED_REVERSE";
    StoreTaskSortBy["NOT_COMPLETE"] = "NOT_COMPLETE";
})(StoreTaskSortBy = exports.StoreTaskSortBy || (exports.StoreTaskSortBy = {}));
/** An enumeration. */
var StoresStoreCategoryChoices;
(function (StoresStoreCategoryChoices) {
    /** Convenience Retail */
    StoresStoreCategoryChoices["CR"] = "CR";
    /** Drug */
    StoresStoreCategoryChoices["DR"] = "DR";
    /** Home Improvement */
    StoresStoreCategoryChoices["HM"] = "HM";
    /** Large */
    StoresStoreCategoryChoices["LG"] = "LG";
    /** Value */
    StoresStoreCategoryChoices["VL"] = "VL";
})(StoresStoreCategoryChoices = exports.StoresStoreCategoryChoices || (exports.StoresStoreCategoryChoices = {}));
/** An enumeration. */
var UploadStatus;
(function (UploadStatus) {
    UploadStatus["COMPLETE"] = "COMPLETE";
    UploadStatus["FAILED"] = "FAILED";
    UploadStatus["PENDING"] = "PENDING";
})(UploadStatus = exports.UploadStatus || (exports.UploadStatus = {}));
/** An enumeration. */
var UserProductivityModelOptions;
(function (UserProductivityModelOptions) {
    UserProductivityModelOptions["DISPLAY"] = "DISPLAY";
    UserProductivityModelOptions["STORE"] = "STORE";
    UserProductivityModelOptions["TEAM"] = "TEAM";
    UserProductivityModelOptions["USER"] = "USER";
})(UserProductivityModelOptions = exports.UserProductivityModelOptions || (exports.UserProductivityModelOptions = {}));
/** An enumeration. */
var UsersFCMTokenDeviceTypeChoices;
(function (UsersFCMTokenDeviceTypeChoices) {
    /** Mobile */
    UsersFCMTokenDeviceTypeChoices["MOBILE"] = "MOBILE";
    /** Tablet */
    UsersFCMTokenDeviceTypeChoices["TABLET"] = "TABLET";
})(UsersFCMTokenDeviceTypeChoices = exports.UsersFCMTokenDeviceTypeChoices || (exports.UsersFCMTokenDeviceTypeChoices = {}));
/** An enumeration. */
var VerificationDecision;
(function (VerificationDecision) {
    VerificationDecision["FAIL"] = "FAIL";
    VerificationDecision["PASS"] = "PASS";
})(VerificationDecision = exports.VerificationDecision || (exports.VerificationDecision = {}));
/** An enumeration. */
var VerifiedLocation;
(function (VerifiedLocation) {
    VerifiedLocation["FIELD"] = "FIELD";
    VerifiedLocation["FIELD_AND_WHTE_RBT"] = "FIELD_AND_WHTE_RBT";
    VerifiedLocation["WHTE_RBT"] = "WHTE_RBT";
})(VerifiedLocation = exports.VerifiedLocation || (exports.VerifiedLocation = {}));
/** An enumeration. */
var ZoneTier;
(function (ZoneTier) {
    ZoneTier["DISTRICT"] = "DISTRICT";
    ZoneTier["ROUTE"] = "ROUTE";
    ZoneTier["TERRITORY"] = "TERRITORY";
})(ZoneTier = exports.ZoneTier || (exports.ZoneTier = {}));
