"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var result = {
    "possibleTypes": {
        "FlaggableObject": [
            "AuditAnswerImage",
            "DisplayAuditImage",
            "DisplayImage",
            "DisplaySegment",
            "DisplayTypeImage",
            "Image",
            "IndoorMap",
            "MerchandiserDisplayScanImage",
            "ModcomComplianceImage",
            "MultiProductImage",
            "PicosImage",
            "PlanogramSegmentFlaggedIssueImage",
            "Product",
            "ProductImage",
            "ProductMarketingImage",
            "ScanImage",
            "StoreImage",
            "TrainingImage",
            "UserImage"
        ],
        "ImageBase": [
            "AuditAnswerImage",
            "DisplayAuditImage",
            "DisplayImage",
            "DisplayTypeImage",
            "Image",
            "MerchandiserDisplayScanImage",
            "ModcomComplianceImage",
            "MultiProductImage",
            "PicosImage",
            "PlanogramProductImage",
            "PlanogramSegmentFlaggedIssueImage",
            "ProductImage",
            "ProductMarketingImage",
            "ScanImage",
            "StoreImage",
            "TrainingImage",
            "UserImage"
        ],
        "PicOSGroupStatsBase": [
            "PicOSAllCustomersStats",
            "PicOSCustomerStats",
            "PicOSGroupStats",
            "PicOSRegionalStats"
        ],
        "PointCloudBase": [
            "DisplayAuditPointCloud",
            "DisplayRestockPointCloud",
            "MerchandiserPointCloud",
            "SingleProductPointCloud",
            "SyntheticPointCloud",
            "TrainingPointCloud"
        ],
        "StoreTask": [
            "DisplayTask"
        ],
        "TaskBase": [
            "DisplayTask"
        ],
        "VideoBase": [
            "MultiProductVideo"
        ]
    }
};
exports.default = result;
